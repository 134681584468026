import {Group} from "@graph";
import Typography from "@mui/material/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";

export interface GroupInviteButtonProps {
    group: Group
}

export const GroupInviteButton = (props: GroupInviteButtonProps) => {
    const navigate = useNavigate()
    const {group} = props

    if (!group.userRole?.isHost ) {
        return undefined
    }

    return <Typography variant="caption"
                       color="primary"
                       fontWeight="700"
                       marginLeft={1}
                       sx={{
                           backgroundColor: "rgba(207,226,245,0.51)",
                           borderRadius: 5,
                           padding: "2px 8px"
                       }}
                       onClick={_ => navigate("invite")}
    >
        <FontAwesomeIcon icon={faUserPlus}/> Inviter
    </Typography>
}