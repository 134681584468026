import {gql} from "@profusion.media/profusion-core";

export const JSD_GROUP_FETCH_MUTATION = gql`
  mutation jsdGroupFetch($email: String!, $password: String!) {
    jsdGroupFetch(email: $email, password: $password) {
      errors {
        field
      }
      groups {
        id
        title
        description
        memberCount
      }
    }
  }`;
