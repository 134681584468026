import {gql} from "@profusion.media/profusion-core";
import {PRAYER_REQUEST_FIELDS} from "../fragments";

export const PRAYER_REQUEST_UPDATE_MUTATION = gql`
  ${PRAYER_REQUEST_FIELDS}
  mutation prayerRequestUpdate($input: PrayerRequestUpdateInput!) {
    prayerRequestUpdate(input: $input) {
      prayerRequest {
        ...PrayerRequestFields
      }
      errors {
        field
        code
      }
    }
  }`;
