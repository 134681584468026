import {Group, GROUP_LIST_ALL_QUERY, GROUP_LIST_MINE_QUERY, GROUP_LIST_SHARED_QUERY, GroupOrigin} from "../schema";
import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {QueryGroupListMineArgs, QueryGroupListSharedArgs} from "@graph";

export const loadGroupList = (client: ProfusionClient, what: GroupOrigin, type: string) : GraphQueryPromise<Group[]> => {
  const query =
    what === "shared" ? GROUP_LIST_SHARED_QUERY :
      what === "mine" ? GROUP_LIST_MINE_QUERY :
        GROUP_LIST_ALL_QUERY

  return executeQuery<Group[], QueryGroupListMineArgs|QueryGroupListSharedArgs>(client, query, {
    variables: {
      type
    },
    isPrivate: true
  })
}
