import { Box, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { WaitingPageProps } from "./typings";
import React from 'react';
import { useTranslation } from "react-i18next";

export const WaitingPage = (props: WaitingPageProps) => {
    const { t } = useTranslation()

    if (!props.show) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex:9999,
                backgroundColor:'rgba(0,0,0,0.3)'
            }}
        >
            <Stack>
                <p>{props.message ?? t("ux.message.loading", "Chargement...")}</p>
                <FontAwesomeIcon icon={faSpinner} pulse size="3x" />
            </Stack>
        </Box>
    );
}