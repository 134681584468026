import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {INVITATION_DETAILS, PENDING_INVITATION_LIST} from "../schema";

import {PublicInvitation, QueryInvitationDetailsArgs} from "@profusion.media/graph";

export const loadInvitationDetails = (client: ProfusionClient, invitationHid: string): GraphQueryPromise<PublicInvitation> => {
  return executeQuery<PublicInvitation, QueryInvitationDetailsArgs>(client, INVITATION_DETAILS, {
    variables: {
      hid: invitationHid
    }
  })
}

export const loadPendingInvitations = (client: ProfusionClient): GraphQueryPromise<PublicInvitation[]> => {
  return executeQuery<PublicInvitation[]>(client, PENDING_INVITATION_LIST, {
    isPrivate: true
  })
}
