import React from "react"
import {MonthInputProps, MonthValue} from "./typings";
import {SelectInput} from "../SelectInput";
import {useTranslation} from "react-i18next"

export const MonthInput : React.FC<MonthInputProps> = (props: MonthInputProps) => {
    const {value, onChange, error} = props
    const {t} = useTranslation()

    const months : MonthValue[] = [
        {number: 1, i18n: "date.month.1", defaultName: "Janvier"},
        {number: 2, i18n: "date.month.2", defaultName: "Février"},
        {number: 3, i18n: "date.month.3", defaultName: "Mars"},
        {number: 4, i18n: "date.month.4", defaultName: "Avril"},
        {number: 5, i18n: "date.month.5", defaultName: "Mai"},
        {number: 6, i18n: "date.month.6", defaultName: "Juin"},
        {number: 7, i18n: "date.month.7", defaultName: "Juillet"},
        {number: 8, i18n: "date.month.8", defaultName: "Août"},
        {number: 9, i18n: "date.month.9", defaultName: "Septembre"},
        {number: 10, i18n: "date.month.10", defaultName: "Octobre"},
        {number: 11, i18n: "date.month.11", defaultName: "Novembre"},
        {number: 12, i18n: "date.month.12", defaultName: "Décembre"},
    ]

    return <SelectInput values={months}
                        error={error}
                        label={props.label ?? t("date.labels.month", "Mois")}
                        sx={{minWidth: "120px"}}
                        fullWidth
                        value={value ? value.toString() : undefined}
                        onChange={(v) => onChange(parseInt(v))}
                        renderItem={(item) => t(item.i18n, item.defaultName)}
                        keyExtractor={(item) => item.number.toString()} />

}

