import {CommunityMember} from "@graph";
import {ListItem, ListItemSecondaryAction, Switch} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {useRevalidator} from "react-router-dom";
import React, {useState} from "react";
import {useCommunityMemberSetAllowPrayer} from "@community/actions/member";

export interface MemberInfoPrayerProps {
    member: CommunityMember
}

export const MemberInfoPrayer = (props: MemberInfoPrayerProps) => {
    const {member} = props
    const {setAllowPrayer} = useCommunityMemberSetAllowPrayer(member.hid)
    const {revalidate, state} = useRevalidator();
    const [checked, setChecked] = useState(member.accessPrayer)

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        setAllowPrayer(event.target.checked).then(_ => revalidate())
    }

    return <ListItem>
        <ListItemText primary="Accès au groupe de prière"/>
        <ListItemSecondaryAction>
            <Switch checked={checked} onChange={handleSwitch}/>
        </ListItemSecondaryAction>
    </ListItem>
}