import {Outlet, useParams} from "react-router-dom";
import React, {createContext, useContext} from "react"
import DashboardLayout from "../components/layout/dashboard/DashboardLayout";
import {GroupsBottomNavigation} from "./components/GroupsBottomNavigation";
import {BodyContainer} from "@components/BodyContainer";
import {useTranslation} from "react-i18next";


export interface DashboardContextProps {
    bottomActiveTab: number;
    setBottomTab: React.Dispatch<React.SetStateAction<number>>;
}

export const DashboardAppContext = createContext<DashboardContextProps>({
    bottomActiveTab: 0,
    setBottomTab: (value: number) => {
    }
})

export const DashboardApp = () => {
    const {group_type} = useParams()
    const {t} = useTranslation()
    const [bottomTab, setBottomTab] = React.useState<number>(0)


    const title = (group_type === 'share') ? t("app.title.discover", "Découvrir Jésus") : t("app.title.prayer", "Prière")
    return <DashboardLayout title={title}>
        <DashboardAppContext.Provider value={{
            bottomActiveTab: bottomTab,
            setBottomTab: (v: number) => {
                setBottomTab(v)
            }
        }}>
            <BodyContainer>
                <Outlet/>
            </BodyContainer>
            <GroupsBottomNavigation/>
        </DashboardAppContext.Provider>
    </DashboardLayout>
}