import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {Backdrop} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePlus, faUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {Group} from "@graph";
import {useNavigateInvite, useNavigateManageContent} from "../../../hooks/navigation";
import Typography from "@mui/material/Typography";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {Close} from "@mui/icons-material";
import {useNavigateRequestCreate} from "@groups/Prayers/hooks/navigation";
import {faBooks} from "@fortawesome/pro-light-svg-icons";
import {useLocation} from "react-router-dom";

export interface GroupSpeedDialProps {
    group: Group
}

type SpeedDialAction = {
    icon: ReactNode,
    name: string,
    onClick: () => void
}

export const GroupSpeedDial = (props: GroupSpeedDialProps) => {
    const {group} = props
    const edit = useNavigateRequestCreate(group.hid)
    const invite = useNavigateInvite()
    const manage = useNavigateManageContent()
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation()

    useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    let actions: SpeedDialAction[] = []

    if (group.mainFeature === 'prayer') {
        actions.push({
            icon: <FontAwesomeIcon icon={faFilePlus}/>,
            name: 'Ajouter une requête',
            onClick: () => edit()
        })
    } else if (group.communityHid !== '') {
        actions.push({
            icon: <FontAwesomeIcon icon={faBooks}/>,
            name: 'Gérer les contenus',
            onClick: () => manage(group)
        })
    }
    actions.push({
        icon: <FontAwesomeIcon icon={faUserPlus}/>,
        name: 'Ajouter un participant',
        onClick: () => invite(group)
    })


    const handleAction = (action: SpeedDialAction) => {
        setOpen(false)
        setTimeout(() => action.onClick(), 200)
    }

    return <>
        <Backdrop open={open}/>
        <SpeedDial
            key={location.pathname}
            className="speed-dial-groups"
            ariaLabel="Gérer le groupe"
            onOpen={_ => setOpen(true)}
            onClose={_ => setOpen(false)}
            open={open}
            icon={<SpeedDialIcon openIcon={<Close/>}/>}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    FabProps={{
                        color: "primary"
                    }}
                    tooltipOpen
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={<Typography variant="caption" noWrap>{action.name}</Typography>}
                    onClick={_ => handleAction(action)}
                />
            ))}
        </SpeedDial>
    </>


}