import {MutationGroupCreateArgs, MutationGroupUpdateArgs} from "@profusion.media/profusion-core-group";
import React, {useEffect, useState} from "react";
import {CancelButton, Form, FormErrorBox, LanguageInput, SubmitButton, TextInput} from "@profusion.media/profusion-ui";
import {Checkbox, ListItem, ListItemSecondaryAction, Stack} from "@mui/material";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {AvatarEditor} from "@profusion.media/profusion-ui-image-uploader";
import ReactQuill from "react-quill";
import {useTranslation} from "react-i18next";
import {Community} from "@graph";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {WhiteBox} from "@components/WhiteBox";

type GroupSettingsInputArgs = MutationGroupUpdateArgs | MutationGroupCreateArgs

export interface GroupSettingsFormProps {
    group: any
    mode: "create" | "update"
    onSubmit: (group: GroupSettingsInputArgs) => void
    onCancel: () => void
    loading: boolean
    validationErrors?: ValidationErrors
    community?: Community
}

interface CommunityTagsInputProps {
    community: Community
    tags: string[]
    onChange: (value: string[]) => void
}

const CommunityTagsInput = (props: CommunityTagsInputProps) => {
    const {community, tags, onChange} = props
    const [selected, setSelected] = useState<string[]>(tags)

    if (!community.tags) return

    const onChecked = (tag: string, checked: boolean) => {
        const newSelected = checked ? [...selected, tag] : selected.filter((v) => v !== tag)
        setSelected(newSelected)
        onChange(newSelected)
    }

    const isChecked = (tag: string): boolean => {
        return selected.includes(tag)
    }

    return <List>
        {community.tags.map(tag => tag && <ListItem key={tag.hid}>
            <ListItemText primary={tag.name}/>
            <ListItemSecondaryAction>
                <Checkbox checked={isChecked(tag.hid)} onChange={(_, checked) => onChecked(tag.hid, checked)}/>
            </ListItemSecondaryAction>
        </ListItem>)}
    </List>
}


export function GroupSettingsForm(props: GroupSettingsFormProps) {
    const {group, community, onCancel, onSubmit, loading, validationErrors} = props
    const [data, setData] = useState<GroupSettingsInputArgs>({input: group, avatar: undefined})
    const {t} = useTranslation()
    const needDescription = false

    const handleSubmit = () => {
        onSubmit(data)
    }

    const onChange = (name: string, value: any) => {
        setData((prev: any) => ({...prev, input: {...prev.input, [name]: value}}))
    }

    const setAvatar = (avatar: any) => {
        setData((prev: any) => ({...prev, avatar: avatar}))
    }

    const onChangeTags = (tags: string[]) => {
        onChange("tags", tags)
    }

    useEffect(() => {
        onChangeTags(group.tags?.map((tag: any) => tag.hid) || [])
    }, [group]);

    const feature = group.mainFeature ?? group.feature

    const formTitle = community ? "" :
        feature === 'prayer' ? "Groupe de prière" : "Découvrir Jésus"

    return <Form onSubmit={handleSubmit}
                 onCancel={onCancel}
                 updating={loading}
                 errors={validationErrors}
    >
        <FormErrorBox message="Des erreurs sont survenues"/>
        <WhiteBox title={formTitle}>
            <Stack spacing={2}>
                <TextInput name="title"
                           value={data.input.title}
                           onValueChange={onChange}
                           label={t("group.form.title", "Nom du groupe")}
                />
                {community && (group.feature === 'share' || group.mainFeature === 'share') &&
                    <CommunityTagsInput community={community}
                                        tags={data.input.tags as string[]}
                                        onChange={onChangeTags}/>}
                {needDescription && <ReactQuill
                    placeholder={t("group.form.description", "Écrivez une description") as string}
                    value={data.input.welcome ?? ""}
                    onChange={value => onChange("welcome", value)}
                    theme="snow"
                    style={{minHeight: 200}}
                />}
            </Stack>
        </WhiteBox>
        <WhiteBox>
            <AvatarEditor
                description={"Ajoutez une image pour aider les participants\n" +
                    "                à reconnaître votre groupe"}
                image={data.avatar?.url ?? group.avatar?.mediumUrl}
                onChange={setAvatar}/>

        </WhiteBox>
        <WhiteBox sx={{paddingY: 4, display: "flex", justifyContent: "end"}}>
            <CancelButton/>
            <SubmitButton/>
        </WhiteBox>

    </Form>
}