import React, { useEffect, useState } from "react";
import { AuthSession } from "../session";
import { LoginOTPInput, LoginUserInput, LoginUserResponse } from "../schema/JWT";
import { ProfusionClient } from "../client";
import {App} from "@capacitor/app";

interface AuthenticationProviderProps {
  children: React.ReactNode | React.ReactNode[];
  client: ProfusionClient;
}

export interface AuthenticationContextProps {
  session: AuthSession;
  access_token: string;
  auth_ready: boolean;
  is_authenticated: boolean;
  login: (credentials: LoginUserInput) => Promise<LoginUserResponse>;
  loginOTP: (credentials: LoginOTPInput) => Promise<any>;
  logout: () => Promise<any>;
  refresh: (token?: string) => Promise<any>;
  is_refreshing: boolean;
}


export const AuthenticationContext = React.createContext<AuthenticationContextProps>({
  is_authenticated: false,
  access_token: "",
  auth_ready: false,
  login: (credentials: LoginUserInput) => Promise.resolve(undefined),
  loginOTP: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: (_?: string) => Promise.resolve(),
  is_refreshing: false,
  session: new AuthSession()
});

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const client = props.client;
  const [appState, setAppState] = useState<boolean>(true);

  App.addListener('appStateChange', ({ isActive }) => {
    console.log('App state changed. Is active?', isActive);
    setAppState(isActive);
  });

  const login = (credentials: LoginUserInput) => {
    return client.session.login(credentials).then(async response => {
      setState({ ...state, is_authenticated: true, access_token: await client.session.get_access_token() });
      return Promise.resolve(response);
    });
  };

  const loginOTP = (credentials: LoginOTPInput) => {
    return client.session.loginOTP(credentials).then(async _ => {
      setState({ ...state, is_authenticated: true, access_token: await client.session.get_access_token() });
      return Promise.resolve();
    });
  };

  const logout = () => {
    window.location.href = "https://app.profusion.media/logout";
    return Promise.resolve();
  };

  const refresh = (token?: string) => {
    setState({ ...state, is_refreshing: true });
    const refreshToken = token ?? client.session.get_refresh_token();

    return client.session.refresh(refreshToken).then(async (_: any) => {
      const token = await client.session.get_access_token();
      setState({
        ...state,
        access_token: token,
        is_authenticated: client.session.is_authenticated,
        is_refreshing: false
      });
      return Promise.resolve();
    });
  };


  const [state, setState] = useState<AuthenticationContextProps>({
    session: client.session,
    access_token: "",
    is_authenticated: client.session.is_authenticated,
    auth_ready: false,
    is_refreshing: false,
    login, loginOTP, logout, refresh
  });

  useEffect(() => {
    if (appState) {
      setState({...state, auth_ready: false})
      client.session.get_access_token()
          .then(token => {
            console.log("AUTH PROVIDER LOADED", token)
            setState({...state, auth_ready: true, access_token: token, is_authenticated: !!token})
          });
    }
  }, [appState]);

  if (!state.auth_ready) return <></>

  return <AuthenticationContext.Provider value={state}>
    {props.children}
  </AuthenticationContext.Provider>;
};
