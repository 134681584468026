import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const GET_ALL_USERS = gql`
  ${USER_FIELDS}
  query getAllUsers ($firstName: String, $lastName: String) {
    getUsers (firstName: $firstName, lastName: $lastName) {
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...UserFields
        }
      }
    }
  }`;
