import {CommunityMember} from "@graph";
import {Box} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";

export interface HeadingProps {
    member: CommunityMember
}

export const Heading = (props: HeadingProps) => {
    const {member} = props

    return <Box display="flex" flexDirection="row" gap={2} padding={2} alignItems="center">
        <UserAvatar userName={member.user?.firstName + " " + member.user?.lastName}
                    avatar={member.user?.avatar?.mediumUrl}
                    size={64}
        />
        <Box>
            <Typography fontWeight={700}>{member.user?.firstName} {member.user?.lastName}</Typography>
            <Typography fontSize={14}>{member.user?.email}</Typography>
        </Box>
    </Box>
}