import React from 'react';
import GridRow from "../GridRow";
import {useNavigate} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";


const SecurityInfo: React.FC = () => {
    const navigate = useNavigate()

    return <WhiteBox title="Sécurité">

        <GridRow label="Mot de passe" value="********"
                 onEdit={() => {
                     navigate("password")
                 }}
        />
        <GridRow label="Supprimer mon compte" value=""
                 color='danger'
                 onEdit={() => {
                     navigate("/account/delete")
                 }}
        />
    </WhiteBox>
}

export default SecurityInfo;
