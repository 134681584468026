import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilterList, faThumbtackSlash as faBookmarkSlash} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {pinColors} from "@groups/Prayers/components/PrayerPinButton";
import {faThumbTack as faBookmark} from "@fortawesome/pro-solid-svg-icons"

export interface PinnedRequestListFilterProps {
    filter: string
    onChange: (filter: string | undefined) => void
}

export const PinnedRequestListFilter = (props: PinnedRequestListFilterProps) => {
    const {filter, onChange} = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelect = (option: string | undefined) => {
        setAnchorEl(null);
        onChange(option)
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <div>
        <IconButton onClick={handleClick} color={filter ? "primary" : "inherit"}>
            <FontAwesomeIcon icon={faFilterList}/>
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={_ => handleClose()}
        >

            <MenuItem key="0"
                      dense
                      onClick={() => {
                          handleSelect(undefined)
                      }}>
                <FontAwesomeIcon icon={faBookmarkSlash} size="1x"/>
            </MenuItem>
            {pinColors.map((option) => (
                option && <MenuItem key={option}
                                    dense
                                    onClick={_ => handleSelect(option)}>
                    <FontAwesomeIcon icon={faBookmark} color={option} size="1x"/>
                </MenuItem>
            ))}
        </Menu>
    </div>
}