import {gql} from "@apollo/client";
import {COMMUNITY_FRAGMENT} from "../fragments";
import {COMMUNITY_MEMBER_FRAGMENT} from "@community/schema/fragments/community_member";
import {GROUP_FIELDS} from "@profusion.media/profusion-core-group";
import {ORGANIZATION_FIELDS} from "@organizations/schema/fragments/organization";

export const GET_COMMUNITY_DETAILS = gql`
    ${COMMUNITY_FRAGMENT}
    query getCommunityDetails ($hid: String!) {
        getCommunityDetails (hid: $hid) {
            ...COMMUNITY_FRAGMENT
        }
    }`

export const GET_COMMUNITY_ORGANIZATION = gql`
${ORGANIZATION_FIELDS}
    query getCommunityOrganization ($hid: String!) {
        getCommunityOrganization(hid: $hid) {
            ...organizationFields
        }
    }`

export const GET_COMMUNITY_CHILDREN = gql`
    ${COMMUNITY_FRAGMENT}
    query getCommunityChildren ($parentHid: String!) {
        getCommunityChildren (parentHid: $parentHid) {
            ...COMMUNITY_FRAGMENT
        }
    }`

export const GET_COMMUNITY_MEMBERS = gql`
    ${COMMUNITY_MEMBER_FRAGMENT}
    query getCommunityMembers ($hid: String!) {
        getCommunityMembers (hid: $hid) {
            ...COMMUNITY_MEMBER_FRAGMENT
        }
    }
`
export const GET_COMMUNITY_GROUPS = gql`
    ${GROUP_FIELDS}
    query getCommunityGroups ($hid: String!) {
        getCommunityGroups (hid: $hid) {
            ...GroupFields
        }
    }
`

export const GET_COMMUNITY_STATS = gql`
    query getCommunityStats ($hid: String!) {
        getCommunityStats (hid: $hid) {
            hid
            members
            activeMembers
            groups
            membersInGroups
        }
    }`
