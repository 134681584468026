import {gql} from "@profusion.media/profusion-core";
import {PRAYER_REQUEST_FIELDS} from "../fragments";

export const PRAYER_REQUEST_LIST_QUERY = gql`
  ${PRAYER_REQUEST_FIELDS}
  query PrayerRequestList ($group: String!) {
    prayerRequestList(group: $group) {
      ...PrayerRequestFields
    }
  }`;

export const PRAYER_PINNED_LIST_QUERY =  gql`
    ${PRAYER_REQUEST_FIELDS}
    query PrayerPinnedList {
        prayerPinList {
            ...PrayerRequestFields
        }
    }`;
