import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {PRAYER_COMMENTS_QUERY, PRAYER_EVENT_LIST_QUERY, PRAYER_PRAYED_EVENTS_QUERY, PrayerEvent} from "../schema";

export const loadPrayerRequestEvents = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerEvent[]> => {
  return executeQuery<PrayerEvent[]>(client, PRAYER_EVENT_LIST_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}

export const loadPrayerRequestComments = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerEvent[]> => {
  return executeQuery<PrayerEvent[]>(client, PRAYER_COMMENTS_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}

export const loadPrayerRequestPrayedEvent = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerEvent[]> => {
  return executeQuery<PrayerEvent[]>(client, PRAYER_PRAYED_EVENTS_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}

export const loadPrayerEventReplies = (client: ProfusionClient, parentHid: string): GraphQueryPromise<PrayerEvent[]> => {
  return executeQuery<PrayerEvent[]>(client, PRAYER_EVENT_LIST_QUERY, {
    isPrivate: true,
    variables: {
      parentHid: parentHid
    }
  })
}
