import {Await, useNavigate, useRouteLoaderData} from "react-router-dom";
import {Box, ListItem, ListItemIcon} from "@mui/material";
import {LoadDetailsReturn} from "../../loaders";
import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import {useCommunityAvailableContentLoader} from "@community/loaders/communityAvailableContent";
import {WhiteBox} from "@components/WhiteBox";
import List from "@mui/material/List";
import {CommunityTag, Content} from "@graph";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {TagChipGroup} from "@community/components/TagChip";
import {GroupContentCheckbox} from "@groups/Groups/GroupDetails/components/GroupContent/GroupContentCheckbox";
import {FullScreenDialog} from "@profusion.media/profusion-ui";

export const GroupContentManage = () => {
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {content: contentData} = useCommunityAvailableContentLoader()
    const navigate = useNavigate();
    const handleClose = () => {
        navigate("..")
    }

    return <Suspense>
        <Await resolve={Promise.all([groupData, contentData])}>
            {([{data: group}, {data: content}]) =>
            <FullScreenDialog
                onClose={handleClose}
                title={"Gérer les contenus"}>
                <WhiteBox title="Contenus disponibles">
                <List>
                    {content && content.map((c: Content) =>
                        <ListItem key={c.hid} sx={{pl: 0}}>
                            <ListItemIcon>
                                <GroupContentCheckbox group={group} content={c}/>
                            </ListItemIcon>
                            <ListItemText primary={c.title}
                                          secondary={<Box>
                                              {c.tags && <TagChipGroup tags={c.tags as CommunityTag[]}/>}
                                              <Typography variant="body2">{c.description}</Typography>
                                          </Box>}/>
                        </ListItem>)}
                </List>
            </WhiteBox>
            </FullScreenDialog>
            }

        </Await>
    </Suspense>
}