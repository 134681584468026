import {useLocation, useNavigate} from "react-router-dom";
import {faPrayingHands, faRectangleHistoryCircleUser, faUserGroup} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {Community} from "@graph";
import {faBuildings, faGears} from "@fortawesome/pro-regular-svg-icons";
import {useTabIndex} from "@groups/Groups/GroupDetails/hooks";
import {faChurch} from "@fortawesome/pro-light-svg-icons";

export const SuperAdminBottomNavigation = () => {
    const currentTab = parseInt(useTabIndex() || "0")
    const [value, setValue] = React.useState(currentTab ?? 0);
    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation()
    const paths = [
        `/su/denomination/1/20`,
        `/su/church/1/20`,
    ]

    const navigateTo = (idx: number) => {
        navigate(paths[idx], {
            state: {from: location.pathname}
        })
    }

    return <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
            navigateTo(newValue)
        }}
        className="bottom-navigation"
    >
        <BottomNavigationAction label={t("su.menu.denominations", "Dénominations")}
                                icon={<FontAwesomeIcon icon={faBuildings} fixedWidth/>}/>
        <BottomNavigationAction label={t("su.menu.churches", "Églises")}
                                icon={<FontAwesomeIcon icon={faChurch} fixedWidth/>}/>

    </BottomNavigation>
}