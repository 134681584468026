import React from "react"
import {FormButtonProps} from "../typings";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";

export const FormButton = (props: FormButtonProps) => {
    const startIcon = props.iconPosition === "left" ? props.icon : undefined
    const endIcon = props.iconPosition === "right" ? props.icon : undefined

    return <Button color={props.color ?? "inherit"}
                   onClick={_ => props.handler()}
                   variant={props.variant}
                   startIcon={startIcon}
                   endIcon={endIcon}
                   disabled={props.disabled ?? false}
                   >{props.label}</Button>

}