import React from "react"
import {List, ListItemIcon, ListItemText, MenuItem, Select, Switch,} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useTranslation} from "react-i18next";
import {StyledListItem} from "./styles";
import {NotificationSettingsFormProps} from "./typings";

export const NotificationSettingsForm = (props: NotificationSettingsFormProps) => {
    const settings = props.value
    const {t} = useTranslation()

    const setSettings = (name: string, value: any) => {
        props.onChange({...settings, [name] : value})
    }

    const handleEmailToggle = () => {
        setSettings("enableNotificationEmail", !settings.enableNotificationEmail)
    };

    const handleSmsToggle = () => {
        setSettings("enableNotificationSms", !settings.enableNotificationSms)
    };

    const handlePushToggle = () => {
        setSettings("enableNotificationPush", !settings.enableNotificationPush)

    };

    const handleFrequencyChange = (event) => {
        setSettings("notificationFrequency", event.target.value)
    };

    return (
        <List>
            <StyledListItem>
                <ListItemIcon>
                    <EmailIcon/>
                </ListItemIcon>
                <ListItemText primary={t("subscription.settings.email.enable", "Autoriser l'envoi par e-mail")}/>
                <Switch checked={settings.enableNotificationEmail} onChange={handleEmailToggle}/>
            </StyledListItem>
            <StyledListItem>
                <ListItemIcon>
                    <SmsIcon/>
                </ListItemIcon>
                <ListItemText primary={t("subscription.settings.sms.enable", "Autoriser l'envoi par SMS")}/>
                <Switch checked={settings.enableNotificationSms} onChange={handleSmsToggle}/>
            </StyledListItem>
            <StyledListItem>
                <ListItemIcon>
                    <NotificationsIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={t("subscription.settings.push.enable", "Autoriser l'envoi par notification push")}/>
                <Switch checked={settings.enableNotificationPush} onChange={handlePushToggle}/>
            </StyledListItem>
            <StyledListItem>
                <ListItemIcon>
                    <AccessTimeIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={t("subscription.settings.notification.frequency", "Fréquence d'envoi des notifications")}/>
                <Select
                    value={settings.notificationFrequency}
                    onChange={handleFrequencyChange}
                >
                    <MenuItem
                        value="ASAP">{t("subscription.settings.notification.asap", "Pour chaque nouvelle requête")}</MenuItem>
                    <MenuItem
                        value="DAILY">{t("subscription.settings.notification.daily", "Une fois par jour")}</MenuItem>
                    <MenuItem
                        value="WEEKLY">{t("subscription.settings.notification.weekly", "Une fois par semaine")}</MenuItem>
                </Select>
            </StyledListItem>
        </List>
    );
}