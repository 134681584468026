import {gql} from "@profusion.media/profusion-core";
import {SUBSCRIBER_FIELDS} from "../fragments";
import {NOTIFICATION_SETTINGS_FIELDS} from "@profusion.media/profusion-core-notification"

export const GROUP_SUBSCRIBER_LIST = gql`
  ${SUBSCRIBER_FIELDS}
  query GroupSubscriberList ($group: String!) {
    groupSubscriberList (group: $group) {
      ...SubscriberFields
    }
  }`;

export const GROUP_SUBSCRIBER_SETTINGS = gql`
  ${NOTIFICATION_SETTINGS_FIELDS}
  query GroupSubscriberSettings($group: String!){
    groupSubscriberSettings(group: $group) {
      ...NotificationSettingsFields
    }
  }`
