import {Community, CommunityMember, CommunityTag} from "@graph";
import {Drawer, Stack} from "@mui/material";
import {Heading} from "@community/screens/members/drawer/Heading";
import {MemberInfoTag} from "@community/screens/members/drawer/MemberInfoTag";
import {MemberInfoPrayer} from "@community/screens/members/drawer/MemberInfoPrayer";
import zIndex from "@mui/material/styles/zIndex";
import List from "@mui/material/List";
import {MemberInfoAdmin} from "@community/screens/members/drawer/MemberInfoAdmin";

export interface CommunityMemberBottomSheetProps {
    member: CommunityMember | undefined;
    community: Community
    open: boolean
    onClose: () => void
}

export const CommunityMemberBottomSheet = (props: CommunityMemberBottomSheetProps) => {
    const {member, open, onClose, community} = props

    const hasTag = community.organization.type ===  'church'

    return member && <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        className="bottom-drawer"
        sx={{zIndex: 40001}}
    >
        <Stack spacing={2} direction="column" alignItems="left">
            <Heading member={member}/>
            <List>
                {hasTag && <MemberInfoTag
                    allowedTags={community.tags as CommunityTag[]}
                    member={member}/>}
                <MemberInfoPrayer member={member}/>
                <MemberInfoAdmin member={member}/>
            </List>
        </Stack>

    </Drawer>
}