import {CommunityMember} from "@graph";
import {ListItem, ListItemSecondaryAction, Switch, ToggleButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {useCommunityMemberSetAdmin} from "@community/actions/member";
import {useRevalidator} from "react-router-dom";
import React, {useState} from "react";

export interface MemberInfoAdminProps {
    member: CommunityMember
}

export const MemberInfoAdmin = (props: MemberInfoAdminProps) => {
    const { member } = props
    const {setAdmin} = useCommunityMemberSetAdmin(member.hid)
    const {revalidate, state} = useRevalidator();
    const [checked, setChecked] = useState(member.isAdmin)

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        setAdmin(event.target.checked).then(_ => revalidate())
    }

    return <ListItem>
        <ListItemText primary="Administrateur" />
        <ListItemSecondaryAction>
            <Switch checked={checked} onChange={handleSwitch} />
        </ListItemSecondaryAction>
    </ListItem>
}