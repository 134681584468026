import {PrayerEvent} from "@profusion.media/profusion-core-prayer";

export type PrayerEventWithStat = PrayerEvent & {
    count: number
}

export function getFirstOccurrences(prayerEvents: PrayerEvent[]): PrayerEventWithStat[] {
    const uniqueEvents: Map<string, PrayerEventWithStat> = new Map();

    for (const event of prayerEvents) {
        if (!uniqueEvents.has(event.createdBy.hid)) {
            uniqueEvents.set(event.createdBy.hid, {...event, count:1});
        } else {
            const old = uniqueEvents.get(event.createdBy.hid) as PrayerEventWithStat;
            uniqueEvents.set(event.createdBy.hid, {...old, count: old.count+1});
        }
    }
    return [...uniqueEvents.values()]
}