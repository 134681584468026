import React from "react"
import {FormContextProvider} from "../contexts";
import {FormProps} from "../typings";
import {FormFieldProvider} from "../../FormValidation/context";

export const Form = (props: FormProps) => {

    return <FormContextProvider
        loading={props.loading ?? false}
        updating={props.updating ?? false}
        canSubmit={props.canSubmit ?? true}
        errors={props.errors}
        onSubmit={props.onSubmit}
        onCancel={props.onCancel}
    >
        <FormFieldProvider errors={props.errors}>
            {props.children}
        </FormFieldProvider>
    </FormContextProvider>
}