import {IconButton, InputAdornment} from "@mui/material";
import React, {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import {PasswordInputProps} from "./typings";
import TextInput from "../TextInput/TextInput";

export const PasswordInput = (props: PasswordInputProps) => {
    const {value, onChange, onValueChange, label, ...allProps} = props
    const [hidden, setHidden] = useState<boolean>(true)

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return <TextInput onChange={e => onChange(e.target.value)}
                      onValueChange={onValueChange}
                      value={value}
                      name={props.name}
                      type={hidden ? 'password' : 'text'}
                      InputProps={{
                          autoComplete: allProps.autoComplete,
                          endAdornment: <InputAdornment position="end">
                              <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={e => setHidden(!hidden)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                  {hidden ? <VisibilityOff/> : <Visibility/>}
                              </IconButton>
                          </InputAdornment>

                      }}
                      margin="normal"
                      required={props.required}
                      label={label}
                      autoComplete={allProps.autoComplete}
    />
}

