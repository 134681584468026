import {Await, Outlet, useRouteLoaderData} from "react-router-dom";
import {ActiveSubscriber, Group, Subscriber} from "@profusion.media/profusion-core-group";
import List from "@mui/material/List";
import {Chip, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {LoadDetailsReturn} from "../../loaders";
import {Suspense, useEffect, useState} from "react";
import {SubscriberAvatar} from "./SubscriberAvatar";
import {WhiteBox} from "@components/WhiteBox";
import {GroupSubscriberEmpty} from "@groups/Groups/GroupDetails/components/SubscriberList/GroupSubscriberEmpty";
import {CommunityMemberBottomSheet} from "@community/screens/members/drawer/CommunityMemberBottomSheet";
import {CommunityMember} from "@graph";
import {
    GroupMemberBottomSheet
} from "@groups/Groups/GroupDetails/components/SubscriberList/drawer/GroupMemberBottomSheet";

export const GroupSubscriberList = () => {
    const {
        group: groupData,
        subscribers: subscribersData
    } = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const [group, setGroup] = useState<Group>()
    const [selectedMember, setSelectedMember] = useState<string>("")

    useEffect(() => {
        if (groupData) {
            groupData.then(response => setGroup(response.data))
        }
    }, [groupData]);

    const handleCloseMemberDrawer = () => {
        setSelectedMember("")
    }


    if (!group) return

    const handleOpenMemberDrawer = (hid: string) => {
        if (group.userRole?.isHost || group.userRole?.isCreator || group.userRole?.isCommunityAdmin) {
             setSelectedMember(hid)
        }
    }


    return <Suspense fallback={<Typography>Loading subscribers...</Typography>}>

        <Await resolve={subscribersData}>
            {({data: subscribers}) => subscribers.length
                ? <><List>
                    {subscribers.map((subscriber: ActiveSubscriber) => <WhiteBox sx={{mt: 1}}>
                            <ListItem key={subscriber.hid}
                                      onClick={_ => handleOpenMemberDrawer(subscriber.hid)}>


                                <ListItemIcon>
                                    <SubscriberAvatar subscriber={subscriber}/>
                                </ListItemIcon>
                                <ListItemText primary={subscriber.user.displayName}
                                              secondary={subscriber.isHost && <Chip label={subscriber.isHost
                                                  ? "Animateur" : ""}/>}
                                />
                            </ListItem>
                        </WhiteBox>
                    )}
                </List>

                <GroupMemberBottomSheet
                    subscriber={subscribers.find((m: Subscriber) => m.hid === selectedMember)}
                    open={!!selectedMember}
                    group={group}
                    onClose={handleCloseMemberDrawer}/>
                </>
            : <GroupSubscriberEmpty group={group} />}
        </Await>
        <Outlet/>
    </Suspense>

}