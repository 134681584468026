import {gql} from "@profusion.media/profusion-core";

export const NOTIFICATION_TOKEN_REGISTER_MUTATION = gql`
  mutation notificationTokenRegister($input: NotificationTokenInput!) {
    accountNotificationTokenRegister(input: $input) {
      user {
        hid
      }
      errors {
        field
        code
      }
    }
  }`;
