import {Community, CommunityTag} from "@profusion.media/graph";

type CommunityTree = Record<string, Community>;

type CommunityTreeNode = Community & {
    children?: CommunityTree
}

export const createTree = (data: Community[]) => {
    let tree : CommunityTree = {}
    data.map(c => {
        if (c.parentHid == null) {
            tree[c.hid] = c;
        }
    })
    return tree
}
export const makeTagList = (tags: CommunityTag[] | undefined) => {
    if (!tags || !tags.length) {
        return "Aucune"
    } else {
        return tags.map(t => t.name).join(", ")
    }
}