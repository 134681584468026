import {gql} from "@profusion.media/profusion-core";
import {PRAYER_EVENT_FIELDS} from "../fragments";

export const PRAYER_EVENT_CREATE_MUTATION = gql`
  ${PRAYER_EVENT_FIELDS}
  mutation prayerEventCreate($input: PrayerEventCreateInput!) {
    prayerEventCreate(input: $input) {
      prayerEvent {
        ...PrayerEventFields
      }
      errors {
        field
        code
      }
    }
  }`;

export const PRAYER_ADD_PRAYED_EVENT_MUTATION = gql`
  ${PRAYER_EVENT_FIELDS}
  mutation prayerAddPrayedEvent($input: PrayerEventPrayerCreateInput!) {
    prayerAddPrayedEvent(input: $input) {
      prayerEvent {
        ...PrayerEventFields
      }
      errors {
        field
        code
      }
    }
  }`;
