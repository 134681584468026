import {useGraphMutation} from "@profusion.media/profusion-core";
import {UserRegistrationInput, UserRegistrationResponse} from "../schema";
import {ACCOUNT_REGISTER} from "../schema/mutations/account_register";

export const useAccountRegister = () => {
  return useGraphMutation<UserRegistrationResponse, UserRegistrationInput>(
    ACCOUNT_REGISTER, {
      isPrivate: false,
    });
};
