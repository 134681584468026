import React, {useContext, useEffect, useState} from 'react';
import {Grid, Stack} from "@mui/material";
import {TextInput} from "../TextInput";
import {DateInputProps, DateValue} from "./typings";
import {MonthInput} from "./MonthInput";
import {useTranslation} from "react-i18next"
import {useFormFieldError} from "../Form";
import {FormStepContext} from "../MultiPartForm";
import {useFormFieldContext} from "../FormValidation";

const toDateValue = (dateString: string): DateValue => {
  const [year, month, day] = dateString.split('-');
  if (!year || !month ||!day) return {year: undefined, month: undefined, day: undefined}
  return {year: parseInt(year), month: parseInt(month),  day: parseInt(day)};
}

const formatDateValue = (dateValue: DateValue): string => {
  const { year, month, day } = dateValue;
  if (!year || !month ||!day) return '';
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export const DateInput: React.FC<DateInputProps> = (props: DateInputProps) => {
    let {name, value: originalValue, onChange, onValueChange,required, labels} = props
    const [value, setValue] = useState<DateValue>(typeof originalValue === "string" ? toDateValue(originalValue) : originalValue)
    const stepInfo = useContext(FormStepContext)
    const error = useFormFieldError(name)
    const context = useFormFieldContext()

    const {t} = useTranslation()
    const fieldLabels = {
        day: labels?.day ?? t("date.labels.day", "Jour"),
        month: labels?.month ?? t("date.labels.month", "Mois"),
        year: labels?.year ?? t("date.labels.year", "Année"),
    }


    const handleChange = (field: string, v: string | number) => {
        const newValue: DateValue = {...value, [field]: v}
        setValue(newValue)

        if (isValid()) {
            if (onChange) onChange(newValue)
            if (onValueChange) {
                onValueChange(name, formatDateValue(newValue))
            }
        }
    }

    const isValid = () => {
        return !!value.day && !!value.month && !!value.year
    }

    useEffect(() => {
        updateField()
    }, []);

    useEffect(() => {
        updateField()
    }, [props.value]);

    const updateField = () => {
        context.onChange({
            isError: false,
            tags: [stepInfo.stepId],
            name: props.name,
            value: props.value,
            isValid: !props.required || isValid()
        })
        console.log("update field", {
            isError: false,
            tags: [stepInfo.stepId],
            name: props.name,
            value: props.value,
            isValid: !props.required || isValid()
        })
    }

    return (
        <Stack direction="row" spacing={1}>

                <TextInput value={value.day ?? ""}
                           type="number"
                           error={error.isError}
                           inputProps={{
                               min: 1,
                               max: 31
                           }}
                           label={fieldLabels.day}
                           onChange={(v) => handleChange("day", parseInt(v.target.value))}/>

                <MonthInput value={value.month}
                            error={error.isError}
                            label={fieldLabels.month}
                            onChange={(v) => handleChange("month", v)}/>

                <TextInput value={value.year ?? ""}
                           type="number"
                           error={error.isError}
                           label={fieldLabels.year}
                           inputProps={{
                               min: 1900,
                               max: (new Date()).getFullYear()
                           }}
                           onChange={(v) => handleChange("year", parseInt(v.target.value))}/>

        </Stack>
    );
};