import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {PRAYER_REQUEST_DETAILS_QUERY, PrayerRequest} from "../schema";

export const loadPrayerRequestDetails = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerRequest> => {
  return executeQuery<PrayerRequest>(client, PRAYER_REQUEST_DETAILS_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}
