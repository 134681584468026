import {DocumentNode, gql} from "@profusion.media/profusion-core"

export const AVATAR_FIELDS: DocumentNode = gql`
  fragment AvatarFields on Avatar {
    __typename
    hid
    smallUrl
    mediumUrl
    largeUrl
  }`
