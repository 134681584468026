import {executeMutation} from "@profusion.media/profusion-core";
import {CommunityMember, CommunityMemberSetAllowPrayerInput, MutationCommunityMemberSetAllowPrayerArgs} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_MEMBER_SET_ALLOW_PRAYER_MUTATION} from "@community/schema/mutations/community_member";


export const useCommunityMemberSetAllowPrayer = (memberHid: string) => {
    return {
        setAllowPrayer: (isAllowed: boolean) => {
            return executeMutation<CommunityMember, MutationCommunityMemberSetAllowPrayerArgs>(
                profusion,
                COMMUNITY_MEMBER_SET_ALLOW_PRAYER_MUTATION,
                {
                    input: {
                        isAllowed,
                        memberHid,
                    }
                },
                {
                    isPrivate: true
                })
        }
    }
}