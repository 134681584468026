import {UserRegistrationInput} from "./types";

export const defaultUserRegistration: UserRegistrationInput = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  phoneNumber: "",
  language: "fr-FR",
  invitationCode: ""
}
