import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";


export const ORGANIZATION_UPDATE_MUTATION = gql`
    ${ORGANIZATION_FIELDS}
    mutation organizationUpdate($organization: OrganizationUpdateInput!, $avatar: AvatarCreateInput, $removeAvatar: Boolean) {
        updateOrganization(organization: $organization, avatar: $avatar, removeAvatar: $removeAvatar) {
            organization {
                ...organizationFields
            }
            errors {
                group
                field
                code
            }
        }
    }`;
