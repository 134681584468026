import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate} from "react-router-dom";
import React, {Suspense, useState} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Community} from "@graph";
import {PeopleSearchOption} from "./options/PeopleSearch";
import {ShareLinkOption} from "./options/ShareLink";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList} from "@mui/lab";


type Params = {
    hid: string
    community: string
}

export const CommunityInvitation = () => {
    const {community} = useCommunityDetailsLoader("communityDetailsAdmin")
    const navigate = useNavigate()


    const getUrl = (c: Community) => {
        return `https://app.profusion.media/community/${c.hid}`
    }

    const onClose = () => {
        navigate("../members")
    }


    return <Suspense>
        <Await resolve={community}>
            {({data}) =>
                <FullScreenDialog title="Inviter dans la communauté"
                                  subtitle={data.name}
                                  onClose={onClose}>

                    <ShareLinkOption community={data} url={getUrl(data)} isAdmin={false} />
                </FullScreenDialog>
            }
        </Await>

    </Suspense>
}