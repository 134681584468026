import {CountryDefinition} from "./types";

export const COUNTRIES : Array<CountryDefinition> = [
  {
    "alpha2": "AF",
    "i18nCode": "geo.country.AF"
  },
  {
    "alpha2": "AX",
    "i18nCode": "geo.country.AX"
  },
  {
    "alpha2": "AL",
    "i18nCode": "geo.country.AL"
  },
  {
    "alpha2": "DZ",
    "i18nCode": "geo.country.DZ"
  },
  {
    "alpha2": "AS",
    "i18nCode": "geo.country.AS"
  },
  {
    "alpha2": "AD",
    "i18nCode": "geo.country.AD"
  },
  {
    "alpha2": "AO",
    "i18nCode": "geo.country.AO"
  },
  {
    "alpha2": "AI",
    "i18nCode": "geo.country.AI"
  },
  {
    "alpha2": "AQ",
    "i18nCode": "geo.country.AQ"
  },
  {
    "alpha2": "AG",
    "i18nCode": "geo.country.AG"
  },
  {
    "alpha2": "AR",
    "i18nCode": "geo.country.AR"
  },
  {
    "alpha2": "AM",
    "i18nCode": "geo.country.AM"
  },
  {
    "alpha2": "AW",
    "i18nCode": "geo.country.AW"
  },
  {
    "alpha2": "AU",
    "i18nCode": "geo.country.AU"
  },
  {
    "alpha2": "AT",
    "i18nCode": "geo.country.AT"
  },
  {
    "alpha2": "AZ",
    "i18nCode": "geo.country.AZ"

  },
  {
    "alpha2": "BS",
    "i18nCode": "geo.country.BS"

  },
  {
    "alpha2": "BH",
    "i18nCode": "geo.country.BH"

  },
  {
    "alpha2": "BD",
    "i18nCode": "geo.country.BD"

  },
  {
    "alpha2": "BB",
    "i18nCode": "geo.country.BB"

  },
  {
    "alpha2": "BY",
    "i18nCode": "geo.country.BY"

  },
  {
    "alpha2": "BE",
    "i18nCode": "geo.country.BE"

  },
  {
    "alpha2": "BZ",
    "i18nCode": "geo.country.BZ"

  },
  {
    "alpha2": "BJ",
    "i18nCode": "geo.country.BJ"

  },
  {
    "alpha2": "BM",
    "i18nCode": "geo.country.BM"

  },
  {
    "alpha2": "BT",
    "i18nCode": "geo.country.BT"

  },
  {
    "alpha2": "BO",
    "i18nCode": "geo.country.BO"

  },
  {
    "alpha2": "BA",
    "i18nCode": "geo.country.BA"

  },
  {
    "alpha2": "BW",
    "i18nCode": "geo.country.BW"

  },
  {
    "alpha2": "BV",
    "i18nCode": "geo.country.BV"

  },
  {
    "alpha2": "BR",
    "i18nCode": "geo.country.BR"

  },
  {
    "alpha2": "IO",
    "i18nCode": "geo.country.IO"

  },
  {
    "alpha2": "BN",
    "i18nCode": "geo.country.BN"

  },
  {
    "alpha2": "BG",
    "i18nCode": "geo.country.BG"

  },
  {
    "alpha2": "BF",
    "i18nCode": "geo.country.BF"

  },
  {
    "alpha2": "BI",
    "i18nCode": "geo.country.BI"

  },
  {
    "alpha2": "KH",
    "i18nCode": "geo.country.KH"

  },
  {
    "alpha2": "CM",
    "i18nCode": "geo.country.CM"

  },
  {
    "alpha2": "CA",
    "i18nCode": "geo.country.CA",
    "provinces": [
      {
        "code": "CA_AB",
        "name": "Alberta"
      },
      {
        "code": "CA_BC",
        "name": "British Columbia"
      },
      {
        "code": "CA_MB",
        "name": "Manitoba"
      },
      {
        "code": "CA_NB",
        "name": "New Brunswick"
      },
      {
        "code": "CA_NL",
        "name": "Newfoundland and Labrador"
      },
      {
        "code": "CA_NT",
        "name": "Northwest Territories"
      },
      {
        "code": "CA_NS",
        "name": "Nova Scotia"
      },
      {
        "code": "CA_NU",
        "name": "Nunavut"
      },
      {
        "code": "CA_ON",
        "name": "Ontario"
      },
      {
        "code": "CA_PE",
        "name": "Prince Edward Island"
      },
      {
        "code": "CA_QC",
        "name": "Quebec"
      },
      {
        "code": "CA_SK",
        "name": "Saskatchewan"
      },
      {
        "code": "CA_YT",
        "name": "Yukon"
      }
    ]
  },
  {
    "alpha2": "CV",
    "i18nCode": "geo.country.CV"

  },
  {
    "alpha2": "KY",
    "i18nCode": "geo.country.KY"

  },
  {
    "alpha2": "CF",
    "i18nCode": "geo.country.CF"

  },
  {
    "alpha2": "TD",
    "i18nCode": "geo.country.TD"

  },
  {
    "alpha2": "CL",
    "i18nCode": "geo.country.CL"

  },
  {
    "alpha2": "CN",
    "i18nCode": "geo.country.CN"

  },
  {
    "alpha2": "CX",
    "i18nCode": "geo.country.CX"

  },
  {
    "alpha2": "CC",
    "i18nCode": "geo.country.CC"

  },
  {
    "alpha2": "CO",
    "i18nCode": "geo.country.CO"

  },
  {
    "alpha2": "KM",
    "i18nCode": "geo.country.KM"

  },
  {
    "alpha2": "CG",
    "i18nCode": "geo.country.CG"

  },
  {
    "alpha2": "CD",
    "i18nCode": "geo.country.CD"

  },
  {
    "alpha2": "CK",
    "i18nCode": "geo.country.CK"

  },
  {
    "alpha2": "CR",
    "i18nCode": "geo.country.CR"

  },
  {
    "alpha2": "CI",
    "i18nCode": "geo.country.CI"

  },
  {
    "alpha2": "HR",
    "i18nCode": "geo.country.HR"

  },
  {
    "alpha2": "CU",
    "i18nCode": "geo.country.CU"

  },
  {
    "alpha2": "CY",
    "i18nCode": "geo.country.CY"

  },
  {
    "alpha2": "CZ",
    "i18nCode": "geo.country.CZ"

  },
  {
    "alpha2": "DK",
    "i18nCode": "geo.country.DK"

  },
  {
    "alpha2": "DJ",
    "i18nCode": "geo.country.DJ"

  },
  {
    "alpha2": "DM",
    "i18nCode": "geo.country.DM"

  },
  {
    "alpha2": "DO",
    "i18nCode": "geo.country.DO"

  },
  {
    "alpha2": "EC",
    "i18nCode": "geo.country.EC"

  },
  {
    "alpha2": "EG",
    "i18nCode": "geo.country.EG"

  },
  {
    "alpha2": "SV",
    "i18nCode": "geo.country.SV"

  },
  {
    "alpha2": "GQ",
    "i18nCode": "geo.country.GQ"

  },
  {
    "alpha2": "ER",
    "i18nCode": "geo.country.ER"

  },
  {
    "alpha2": "EE",
    "i18nCode": "geo.country.EE"

  },
  {
    "alpha2": "ET",
    "i18nCode": "geo.country.ET"

  },
  {
    "alpha2": "FK",
    "i18nCode": "geo.country.FK"

  },
  {
    "alpha2": "FO",
    "i18nCode": "geo.country.FO"

  },
  {
    "alpha2": "FJ",
    "i18nCode": "geo.country.FJ"

  },
  {
    "alpha2": "FI",
    "i18nCode": "geo.country.FI"

  },
  {
    "alpha2": "FR",
    "i18nCode": "geo.country.FR"

  },
  {
    "alpha2": "GF",
    "i18nCode": "geo.country.GF"

  },
  {
    "alpha2": "PF",
    "i18nCode": "geo.country.PF"

  },
  {
    "alpha2": "TF",
    "i18nCode": "geo.country.TF"

  },
  {
    "alpha2": "GA",
    "i18nCode": "geo.country.GA"

  },
  {
    "alpha2": "GM",
    "i18nCode": "geo.country.GM"

  },
  {
    "alpha2": "GE",
    "i18nCode": "geo.country.GE"

  },
  {
    "alpha2": "DE",
    "i18nCode": "geo.country.DE"

  },
  {
    "alpha2": "GH",
    "i18nCode": "geo.country.GH"

  },
  {
    "alpha2": "GI",
    "i18nCode": "geo.country.GI"

  },
  {
    "alpha2": "GR",
    "i18nCode": "geo.country.GR"

  },
  {
    "alpha2": "GL",
    "i18nCode": "geo.country.GL"

  },
  {
    "alpha2": "GD",
    "i18nCode": "geo.country.GD"

  },
  {
    "alpha2": "GP",
    "i18nCode": "geo.country.GP"

  },
  {
    "alpha2": "GU",
    "i18nCode": "geo.country.GU"

  },
  {
    "alpha2": "GT",
    "i18nCode": "geo.country.GT"

  },
  {
    "alpha2": "GG",
    "i18nCode": "geo.country.GG"

  },
  {
    "alpha2": "GN",
    "i18nCode": "geo.country.GN"

  },
  {
    "alpha2": "GW",
    "i18nCode": "geo.country.GW"

  },
  {
    "alpha2": "GY",
    "i18nCode": "geo.country.GY"

  },
  {
    "alpha2": "HT",
    "i18nCode": "geo.country.HT"

  },
  {
    "alpha2": "HM",
    "i18nCode": "geo.country.HM"

  },
  {
    "alpha2": "VA",
    "i18nCode": "geo.country.VA"

  },
  {
    "alpha2": "HN",
    "i18nCode": "geo.country.HN"

  },
  {
    "alpha2": "HK",
    "i18nCode": "geo.country.HK"

  },
  {
    "alpha2": "HU",
    "i18nCode": "geo.country.HU"

  },
  {
    "alpha2": "IS",
    "i18nCode": "geo.country.IS"

  },
  {
    "alpha2": "IN",
    "i18nCode": "geo.country.IN"

  },
  {
    "alpha2": "ID",
    "i18nCode": "geo.country.ID"

  },
  {
    "alpha2": "IR",
    "i18nCode": "geo.country.IR"

  },
  {
    "alpha2": "IQ",
    "i18nCode": "geo.country.IQ"

  },
  {
    "alpha2": "IE",
    "i18nCode": "geo.country.IE"

  },
  {
    "alpha2": "IM",
    "i18nCode": "geo.country.IM"

  },
  {
    "alpha2": "IL",
    "i18nCode": "geo.country.IL"

  },
  {
    "alpha2": "IT",
    "i18nCode": "geo.country.IT"

  },
  {
    "alpha2": "JM",
    "i18nCode": "geo.country.JM"

  },
  {
    "alpha2": "JP",
    "i18nCode": "geo.country.JP"

  },
  {
    "alpha2": "JE",
    "i18nCode": "geo.country.JE"

  },
  {
    "alpha2": "JO",
    "i18nCode": "geo.country.JO"

  },
  {
    "alpha2": "KZ",
    "i18nCode": "geo.country.KZ"

  },
  {
    "alpha2": "KE",
    "i18nCode": "geo.country.KE"

  },
  {
    "alpha2": "KI",
    "i18nCode": "geo.country.KI"

  },
  {
    "alpha2": "KP",
    "i18nCode": "geo.country.KP"

  },
  {
    "alpha2": "KR",
    "i18nCode": "geo.country.KR"

  },
  {
    "alpha2": "KW",
    "i18nCode": "geo.country.KW"

  },
  {
    "alpha2": "KG",
    "i18nCode": "geo.country.KG"

  },
  {
    "alpha2": "LA",
    "i18nCode": "geo.country.LA"

  },
  {
    "alpha2": "LV",
    "i18nCode": "geo.country.LV"

  },
  {
    "alpha2": "LB",
    "i18nCode": "geo.country.LB"

  },
  {
    "alpha2": "LS",
    "i18nCode": "geo.country.LS"

  },
  {
    "alpha2": "LR",
    "i18nCode": "geo.country.LR"

  },
  {
    "alpha2": "LY",
    "i18nCode": "geo.country.LY"

  },
  {
    "alpha2": "LI",
    "i18nCode": "geo.country.LI"

  },
  {
    "alpha2": "LT",
    "i18nCode": "geo.country.LT"

  },
  {
    "alpha2": "LU",
    "i18nCode": "geo.country.LU"

  },
  {
    "alpha2": "MO",
    "i18nCode": "geo.country.MO"

  },
  {
    "alpha2": "MK",
    "i18nCode": "geo.country.MK"

  },
  {
    "alpha2": "MG",
    "i18nCode": "geo.country.MG"

  },
  {
    "alpha2": "MW",
    "i18nCode": "geo.country.MW"

  },
  {
    "alpha2": "MY",
    "i18nCode": "geo.country.MY"

  },
  {
    "alpha2": "MV",
    "i18nCode": "geo.country.MV"

  },
  {
    "alpha2": "ML",
    "i18nCode": "geo.country.ML"

  },
  {
    "alpha2": "MT",
    "i18nCode": "geo.country.MT"

  },
  {
    "alpha2": "MH",
    "i18nCode": "geo.country.MH"

  },
  {
    "alpha2": "MQ",
    "i18nCode": "geo.country.MQ"

  },
  {
    "alpha2": "MR",
    "i18nCode": "geo.country.MR"

  },
  {
    "alpha2": "MU",
    "i18nCode": "geo.country.MU"

  },
  {
    "alpha2": "YT",
    "i18nCode": "geo.country.YT"

  },
  {
    "alpha2": "MX",
    "i18nCode": "geo.country.MX"

  },
  {
    "alpha2": "FM",
    "i18nCode": "geo.country.FM"

  },
  {
    "alpha2": "MD",
    "i18nCode": "geo.country.MD"

  },
  {
    "alpha2": "MC",
    "i18nCode": "geo.country.MC"

  },
  {
    "alpha2": "MN",
    "i18nCode": "geo.country.MN"

  },
  {
    "alpha2": "ME",
    "i18nCode": "geo.country.ME"

  },
  {
    "alpha2": "MS",
    "i18nCode": "geo.country.MS"

  },
  {
    "alpha2": "MA",
    "i18nCode": "geo.country.MA"

  },
  {
    "alpha2": "MZ",
    "i18nCode": "geo.country.MZ"

  },
  {
    "alpha2": "MM",
    "i18nCode": "geo.country.MM"

  },
  {
    "alpha2": "NA",
    "i18nCode": "geo.country.NA"

  },
  {
    "alpha2": "NR",
    "i18nCode": "geo.country.NR"

  },
  {
    "alpha2": "NP",
    "i18nCode": "geo.country.NP"

  },
  {
    "alpha2": "NL",
    "i18nCode": "geo.country.NL"

  },
  {
    "alpha2": "AN",
    "i18nCode": "geo.country.AN"

  },
  {
    "alpha2": "NC",
    "i18nCode": "geo.country.NC"

  },
  {
    "alpha2": "NZ",
    "i18nCode": "geo.country.NZ"

  },
  {
    "alpha2": "NI",
    "i18nCode": "geo.country.NI"

  },
  {
    "alpha2": "NE",
    "i18nCode": "geo.country.NE"

  },
  {
    "alpha2": "NG",
    "i18nCode": "geo.country.NG"

  },
  {
    "alpha2": "NU",
    "i18nCode": "geo.country.NU"

  },
  {
    "alpha2": "NF",
    "i18nCode": "geo.country.NF"

  },
  {
    "alpha2": "MP",
    "i18nCode": "geo.country.MP"

  },
  {
    "alpha2": "NO",
    "i18nCode": "geo.country.NO"

  },
  {
    "alpha2": "OM",
    "i18nCode": "geo.country.OM"

  },
  {
    "alpha2": "PK",
    "i18nCode": "geo.country.PK"

  },
  {
    "alpha2": "PW",
    "i18nCode": "geo.country.PW"

  },
  {
    "alpha2": "PS",
    "i18nCode": "geo.country.PS"

  },
  {
    "alpha2": "PA",
    "i18nCode": "geo.country.PA"

  },
  {
    "alpha2": "PG",
    "i18nCode": "geo.country.PG"

  },
  {
    "alpha2": "PY",
    "i18nCode": "geo.country.PY"

  },
  {
    "alpha2": "PE",
    "i18nCode": "geo.country.PE"

  },
  {
    "alpha2": "PH",
    "i18nCode": "geo.country.PH"

  },
  {
    "alpha2": "PN",
    "i18nCode": "geo.country.PN"

  },
  {
    "alpha2": "PL",
    "i18nCode": "geo.country.PL"

  },
  {
    "alpha2": "PT",
    "i18nCode": "geo.country.PT"

  },
  {
    "alpha2": "PR",
    "i18nCode": "geo.country.PR"

  },
  {
    "alpha2": "QA",
    "i18nCode": "geo.country.QA"

  },
  {
    "alpha2": "RE",
    "i18nCode": "geo.country.RE"

  },
  {
    "alpha2": "RO",
    "i18nCode": "geo.country.RO"

  },
  {
    "alpha2": "RU",
    "i18nCode": "geo.country.RU"

  },
  {
    "alpha2": "RW",
    "i18nCode": "geo.country.RW"

  },
  {
    "alpha2": "SH",
    "i18nCode": "geo.country.SH"

  },
  {
    "alpha2": "KN",
    "i18nCode": "geo.country.KN"

  },
  {
    "alpha2": "LC",
    "i18nCode": "geo.country.LC"

  },
  {
    "alpha2": "PM",
    "i18nCode": "geo.country.PM"

  },
  {
    "alpha2": "VC",
    "i18nCode": "geo.country.VC"

  },
  {
    "alpha2": "WS",
    "i18nCode": "geo.country.WS"

  },
  {
    "alpha2": "SM",
    "i18nCode": "geo.country.SM"

  },
  {
    "alpha2": "ST",
    "i18nCode": "geo.country.ST"

  },
  {
    "alpha2": "SA",
    "i18nCode": "geo.country.SA"

  },
  {
    "alpha2": "SN",
    "i18nCode": "geo.country.SN"

  },
  {
    "alpha2": "RS",
    "i18nCode": "geo.country.RS"

  },
  {
    "alpha2": "SC",
    "i18nCode": "geo.country.SC"

  },
  {
    "alpha2": "SL",
    "i18nCode": "geo.country.SL"

  },
  {
    "alpha2": "SG",
    "i18nCode": "geo.country.SG"

  },
  {
    "alpha2": "SK",
    "i18nCode": "geo.country.SK"

  },
  {
    "alpha2": "SI",
    "i18nCode": "geo.country.SI"

  },
  {
    "alpha2": "SB",
    "i18nCode": "geo.country.SB"

  },
  {
    "alpha2": "SO",
    "i18nCode": "geo.country.SO"

  },
  {
    "alpha2": "ZA",
    "i18nCode": "geo.country.ZA"

  },
  {
    "alpha2": "GS",
    "i18nCode": "geo.country.GS"

  },
  {
    "alpha2": "ES",
    "i18nCode": "geo.country.ES"

  },
  {
    "alpha2": "LK",
    "i18nCode": "geo.country.LK"

  },
  {
    "alpha2": "SD",
    "i18nCode": "geo.country.SD"

  },
  {
    "alpha2": "SR",
    "i18nCode": "geo.country.SR"

  },
  {
    "alpha2": "SJ",
    "i18nCode": "geo.country.SJ"

  },
  {
    "alpha2": "SZ",
    "i18nCode": "geo.country.SZ"

  },
  {
    "alpha2": "SE",
    "i18nCode": "geo.country.SE"

  },
  {
    "alpha2": "CH",
    "i18nCode": "geo.country.CH"

  },
  {
    "alpha2": "SY",
    "i18nCode": "geo.country.SY"

  },
  {
    "alpha2": "TW",
    "i18nCode": "geo.country.TW"

  },
  {
    "alpha2": "TJ",
    "i18nCode": "geo.country.TJ"

  },
  {
    "alpha2": "TZ",
    "i18nCode": "geo.country.TZ"

  },
  {
    "alpha2": "TH",
    "i18nCode": "geo.country.TH"

  },
  {
    "alpha2": "TL",
    "i18nCode": "geo.country.TL"

  },
  {
    "alpha2": "TG",
    "i18nCode": "geo.country.TG"

  },
  {
    "alpha2": "TK",
    "i18nCode": "geo.country.TK"

  },
  {
    "alpha2": "TO",
    "i18nCode": "geo.country.TO"

  },
  {
    "alpha2": "TT",
    "i18nCode": "geo.country.TT"

  },
  {
    "alpha2": "TN",
    "i18nCode": "geo.country.TN"

  },
  {
    "alpha2": "TR",
    "i18nCode": "geo.country.TR"

  },
  {
    "alpha2": "TM",
    "i18nCode": "geo.country.TM"

  },
  {
    "alpha2": "TC",
    "i18nCode": "geo.country.TC"

  },
  {
    "alpha2": "TV",
    "i18nCode": "geo.country.TV"

  },
  {
    "alpha2": "UG",
    "i18nCode": "geo.country.UG"

  },
  {
    "alpha2": "UA",
    "i18nCode": "geo.country.UA"

  },
  {
    "alpha2": "AE",
    "i18nCode": "geo.country.AE"

  },
  {
    "alpha2": "GB",
    "i18nCode": "geo.country.GB"

  },
  {
    "alpha2": "US",
    "i18nCode": "geo.country.US",
    "provinces": [
      {
        "code": "US_AL",
        "name": "Alabama"
      },
      {
        "code": "US_AK",
        "name": "Alaska"
      },
      {
        "code": "US_AZ",
        "name": "Arizona"
      },
      {
        "code": "US_AR",
        "name": "Arkansas"
      },
      {
        "code": "US_CA",
        "name": "California"
      },
      {
        "code": "US_CO",
        "name": "Colorado"
      },
      {
        "code": "US_CT",
        "name": "Connecticut"
      },
      {
        "code": "US_DE",
        "name": "Delaware"
      },
      {
        "code": "US_DC",
        "name": "District of Columbia"
      },
      {
        "code": "US_FL",
        "name": "Florida"
      },
      {
        "code": "US_GA",
        "name": "Georgia"
      },
      {
        "code": "US_HI",
        "name": "Hawaii"
      },
      {
        "code": "US_ID",
        "name": "Idaho"
      },
      {
        "code": "US_IL",
        "name": "Illinois"
      },
      {
        "code": "US_IN",
        "name": "Indiana"
      },
      {
        "code": "US_IA",
        "name": "Iowa"
      },
      {
        "code": "US_KS",
        "name": "Kansas"
      },
      {
        "code": "US_KY",
        "name": "Kentucky"
      },
      {
        "code": "US_LA",
        "name": "Louisiana"
      },
      {
        "code": "US_ME",
        "name": "Maine"
      },
      {
        "code": "US_MD",
        "name": "Maryland"
      },
      {
        "code": "US_MA",
        "name": "Massachusetts"
      },
      {
        "code": "US_MI",
        "name": "Michigan"
      },
      {
        "code": "US_MN",
        "name": "Minnesota"
      },
      {
        "code": "US_MS",
        "name": "Mississippi"
      },
      {
        "code": "US_MO",
        "name": "Missouri"
      },
      {
        "code": "US_MT",
        "name": "Montana"
      },
      {
        "code": "US_NE",
        "name": "Nebraska"
      },
      {
        "code": "US_NV",
        "name": "Nevada"
      },
      {
        "code": "US_NH",
        "name": "New Hampshire"
      },
      {
        "code": "US_NJ",
        "name": "New Jersey"
      },
      {
        "code": "US_NM",
        "name": "New Mexico"
      },
      {
        "code": "US_NY",
        "name": "New York"
      },
      {
        "code": "US_NC",
        "name": "North Carolina"
      },
      {
        "code": "US_ND",
        "name": "North Dakota"
      },
      {
        "code": "US_OH",
        "name": "Ohio"
      },
      {
        "code": "US_OK",
        "name": "Oklahoma"
      },
      {
        "code": "US_OR",
        "name": "Oregon"
      },
      {
        "code": "US_PA",
        "name": "Pennsylvania"
      },
      {
        "code": "US_RI",
        "name": "Rhode Island"
      },
      {
        "code": "US_SC",
        "name": "South Carolina"
      },
      {
        "code": "US_SD",
        "name": "South Dakota"
      },
      {
        "code": "US_TN",
        "name": "Tennessee"
      },
      {
        "code": "US_TX",
        "name": "Texas"
      },
      {
        "code": "US_UT",
        "name": "Utah"
      },
      {
        "code": "US_VT",
        "name": "Vermont"
      },
      {
        "code": "US_VA",
        "name": "Virginia"
      },
      {
        "code": "US_WA",
        "name": "Washington"
      },
      {
        "code": "US_WV",
        "name": "West Virginia"
      },
      {
        "code": "US_WI",
        "name": "Wisconsin"
      },
      {
        "code": "US_WY",
        "name": "Wyoming"
      }
    ]
  },
  {
    "alpha2": "UM",
    "i18nCode": "geo.country.UM"

  },
  {
    "alpha2": "UY",
    "i18nCode": "geo.country.UY"

  },
  {
    "alpha2": "UZ",
    "i18nCode": "geo.country.UZ"

  },
  {
    "alpha2": "VU",
    "i18nCode": "geo.country.VU"

  },
  {
    "alpha2": "VE",
    "i18nCode": "geo.country.VE"

  },
  {
    "alpha2": "VN",
    "i18nCode": "geo.country.VN"

  },
  {
    "alpha2": "VG",
    "i18nCode": "geo.country.VG"

  },
  {
    "alpha2": "VI",
    "i18nCode": "geo.country.VI"

  },
  {
    "alpha2": "WF",
    "i18nCode": "geo.country.WF"

  },
  {
    "alpha2": "EH",
    "i18nCode": "geo.country.EH"

  },
  {
    "alpha2": "YE",
    "i18nCode": "geo.country.YE"

  },
  {
    "alpha2": "ZM",
    "i18nCode": "geo.country.ZM"

  },
  {
    "alpha2": "ZW",
    "i18nCode": "geo.country.ZW"

  }
]
