import {
  ActiveSubscriber,
  GROUP_INVITATION_LIST,
  GROUP_SUBSCRIBER_LIST,
  GROUP_SUBSCRIBER_SETTINGS,
  PendingInvitation
} from "../schema";
import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {NotificationSettings} from "@profusion.media/profusion-core-notification";

export const loadGroupActiveSubscribers = (client: ProfusionClient, groupHid: string): GraphQueryPromise<ActiveSubscriber[]> => {
  return executeQuery<ActiveSubscriber[]>(client, GROUP_SUBSCRIBER_LIST, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}

export const loadGroupPendingInvitations = (client: ProfusionClient, groupHid: string): GraphQueryPromise<PendingInvitation[]> => {
  return executeQuery<PendingInvitation[]>(client, GROUP_INVITATION_LIST, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}

export const loaderGroupSubscriberSettings = (client: ProfusionClient, groupHid: string) :GraphQueryPromise<NotificationSettings> => {
  return executeQuery<NotificationSettings>(client, GROUP_SUBSCRIBER_SETTINGS, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}
