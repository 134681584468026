import {LoadPrayerRequestListReturn} from "../loaders";
import {Suspense} from "react";
import {Await, Outlet, useLoaderData, useRouteLoaderData} from "react-router-dom";
import {PrayerRequestListItem} from "./PrayerRequestListItem";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {Stack} from "@mui/material";
import {PrayerRequestAddButton} from "./PrayerRequestAddButton";
import {LoadDetailsReturn} from "../../Groups/GroupDetails/loaders";
import {PrayerRequestListEmpty} from "./PrayerRequestListEmpty";

export const PrayerRequestList = () => {
    const {prayerRequests: requestsData} = useLoaderData() as LoadPrayerRequestListReturn
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn

    return <Await resolve={groupData}>
        {({data: group}) => <>
            <Suspense>
                <Stack spacing={1} >
                    <Await resolve={requestsData}>
                        {({data: prayerRequests}) => prayerRequests.length ? prayerRequests.map((r: PrayerRequest) => <PrayerRequestListItem
                            key={r.hid}
                            prayerRequest={r}/>) : <PrayerRequestListEmpty group={group} />}
                    </Await>
                </Stack>
            </Suspense>
            <Outlet/>
        </>}
    </Await>
}