import {gql} from "@profusion.media/profusion-core";
import {GROUP_OVERVIEW_FIELDS} from "../fragments";

export const GROUP_OVERVIEW_QUERY = gql`
  ${GROUP_OVERVIEW_FIELDS}
    query GroupOverview($groupHid: String!) {
        groupOverview(groupHid: $groupHid) {
            ...GroupOverviewFields
        }
    }`;

export const ALL_GROUP_OVERVIEW_QUERY = gql`
    ${GROUP_OVERVIEW_FIELDS}
    query AllGroupOverview {
        allGroupOverview  {
            ...GroupOverviewFields
        }
    }`;
