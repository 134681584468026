import {DropDownMenuProps, DropDownMenuPropsDefaults, DropDownMenuVariants} from "./typings";
import {Backdrop, Box, darken, Popper, Stack, styled} from "@mui/material"
import React, {useRef, useState} from "react"

interface StyledPopperProps {
    backgroundColor?: string;
    variant?: DropDownMenuVariants
}

const StyledToolsPopper = styled(Popper,{
    shouldForwardProp: (propName) => propName !== 'backgroundColor',
})<StyledPopperProps>(({theme, backgroundColor, variant}) => ({
    backgroundColor: backgroundColor || '#f0f0f5', // Utilisation du paramètre backgroundColor ou une valeur par défaut
    borderColor: darken(backgroundColor || '#f0f0f5', 0.2),
    borderStyle: "solid",
    borderSize: 1,
    padding: variant === "large" ? "30px 15px" : "0px",
    borderRadius: variant === "large" ? 20 : 5,
    color: "#454545"
}));

export const DropDownMenu = (props: DropDownMenuProps) => {
    const {children, showClose, backgroundColor, variant} = {...DropDownMenuPropsDefaults, ...props}
    const slots = {...DropDownMenuPropsDefaults.slots, ...props.slots}
    const [open, setOpen] = useState<boolean>(false);

    const anchor = useRef()

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return <div style={{position: 'relative', zIndex: 200}}>
        <Box onClick={onOpen} ref={anchor}>
            {slots.anchor}
        </Box>
        <Backdrop open={open} invisible={true} onClick={_ => onClose()}>
            <StyledToolsPopper
                backgroundColor={backgroundColor}
                variant={variant}
                open={open}
                placement="bottom-end"
                anchorEl={anchor.current}
                disablePortal={true}
            >
                {showClose && <div
                    onClick={_ => onClose()}
                    style={{position: 'absolute', top: 10, right: 10}}
                >{slots.closeButton}</div>}

                <Stack direction="column" spacing={4}>
                    {slots.title}
                    {children}
                </Stack>

            </StyledToolsPopper>
        </Backdrop>
    </div>
}