import React from "react"
import {User} from "../users/schema";

export interface ConnectedUserProps {
  isConnected: boolean
  user?: User
  roles: Array<string>
}

export const defaultConnectedUserProps : ConnectedUserProps = {
  isConnected: false,
  roles: []
}

export interface ConnectedUserProviderProps {
  children: React.ReactNode | React.ReactNode[]
  onSignOut?: () => void
  waitElement?: React.ReactNode
}
