import React from "react";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {DashboardDrawerContent} from "./drawer/DashboardDrawerContent";
import {DashboardDrawer} from "./drawer/DashboardDrawer";
import {BodyContainer} from "@components/BodyContainer";
import {ProfusionAppBar} from "@components/layout/dashboard/appbar/ProfusionAppBar";
import {ScreenContainer} from "@components/ScreenContainer";


interface DashboardLayoutProps {
    children: React.ReactNode
    title?: string

}

const DashboardLayout = (props: DashboardLayoutProps) => {
    const {title, children} = props;
    const {t} = useTranslation()
    const user = useConnectedUser()

    if (!user) return <></>

    return <ScreenContainer>
        <ProfusionAppBar title={title ? title : t("prayer.app.title", "Prière") ?? ""}/>
        <DashboardDrawer>
            <DashboardDrawerContent/>
        </DashboardDrawer>

        <BodyContainer sx={{paddingBottom: 8, pt: 7}}>
            {children as any}
        </BodyContainer>
    </ScreenContainer>
}

export default DashboardLayout