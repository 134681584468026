import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const GET_CONNECTED_USER = gql`
    ${USER_FIELDS}
    query getConnectedUser {
        getConnectedUser {
            ...UserFields
        }
    }`;
