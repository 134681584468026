import {gql} from "@profusion.media/profusion-core";
import {COMMUNITY_MEMBER_FRAGMENT} from "@community/schema/fragments/community_member";

export const COMMUNITY_MEMBER_SET_ADMIN_MUTATION = gql`
    ${COMMUNITY_MEMBER_FRAGMENT}
    mutation communityMemberSetAdmin($input: CommunityMemberSetAdminInput!) {
        communityMemberSetAdmin(input: $input) {
            ...COMMUNITY_MEMBER_FRAGMENT
        }
    }`;

export const COMMUNITY_MEMBER_SET_ALLOW_PRAYER_MUTATION = gql`
    ${COMMUNITY_MEMBER_FRAGMENT}
    mutation communityMemberSetAllowPrayer($input: CommunityMemberSetAllowPrayerInput!) {
        communityMemberSetAllowPrayer(input: $input) {
            ...COMMUNITY_MEMBER_FRAGMENT
        }
    }`;

export const COMMUNITY_MEMBER_SET_TAG_MUTATION = gql`
    ${COMMUNITY_MEMBER_FRAGMENT}
    mutation communityMemberSetTag($input: CommunityMemberSetTagInput!) {
        communityMemberSetTag(input: $input) {
            ...COMMUNITY_MEMBER_FRAGMENT
        }
    }`;
