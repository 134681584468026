import {gql} from "@profusion.media/profusion-core";
import {NOTIFICATION_SETTINGS_FIELDS} from "../fragments";

export const NOTIFICATION_SETTINGS_UPDATE_MUTATION = gql`
    ${NOTIFICATION_SETTINGS_FIELDS}
    mutation notificationSettingsUpdate($input: NotificationSettingsInput!) {
        updateNotificationSettings(input: $input) {
            settings {
                ...NotificationSettingsFields
            }
            errors {
                field
                code
            }
        }
    }`;
