import {gql} from "@profusion.media/profusion-core";
import {ProfileIdentityInput} from "../types";
import {PROFILE_FIELDS} from "../fragments";


export type UpdateProfileIdentityInput = {
  input: ProfileIdentityInput
  hid: string
}


export const UPDATE_PROFILE = gql`
  ${PROFILE_FIELDS}
  mutation updateProfile($input: ProfileIdentityInput!, $hid: String!) {
    updateProfileIdentity(hid: $hid, input: $input) {
      profile {
        ...ProfileFields
      }
      errors {
        field
        code
      }
    }
  }`;
