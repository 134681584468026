import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Community, CommunityStats, Group, Organization} from "@graph";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {
    GET_COMMUNITY_CHILDREN,
    GET_COMMUNITY_DETAILS, GET_COMMUNITY_GROUPS, GET_COMMUNITY_ORGANIZATION,
    GET_COMMUNITY_STATS
} from "../schema/queries/get_community_details";

const _loadCommunityDetails = (client: ProfusionClient, hid: string): GraphQueryPromise<Community> => {
    return executeQuery<Community>(client, GET_COMMUNITY_DETAILS, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}
const _loadCommunityOrganization = (client: ProfusionClient, hid: string): GraphQueryPromise<Organization> => {
    return executeQuery<Organization>(client, GET_COMMUNITY_ORGANIZATION, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}

const _loadCommunityChildren = (client: ProfusionClient, hid: string): GraphQueryPromise<Community[]> => {
    return executeQuery<Community[]>(client, GET_COMMUNITY_CHILDREN, {
        isPrivate: true,
        variables: {
            parentHid: hid
        }
    })
}

const _loadCommunityGroups = (client: ProfusionClient, hid: string): GraphQueryPromise<Group[]> => {
    return executeQuery<Group[]>(client, GET_COMMUNITY_GROUPS, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}


const _loadCommunityStats = (client: ProfusionClient, hid: string): GraphQueryPromise<CommunityStats> => {
    return executeQuery<CommunityStats>(client, GET_COMMUNITY_STATS, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}

const loadData = (hid: string) => {
    return {
        community: _loadCommunityDetails(profusion, hid),
        //children: _loadCommunityChildren(profusion, hid),
        stats: _loadCommunityStats(profusion, hid),
        groups: _loadCommunityGroups(profusion, hid),
    }
}


export const loadCommunityDetails = (props: LoaderFunctionArgs<["community"]>) => {
    return defer(loadData(props.params.community ?? ""))
}

export const useCommunityDetailsLoader = (routeId?: string) => {
    if (!routeId) {
        return useLoaderData() as ReturnType<typeof loadData>
    }
    return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
}



const loadDataOrganization = (hid: string) => {
    return {
        organization: _loadCommunityOrganization(profusion, hid)
    }
}

export const loadCommunityOrganization = (props: LoaderFunctionArgs<["community"]>) => {
    return defer(loadDataOrganization(props.params.community ?? ""))
}