import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {ImageUploaderDialogProps, UploadedImage} from "./typings";
import ImageUploader from "./ImageUploader";
import React, {createRef, useState} from "react";
import {Close as CloseIcon, Edit as EditIcon} from '@mui/icons-material';


export const ImageUploaderDialog = (props: ImageUploaderDialogProps) => {
    const {open, onUploaded, onCancel, shape, filePrefix, image, folder, title} = props
    const [enableButton, setEnableButton] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const buttonRef = createRef<HTMLButtonElement>()
    const changeButtonRef = createRef<HTMLButtonElement>()


    const onUploadStart = () => {
        setUploading(true)
        setEnableButton(false)
    }

    const onImageReady = (ready: boolean) => {
        setEnableButton(ready)
    }

    const onUploadFinished = (fileInfo: UploadedImage) => {
        onUploaded(fileInfo)
        setEnableButton(false)
        setUploading(false)
    }

    return <Dialog open={open}
                   keepMounted={false}
                   sx={{zIndex:40004}}
    >
        <DialogTitle>
            {title}
            <IconButton
                sx={{position: 'absolute', right: 15, top: 8}}
                color="inherit" onClick={onCancel} aria-label="Fermer">
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <ImageUploader onUploaded={onUploadFinished}
                           onUploadStart={onUploadStart}
                           buttonRef={buttonRef}
                           changeButtonRef={changeButtonRef}
                           shape={shape}
                           folder={folder}
                           filePrefix={filePrefix}
                           onImageReady={onImageReady}
                           image={image}
                           width={300}
                           height={300}/>
        </DialogContent>
        <DialogActions>
            <Button
                ref={changeButtonRef}
                startIcon={<EditIcon/>}
                variant="outlined" color="primary">
                Changer
            </Button>


            <Button variant="contained" color="primary"
                    disabled={!enableButton}
                    startIcon={uploading ? <CircularProgress size={16}/> : <></>}
                    ref={buttonRef}>Upload</Button>

        </DialogActions>
    </Dialog>
}

