import React, {Suspense} from "react";
import {Box} from "@mui/material";
import {CommunityGroupList} from "@community/screens/groups/list/CommunityGroupList";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Await, useParams} from "react-router-dom";
import {Group} from "@graph";
import {WhiteBox} from "@components/WhiteBox";
import Typography from "@mui/material/Typography";
import {faPrayingHands, faRectangleHistoryCircleUser} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const CommunityGroupListTabs = () => {
    const {groups} = useCommunityDetailsLoader("communityDetailsAdmin")
    const {group_type} = useParams()

    return <Suspense>
        <Await resolve={groups}>
            {({data}) =>
                <Box>
                    <WhiteBox sx={{paddingX:3}}>
                        <Typography variant="h5" gap={2} display="flex" color="#454545">
                            {group_type === 'prayer'
                                ? <FontAwesomeIcon icon={faPrayingHands} fixedWidth/>
                                : <FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}
                            {group_type === 'prayer' ? "Prière" : "Groupes"}
                        </Typography>
                    </WhiteBox>

                    <CommunityGroupList groups={data.filter((g: Group) => g.mainFeature == group_type)}/>


                </Box>
            }
        </Await>
    </Suspense>
}