import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {CancelButton, Form, FormErrorBox, FullScreenDialog, SubmitButton} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {useAccountAddressUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import {address_to_input, AddressInput, EmptyAddress, Address} from "@profusion.media/profusion-core-geo";
import {AddressInput as AddressInputUI} from "@profusion.media/profusion-ui-address";

const FormUserAddress: React.FC = () => {
    const user = useConnectedUser()
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [fields, setFields] = useState<AddressInput>({...EmptyAddress})

    const [update,{loading: updating, validationErrors: errors}] = useAccountAddressUpdate()

    useEffect(() => {
        if (user) {
            setFields(address_to_input(user.address as Address))
        }
    }, [user])

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        const r = await update({input: fields})
        if (!r.validationErrors) {
            closeDialog()
        }
    };


    const handleCancel = () => {
        // Logique d'annulation ici
        setFields((user?.address ?? EmptyAddress) as Address);
        closeDialog()
    };


    return (
        <FullScreenDialog title="Lieu de résidence"  onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={updating}
                  autoClose>
                <FormErrorBox message="Des erreurs sont survenues"/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <AddressInputUI address={fields}
                                            autoComplete
                                            onChange={(address: AddressInput) => {
                                                setFields(address)
                                            }}/>
                        </Stack>

                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton label='Annuler'/>
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"
                            label="Enregistrer"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormUserAddress;
