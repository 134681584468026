import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {PrayerEventGroup} from "../schema";
import {PRAYER_EVENT_GROUP_LIST_QUERY} from "../schema/queries/prayer_event_group_list";

export const loadPrayerRequestEventGroups = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerEventGroup[]> => {
  return executeQuery<PrayerEventGroup[]>(client, PRAYER_EVENT_GROUP_LIST_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}
