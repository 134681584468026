import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const ACCOUNT_CONTACT_UPDATE = gql`
  ${USER_FIELDS}
  mutation($input: AccountContactInput!) {
    accountContactUpdate(input: $input) {
      user {
        ...UserFields
      }
      errors {
        field
        code
      }
    }
  }`;
