import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";


export const ORGANIZATION_AVATAR_UPDATE_MUTATION = gql`
    ${ORGANIZATION_FIELDS}
    mutation organizationAvatarUpdate($input: AttachAvatarInput!) {
        updateAvatarOrganization(input: $input) {
            __typename
            ...organizationFields
            ... on ValidationErrors {
                errors {
                    field
                    code
                    group
                }                
            }            
                        
        }
    }`;
