import React, {ChangeEvent, useContext, useEffect} from "react"
import {TextInputProps} from "./typings";
import {TextField} from "@mui/material";
import {useFormFieldError} from "../Form";
import {FormStepContext} from "../MultiPartForm";
import { useFormFieldContext} from "../FormValidation";

export const TextInput = (props: TextInputProps) => {
    const {onChange, onValueChange, ...allProps} = props
    const stepInfo = useContext(FormStepContext)
    const error = useFormFieldError(props.name)
    const context = useFormFieldContext()

    let newChangeHandler = onChange
    if (onValueChange) {
        newChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event.target.value
            onValueChange(props.name, value)
        }
    }

    useEffect(() => {
        updateField()
    }, []);

    useEffect(() => {
        updateField()
    }, [props.value]);

    const updateField = () => {
        context.onChange({
            isError: false,
            tags: [stepInfo.stepId],
            name: props.name,
            value: props.value,
            isValid: !props.required || !!props.value
        })
    }


    return <TextField
        fullWidth
        variant="outlined"
        error={error.isError}
        onChange={newChangeHandler}
        {...allProps} />
}

export default TextInput