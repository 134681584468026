import React, {createContext} from "react"
import {MultiPartFormStepProps} from "../typings";
import {useIsActiveStep} from "./MutliPartForm";
import {Box} from "@mui/material";


interface FormStepContextProps {
    step: number
    stepId: string
    isActive: boolean
}

export const FormStepContext = createContext<FormStepContextProps>({
    step: 0,
    stepId: "",
    isActive: false
})


export const MultiPartFormStep = (props: MultiPartFormStepProps) => {
    const isActive = useIsActiveStep(props.step)
    return <Box sx={{display: isActive ? "inherit" : "none"}}>
        <FormStepContext.Provider value={{
            step: props.step,
            stepId: props.id,
            isActive
        }}>
            {props.children}
        </FormStepContext.Provider>
    </Box>
}