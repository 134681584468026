import React from 'react';
import {Paper, Stack, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {styled} from '@mui/system';
import {useAccountAvatarUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import {ImageUploaderDialog, UploadedImage} from "@profusion.media/profusion-ui-image-uploader";
import {AvatarWithOverlay} from "./basic/AvatarChooser";
import Box from "@mui/material/Box";
import {WhiteBox} from "@components/WhiteBox";

const IconWrapper = styled(FontAwesomeIcon)`
    color: #00A8E6;
    margin-right: 1rem;
`;

const SecurityIconWrapper = styled(FontAwesomeIcon)`
    color: #3AC86E;
    margin-right: 1rem;
`;

const ContactIconWrapper = styled(FontAwesomeIcon)`
    color: #FF5C4D;
`;

const GradientPaper = styled(Paper)`
    display: flex;
    align-items: center;
    min-height: 10px;
    position: relative;
    //background: url('https://ik.imagekit.io/profusion/Static_files/Designer.jpeg?updatedAt=1709759297675&tr=w-1024,h-321,fo-custom,cm-extract');
    background: #1e7086;
    background-size: cover;
`;

const Header: React.FC = () => {

    const user = useConnectedUser()
    const [openAvatarDialog, setOpenAvatarDialog] = React.useState<boolean>(false)
    const [update, {loading: updating, validationErrors: errors}] = useAccountAvatarUpdate()
    const {t} = useTranslation()


    const handleUploaded = async (uploaded: UploadedImage) => {
        const r = await update({input: uploaded})
        if (!r.validationErrors) {
            closeDialog()
        }
    }

    function openDialog() {
        setOpenAvatarDialog(true)
    }

    function closeDialog() {
        setOpenAvatarDialog(false)
    }


    return <WhiteBox title="Ma photo de profil">
        <Stack direction="row" spacing={2}>
            <Typography>
                Aidez les autres membres de la communauté à vous reconnaître en ajoutant
                une photo de profil.
            </Typography>
            <Box onClick={openDialog} sx={{paddingX: 0}}>
                <AvatarWithOverlay
                    width={64}
                    height={64}
                    imageUrl={user?.profile?.avatar?.mediumUrl ?? ""}/>
            </Box>

        </Stack>


        <ImageUploaderDialog open={openAvatarDialog}
                             onUploaded={handleUploaded}
                             onCancel={closeDialog}
                             image={user?.profile?.avatar?.largeUrl ?? ""}
                             folder="users/avatars"
                             shape="rounded"
                             title={t("account.avatar.label", "Photo de profil") ?? ""}
                             filePrefix="ua-"
        />
    </WhiteBox>
};

export default Header;
