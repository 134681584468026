import { gql, DocumentNode } from '@profusion.media/profusion-core'

export const SUBSCRIBER_FIELDS : DocumentNode = gql`
  fragment SubscriberFields on Subscriber {
    hid
    dateCreated
    dateActivated
    isCreator
    isHost
    notificationSettingsHid
    user {
      displayName
      hid
      avatar {
        smallUrl
        mediumUrl
        largeUrl
      }
    }
    invitation {
      createdAt
      lastSentAt
      tryCount
      recipientEmail
      recipientPhoneNumber
      recipientFirstName
      recipientLastName
    }
  }`
