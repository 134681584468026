import {Button, Container, Paper, Stack} from "@mui/material";
import {faUsersMedical} from "@fortawesome/pro-duotone-svg-icons"
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import {useNavigationCreateGroup} from "../../Groups/hooks/navigation";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {HomeEmptyGroupsMineDiscover} from "@groups/HomeScreen/components/HomeEmptyGroupsMineDiscover";

export const HomeEmptyGroupsMine = () => {
    const navigateCreate = useNavigationCreateGroup()
    const {group_type} = useParams()

    if (group_type === 'share') {
        return <HomeEmptyGroupsMineDiscover/>
    }

    const handleAdd = () => {
        navigateCreate()
    }

    return <Paper sx={{padding: 3, pb:4}}>
        <Container>
            <Stack direction="column" spacing={4} alignItems="center">
                <DuoToneIcon
                    size="5x"
                    style={{
                        "--fa-primary-color": "#08348c",
                        "--fa-secondary-color": "#1186c5"
                    }}
                    icon={faUsersMedical}/>
                <Stack spacing={2}>
                    <Typography variant="h5">
                        Vous n'avez encore aucun groupe
                    </Typography>

                    <Typography variant="h6" color="#777">En quelques clics,
                        créez un premier groupe, invitez des
                        partenaires et partagez des requêtes
                        de prière.</Typography>
                </Stack>

                <Button variant="contained"
                        color="primary"
                        onClick={handleAdd}>Créer un groupe</Button>
            </Stack>
        </Container>
    </Paper>
}