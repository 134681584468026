import {gql} from "@profusion.media/profusion-core";
import {INVITATION_FIELDS} from "../fragments";

export const INVITATION_DETAILS_QUERY = gql`
  ${INVITATION_FIELDS}
  query getInvitationDetails ($hid: String!) {
    invitationDetails (hid: $hid) {
      ...InvitationFields
    }
  }`;
