import {Container, ContainerProps} from "@mui/material";

export const ScreenContainer = (props: ContainerProps) => {
    const {disableGutters, maxWidth,  ...rest} = props
    return <Container
        {...rest}
        maxWidth={false}
        disableGutters

    />
}
