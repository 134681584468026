import {DocumentNode, gql} from '@profusion.media/profusion-core'


export const ADDRESS_ALL_FIELDS: DocumentNode = gql`
  fragment AddressAllFields on Address {
    displayName
    postalCode
    city
    country
    province
    street
    coordinates
    visibility
  }
`;


export const ADDRESS_MINIMAL_FIELDS: DocumentNode = gql`
  fragment AddressAllFields on Address {
    postalCode
    city
    country
    province
  }
`;
