import profusion from "@config/profusion"
import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";

import {
    MutationCreateOrganizationArgs,
    MutationUpdateOrganizationArgs,
    Organization,
    OrganizationCreate,
    OrganizationUpdate,
    OrganizationUpdateInput
} from "@profusion.media/graph";
import {ORGANIZATION_CREATE_MUTATION} from "@organizations/schema/mutations/organization_create_mutation";
import {ORGANIZATION_UPDATE_MUTATION} from "@organizations/schema/mutations/organization_update_mutation";
import {GET_COMMUNITY_DETAILS} from "@community/schema/queries/get_community_details";

export type onOrganizationEditSuccessHandler = (organization: Organization) => void;

export const organizationCreate = (onSuccess: onOrganizationEditSuccessHandler) => async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationCreateOrganizationArgs

    console.log(data, "SUBMITTED")

    const response = await executeMutation<OrganizationCreate, MutationCreateOrganizationArgs>(
        profusion,
        ORGANIZATION_CREATE_MUTATION,
        data, {
            isPrivate: true,
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return onSuccess(response?.data?.organization as Organization)
}

export const organizationUpdate = (onSuccess: onOrganizationEditSuccessHandler) => async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationUpdateOrganizationArgs
    console.log("SEND UPDATE", data)
    const response = await executeMutation<OrganizationUpdate, MutationUpdateOrganizationArgs>(
        profusion,
        ORGANIZATION_UPDATE_MUTATION,
        {
            organization: data.organization,
            removeAvatar: data.removeAvatar,
            avatar: data.avatar,
        },
        {
            isPrivate: true,
            refetchQueries: (response, vars) => (
                [{
                    query: GET_COMMUNITY_DETAILS,
                    variables: {
                        hid: response.organization.mainCommunity.hid
                    },
                    context: {
                        use_private: true
                    }
                }])
        })
    if (response.validationErrors) {
        console.error(response.validationErrors)
        return response.validationErrors
    }
    return onSuccess(response?.data?.organization as Organization)
}