import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {OrganizationListChurches} from "./OrganizationListChurches";
import {Organization} from "@graph";
import {OrganizationListDenominations} from "./OrganizationListDenominations";
import {OrganizationListPartners} from "./OrganizationListPartners";
import {WhiteBox} from "@components/WhiteBox";
import {ListItem, ListItemAvatar} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";

interface OrganizationListTabsProps {
    organizations: Organization[]
    onSelect: (organization: Organization) => void;
}

export const OrganizationListTabs = (props: OrganizationListTabsProps) => {
    const {organizations, onSelect} = props
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <WhiteBox sx={{width: '100%', typography: 'body1'}}>
            {organizations.map((organization: Organization) => ( <ListItem key={organization.hid}
                                                                           onClick={() => onSelect(organization)}>
                <ListItemAvatar>
                    <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
                </ListItemAvatar>
                <ListItemText primary={organization.name}/>
            </ListItem>))}

        </WhiteBox>
    )
}