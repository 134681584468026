import {DashboardApp} from "./DashboardApp";
import {groupListRouter} from "./Groups/groupListRouter";
import {prayerGlobalRouter} from "./Prayers/prayerRouter";
import {loadDetails} from "./Groups/GroupDetails/loaders";
import {GroupScreen} from "./Groups/components/groupScreen/GroupScreen";
import {groupSingleRouter} from "./Groups/groupSingleRouter";
import {GroupAccessChecker} from "./Groups/GroupAccessChecker";
import {actionGroupJoin} from "@groups/Groups/GroupJoin/action_join";


const GroupsRouter = [

    {
        path: "",
        id: "groups",
        element: <DashboardApp/>,
        children: [
            ...groupListRouter,
            ...prayerGlobalRouter,
            {
                path: ":group_type/:hid",
                loader: loadDetails,
                id: "groupDetails",
                element: <GroupAccessChecker/>,
                children: [
                    {
                        path: "",
                        element: <GroupScreen/>,
                        children: [
                            ...groupSingleRouter
                        ]
                    },
                    {
                        path: "join",
                        id: "groupJoinAction",
                        action: actionGroupJoin,
                    }
                ]
            },
        ]
    }
]

export default GroupsRouter