import {Container, Paper, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import {faThumbTack} from "@fortawesome/pro-duotone-svg-icons";

export const PinnedRequestListEmpty = () => {

    return <Paper sx={{padding: 3, pb:4}}>
        <Container>
            <Stack direction="column" spacing={4} alignItems="center">
                <DuoToneIcon
                    size="5x"
                    style={{
                        "--fa-primary-color": "#08348c",
                        "--fa-secondary-color": "#1186c5"
                    }}
                    icon={faThumbTack}/>
                <Stack spacing={2}>
                    <Typography variant="h5">
                        Regroupez vos requêtes courantes au même endroit
                    </Typography>
                    <Typography variant="h6" color="#777">

                        Dans cette page, il vous est possible d’afficher les requêtes pour lesquelles vous êtes
                        actuellement
                        en train de prier. Lorsque vous avez terminé de prier pour une requête, il vous suffit de
                        retirer l’épingle et
                        la requête sortira de votre liste de prières courantes.
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    </Paper>
}
