import {Group, GROUP_DETAILS} from "../schema";
import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";

export const loadGroupDetails = (client: ProfusionClient, groupHid: string): GraphQueryPromise<Group> => {
  return executeQuery<Group>(client, GROUP_DETAILS, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}
