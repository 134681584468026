import {Divider, List, ListItem, ListItemIcon, ListItemText, styled} from "@mui/material";
import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff, faShieldCheck, faUserEdit} from "@fortawesome/pro-solid-svg-icons"
import {useAuthentication} from "@profusion.media/profusion-core";
import {useNavigate} from "react-router-dom";

const StyledMenu = styled(List)(({theme}) => ({
    backgroundColor: '#ffffff', // Utilisation du paramètre backgroundColor ou une valeur par défaut
    padding: 10,
    borderRadius: 20,
    color: "#454545"
}));

export const AccountMenu = () => {
    const navigate = (url: string) => {
        window.document.location.href = url
    }

    return <StyledMenu>
        <ListItem onClick={_ => navigate("/account/personal-info")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faUserEdit}/>
            </ListItemIcon>
            <ListItemText primary={"Gérer mon profil"}/>
        </ListItem>
        <ListItem onClick={_ => navigate("/account/personal-info")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faShieldCheck}/>
            </ListItemIcon>
            <ListItemText primary={"Sécurité et mot de passe"}/>
        </ListItem>
        <Divider/>

        <ListItem onClick={_ => navigate("/logout")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faPowerOff}/>
            </ListItemIcon>
            <ListItemText primary={"Déconnexion"}/>
        </ListItem>
    </StyledMenu>
}