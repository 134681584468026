import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Content} from "@graph";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {GET_COMMUNITY_AVAILABLE_CONTENT} from "@community/schema/queries/get_community_content";

const _loadCommunityAvailableContent = (client: ProfusionClient): GraphQueryPromise<Content[]> => {
    return executeQuery<Content[]>(client, GET_COMMUNITY_AVAILABLE_CONTENT, {
        isPrivate: true,
    })
}


const loadData = () => {
    return {
        content: _loadCommunityAvailableContent(profusion)
    }
}


export const loadCommunityAvailableContent = (props: LoaderFunctionArgs<["community"]>) => {
    return defer(loadData())
}


export const useCommunityAvailableContentLoader = (routeId?: string) => {
    if (!routeId) {
        return useLoaderData() as ReturnType<typeof loadData>
    }
    return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
}
