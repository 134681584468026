import { gql } from "@apollo/client";

export const DEVICE_UPDATE_MUTATION = gql`
  mutation deviceUpdate($input: DeviceInput!) {
    deviceUpdate(input: $input) {
      device {
        hid
        systemId
        isTrusted
        name
        platform
        userAgent
      }
      errors {
        field
        code
      }
    }
  }`;
