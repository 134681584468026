import {gql} from "@profusion.media/profusion-core";
import {PROFILE_FIELDS} from "@profusion.media/profusion-core-profile";

export const PRAYER_REQUEST_OVERVIEW_QUERY = gql`
    ${PROFILE_FIELDS}
    query PrayerRequestOverview ($request: String!) {
        prayerRequestOverview(prayerRequest: $request) {
          requestHid
            commentCount
            prayerCount
            prayerAvatars {
                ...ProfileFields
            }
        }
    }`;

export const ALL_PRAYER_REQUEST_OVERVIEW_QUERY = gql`
    ${PROFILE_FIELDS}
    query AllPrayerRequestOverview ($groupHid: String!) {
        allPrayerRequestOverview(groupHid: $groupHid) {
            requestHid
            commentCount
            prayerCount
            prayerAvatars {
                ...ProfileFields
            }
        }
    }`;
