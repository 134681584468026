import {CommunityMember, CommunityTag, Group} from "@graph";
import {WhiteBox} from "@components/WhiteBox";
import React, {Suspense, useState} from "react";
import {FullScreenDialog, UserAvatar} from "@profusion.media/profusion-ui";
import {Await, useNavigate, useRevalidator} from "react-router-dom";
import {useGroupDetailsLoader} from "@groups/Groups/GroupDetails/loaders";
import {useCommunityGroupMembersAllowedLoader} from "@community/loaders/communityGroupMembersAllowedLoader";
import List from "@mui/material/List";
import {Button, Checkbox, Drawer, ListItem, ListItemAvatar, ListItemSecondaryAction, Stack} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {TagChipGroup} from "@community/components/TagChip";
import Typography from "@mui/material/Typography";
import {useCommunityGroupMembers} from "@community/actions/member/community_group_members";
import {LoadingButton} from "@mui/lab";


interface GroupAddMembersSelectorProps {
    group: Group
    members: CommunityMember[]
}


export const GroupAddMembersSelector = (props: GroupAddMembersSelectorProps) => {
    const {group, members} = props
    const navigate = useNavigate()
    const [selectedMembers, setSelectedMembers] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const {addMembers} = useCommunityGroupMembers(group.hid)
    const {revalidate} = useRevalidator()

    const onClose = () => {
        navigate("../subscribers")
    }

    const onSubmit = () => {
        setLoading(true)
        addMembers(selectedMembers).then(_ => {
            revalidate()
            setLoading(false)
            onClose()
        })
    }

    const memberName = (m: CommunityMember) => {
        return m.user?.firstName + " " + m.user?.lastName
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, member: CommunityMember) => {
        const checked = event.target.checked
        if (checked) {
            setSelectedMembers(prev => [...prev, member.hid])
        } else {
            setSelectedMembers(prev => prev.filter(t => t !== member.hid))
        }
    };

    const isChecked = (member: CommunityMember) => {
        return !!selectedMembers.find(t => t === member.hid)
    }

    return <FullScreenDialog title="Ajouter des membres"
                             subtitle={group.title}
                             onClose={onClose}>
        <WhiteBox title="Ajouter des membres">
            <List>
                {members.map((member: CommunityMember) => <ListItem key={member.hid}>
                    <ListItemAvatar>
                        <UserAvatar userName={memberName(member)}
                                    avatar={member.user?.avatar?.mediumUrl}
                        />
                    </ListItemAvatar>
                    <ListItemText primary={memberName(member)}
                                  secondary={member.tags && <TagChipGroup tags={member.tags as CommunityTag[]}/>}
                    />
                    <ListItemSecondaryAction>
                        <Checkbox
                            checked={isChecked(member)}
                            onChange={e => handleChange(e, member)}/>
                    </ListItemSecondaryAction>
                </ListItem>)}
            </List>
        </WhiteBox>
        <Drawer
            anchor="bottom"
            open={selectedMembers.length > 0}
            sx={{zIndex: 1500}}
            hideBackdrop
            variant={selectedMembers.length > 0 ? "persistent" : "temporary"}
        >
            <Stack p={1}
                   justifyContent="space-between"
                   direction="row" alignItems="center" spacing={2}>
                <Typography>
                    {selectedMembers.length > 1 ? selectedMembers.length + " membres sélectionnés" : "1 membre sélectionné"}
                </Typography>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={onSubmit}
                >OK</LoadingButton>
            </Stack>
        </Drawer>
    </FullScreenDialog>
}

export const GroupAddMembers = () => {
    const {group: groupData} = useGroupDetailsLoader("groupDetails")
    const {members: membersData} = useCommunityGroupMembersAllowedLoader()
    return <Suspense>
        <Await resolve={Promise.all([groupData, membersData])}>
            {([{data: group}, {data: members}]) => <GroupAddMembersSelector group={group} members={members}/>}
        </Await>
    </Suspense>
}