import {Community, CommunityMember, CommunityTag} from "@graph";
import {ListItem, ListItemSecondaryAction, Stack} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import React, {useEffect, useState} from "react";
import {MemberTagSelector} from "@community/screens/members/drawer/MemberTagSelector";
import {makeTagList} from "@community/utils/create_tree";
import {TagChipGroup} from "@community/components/TagChip";

export interface MemberInfoTagProps {
    member: CommunityMember
    allowedTags: CommunityTag[]
}

export const MemberInfoTag = (props: MemberInfoTagProps) => {
    const {member, allowedTags} = props
    const [openTagSelector, setOpenTagSelector] = useState<boolean>(false)



    const handleOpenSelector = () => {
        setOpenTagSelector(true)
    }

    const handleCloseSelector = () => {
        setOpenTagSelector(false)
    }

    return <ListItem>
        <MemberTagSelector member={member}
                           allowedTags={allowedTags}
                           open={openTagSelector}
                           onClose={handleCloseSelector}/>
        <ListItemText primary="Catégorie"/>
        <ListItemSecondaryAction onClick={handleOpenSelector}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <TagChipGroup tags={member.tags as CommunityTag[]} showEmpty />
                <FontAwesomeIcon icon={faChevronRight}/>
            </Stack>
        </ListItemSecondaryAction>
    </ListItem>
}