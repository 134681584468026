import {AvatarCreateInput, MutationUpdateOrganizationArgs, Organization} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "@organizations/screens/edit/OrganizationForm";
import {Await, useActionData, useLocation, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";
import {useState} from "react";

export const OrganizationUpdate = () => {
    const submit = useSubmit()
    const {organization} = useOrganizationDetailsLoader()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const location = useLocation()
    const navigate = useNavigate()
    const loading = navigation.state === "loading"
    const [avatar, setAvatar] = useState<AvatarCreateInput|undefined>(undefined)
    const [removeAvatar, setRemoveAvatar] = useState<boolean>(false)

    const handleSubmit = async (organization: Partial<Organization>) => {
        const data : MutationUpdateOrganizationArgs = {
            organization: {
                hid: organization.hid,
                name: organization.name,
                shortName: organization.shortName,
                leaderLastName: organization.leaderLastName,
                leaderFirstName: organization.leaderFirstName,
                email: organization.email,
                phoneNumber: organization.phoneNumber,
                website: organization.website,
                city: organization.city,
            },
            removeAvatar,
            avatar
        }
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(location.state?.hasOwnProperty("from") ? location.state.from : "..")

    }

    const getAvatarUrl = (org: Organization) => {
        return removeAvatar
            ? undefined
            : avatar?.url ?? org.profile?.avatar?.smallUrl
    }

    return <Await resolve={organization}>
        {({data}) =>
            <FullScreenDialog title={`Mettre à jour ${data.name}`} onClose={handleCancel}>
                <OrganizationForm
                    organization={data}
                    avatarUrl={getAvatarUrl(data)}
                    onAvatarDelete={() => {
                        setAvatar(undefined)
                        setRemoveAvatar(true)
                    }}
                    onAvatarChange={a => {
                        setAvatar(a)
                        setRemoveAvatar(false)
                    }}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    loading={loading}
                    errors={validationErrors}
                />
            </FullScreenDialog>}
    </Await>
}