import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_SET_CONTENT_MUTATION = gql`
    ${GROUP_FIELDS}
    mutation groupSetContent($input: GroupSetContentInput!) {
        groupSetContent(input: $input) {
            ...GroupFields
        }
    }`;
