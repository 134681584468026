import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Box, IconButton, Stack, Toolbar, useTheme} from "@mui/material";
import {useDrawerMenu, UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";

import {DrawerUserActions} from "@components/layout/dashboard/drawer/DrawerUserActions";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons";

export const DashboardDrawerHeader = () => {
    const user = useConnectedUser()
    const theme = useTheme()
    const {drawerOpen, toggleDrawer, setDrawerOpen} = useDrawerMenu()

    if (!drawerOpen) {
        return <Toolbar></Toolbar>
    }

    return <Box className="drawerHeaderRoot" sx={{
        position: "relative",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt:2,
        pb: 2,
        backgroundColor: theme.palette.primary.main,
    }}>


        <Box sx={{pt: 2}}>
            {user && <Stack spacing={1} alignItems="center">
                <UserAvatar userName={user.profile?.displayName}
                            avatar={user.profile?.avatar?.mediumUrl}
                            size={96} addBorder={true}/>
                <Typography color="#fafafa" variant="h6" component="div">
                    {user.firstName} {user.lastName}
                </Typography>
                {user.communityMembership.length ? <Stack direction="column" gap={0}>
                    {user.communityMembership.map(membership =>
                        <Typography color="#e5e5e5" variant="body1">{membership.communityName}</Typography>)}
                </Stack> : <></>}
            </Stack>
            }

        </Box>
        <DrawerUserActions/>
    </Box>
}