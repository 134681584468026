import {gql} from "@profusion.media/profusion-core";
import {PRAYER_EVENT_FIELDS} from "../fragments";

export const PRAYER_COMMENT_CREATE_MUTATION = gql`
  ${PRAYER_EVENT_FIELDS}
  mutation prayerCommentCreate($input: PrayerCommentCreateInput!) {
    prayerCommentCreate(input: $input) {
      prayerEvent {
        ...PrayerEventFields
      }
      errors {
        field
        code
      }
    }
  }`;
