import {useTranslation} from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControlLabel,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useState} from "react";
import {FullScreenDialog, HeadsUp, TextInput} from "@profusion.media/profusion-ui";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons"

export const DeleteAccount = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [state, setState] = useState({
        email: "",
        name: "",
        comment: ''
    });
    const [perms, setPerms] = useState({
        checked: false,
        contactPermissionChecked: false,
    })
    const [expanded, setExpanded] = useState<string | false>(false);
    const userInfo = useConnectedUser()

    const handleDataChange = (name: string, value: string) => {
        setState(prev => ({
            ...prev, [name]: value,
        }))
    };


    const handleConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPerms({
            ...perms,
            checked: event.target.checked,
        })
    };

    const handleAccordionChange = (panel: string) => (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (userInfo) {
            setState(prev => ({
                ...prev,
                name: userInfo.firstName + " " + userInfo.lastName,
                email: userInfo.email
            }))
        }
    }, [userInfo]);

    const closeDialog = () => {
        navigate(-1);
    }

    return <FullScreenDialog title="Suppression de Compte" onClose={closeDialog}>
        <Container component="main" maxWidth="md">
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={3}>

                        <Typography gutterBottom variant="h5" component="div">
                            {t('account.delete.description', 'Êtes-vous sûr de vouloir supprimer définitivement votre compte?')}
                        </Typography>
                        <HeadsUp backgroundColor="#FFE5B4" sx={{padding: 4}}>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
                            <Typography>{t('account.delete.headsup.message', 'Cette action est irréversible. Vos groupes et vos interactions avec la communauté seront supprimés.')}</Typography>
                        </HeadsUp>
                    </Stack>
                </CardContent>
                <CardContent>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <AddIcon/>
                            <Typography>{t('account.delete.addComment', 'Ajouter un commentaire')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <TextInput
                                    name="comment"
                                    label={t('account.delete.commentLabel', 'Commentaire')}
                                    placeholder={t('account.delete.commentPlaceholder', 'Si vous le souhaitez, vous pouvez nous laisser un commentaire ici.') as string}
                                    value={state.comment}
                                    onValueChange={handleDataChange}

                                />
                                {perms.contactPermissionChecked && (
                                    <>
                                        <TextInput
                                            name="email"
                                            label={t('account.delete.emailLabel', 'Email')}
                                            placeholder={t('account.delete.emailPlaceholder', 'Entrez votre email ici') as string}
                                            value={state.email}
                                            onValueChange={handleDataChange}
                                        />
                                        <TextInput
                                            name="name"
                                            label={t('account.delete.nameLabel', 'Nom')}
                                            placeholder={t('account.delete.namePlaceholder', 'Entrez votre nom ici') as string}
                                            value={state.name}
                                            onValueChange={handleDataChange}
                                        />
                                    </>
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>

                </CardContent>
                <CardContent>
                    <FormControlLabel
                        control={<Checkbox checked={perms.checked} onChange={handleConfirmChange}/>}
                        label={t('account.delete.checkbox', 'Je comprends et je confirme la suppression de mon compte')}
                    />
                </CardContent>
                <CardActions>

                    <Button variant="contained" color={"secondary"} type="submit" disabled={!perms.checked}>
                        {t('account.delete.button', 'Supprimer le Compte')}
                    </Button>
                </CardActions>
            </Card>
        </Container>
    </FullScreenDialog>
};