import React, {ReactNode} from "react";
import {Breakpoint, ContainerProps, DialogProps} from "@mui/material";

export interface FullScreenDialogSlotProps {
    container?: Partial<ContainerProps>
    dialog?: Partial<DialogProps>
}

export interface FullScreenDialogProps {
    title: ReactNode
    subtitle?: ReactNode
    maxWidth?: Breakpoint
    children: React.ReactNode
    onClose?: () => void
    showHelp?: boolean
    helpContent?: ReactNode
    slotProps?: FullScreenDialogSlotProps
    bodyContainer?: (props: ContainerProps) => ReactNode
}

export const fullScreenDialogPropsDefaults : Partial<FullScreenDialogProps> = {
    showHelp: false,
    maxWidth: "md"
}

export interface FullScreenDialogHeaderProps {
    title?: ReactNode
    subtitle?: ReactNode
    maxWidth?: Breakpoint
    showHelp?: boolean
    helpContent?: ReactNode
    onClose: () => void
}

export const fullScreenDialogHeaderPropsDefaults : Partial<FullScreenDialogHeaderProps> = {
    showHelp: false,
    maxWidth: "md"
}
