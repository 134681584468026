import {Box, useTheme} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQrcode, faSignOutAlt, faUserGear} from "@fortawesome/pro-light-svg-icons";
import Typography from "@mui/material/Typography";
import {useDrawerMenu} from "@profusion.media/profusion-ui";
import {useNavigate} from "react-router-dom";
import {scanQrCode} from "@components/QrScanner";


interface UserActionBoxProps {
    children?: React.ReactNode;
    onClick?: () => void;
}

const UserActionBox = (props: UserActionBoxProps) => {
    const theme = useTheme()

    return <Box
        onClick={props.onClick}
        sx={{
            padding: 1,
            display: "flex",
            gap: 1,
            flexGrow: 1,

        }}>{props.children}</Box>
}

export const DrawerUserActions = () => {
    const theme = useTheme()
    const {toggleDrawer} = useDrawerMenu()
    const navigate = useNavigate()

    const handleGoAccount = () => {
        toggleDrawer()
        navigate("/account/personal-info/")
    }


    const handleOnScan = () => {
        toggleDrawer()
        scanQrCode().then(internal => navigate(internal))
    }
    return <Box display="flex"
                flexDirection="row"
                marginTop={1}
                gap={2}

                alignItems="center"
                justifyContent="space-between"
                sx={{

                    backgroundColor: "#fafafa",
                    paddingX: 1,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: theme.palette.divider,
                    borderRadius: 4
                }}
    >

        <UserActionBox onClick={handleOnScan}>
            <FontAwesomeIcon icon={faQrcode}/>
            <Typography variant="caption">
                Code QR
            </Typography>
        </UserActionBox>
        <UserActionBox onClick={handleGoAccount}>
            <FontAwesomeIcon icon={faUserGear}/>
            <Typography variant="caption">
                Compte
            </Typography>
        </UserActionBox>
    </Box>
}