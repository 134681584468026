import {redirect, RouteObject} from "react-router-dom";
import {loadOrganizationList} from "./organization/loaders/organizationListLoader";
import {OrganizationList} from "./organization/screens/OrganizationList/OrganizationList";
import {SuperAdminHome} from "./layout/SuperAdminHome";
import {OrganizationCreate} from "./organization/screens/OrganiztionForm/OrganizationCreate";
import {actionOrganizationCreate, actionOrganizationUpdate} from "./organization/actions/organizationEdit";
import {OrganizationDetails} from "./organization/screens/OrganizationDetails/OrganizationDetails";
import {OrganizationUpdate} from "./organization/screens/OrganiztionForm/OrganizationUpdate";
import {actionOrganizationDelete} from "./organization/actions/organizationDelete";
import {OrganizationDeleteDialog} from "./organization/screens/OrganizationDetails/OrganizationDeleteDialog";
import {OrganizationCommunity} from "./organization/screens/OrganizationCommunity/details/OrganizationCommunity";
import {loadCommunityDetails} from "@community/loaders/communityDetailsLoader";
import {
    OrganizationCommunityInvitation
} from "./organization/screens/OrganizationCommunity/invitation/OrganizationCommunityInvitation";
import {loadOrganizationDetails} from "@organizations/loaders/organizationDetailsLoader";
import {OrganizationCommunityQR} from "./organization/screens/OrganizationCommunity/invitation/OrganizationCommunityQR";
import {actionOrganizationAvatarUpdate} from "./organization/actions/organizationAvatarUpdate";
import {actionCommunityInvite} from "@community/actions/community_invite";
import {CommunityMembersList} from "./organization/screens/OrganizationCommunity/details/CommunityMembersList";
import {loadCommunityMembers} from "@community/loaders/communityMembersLoader";


export const SuperadminRouter: RouteObject[] = [
    {
        path: "",
        element: <SuperAdminHome/>,
        children: [
            {
                path: ":org_type",
                loader: ({params}) => redirect(`/su/${params.org_type}}/1/1000`)
            },
            {
                path: ":org_type/:page/:nb",
                loader: loadOrganizationList,
                element: <OrganizationList/>
            },
            {
                path: ":org_type/add",
                element: <OrganizationCreate/>,
                action: actionOrganizationCreate
            },
            {
                path: "organizations/:hid",
                loader: loadOrganizationDetails,
                element: <OrganizationDetails/>,
                id: "organizationDetailsSU",
                children: [
                    {
                        path: "edit",
                        element: <OrganizationUpdate/>,
                        action: actionOrganizationUpdate()
                    },
                    {
                        path: "avatar",
                        action: actionOrganizationAvatarUpdate
                    },
                    {
                        path: "delete",
                        element: <OrganizationDeleteDialog/>,
                        action: actionOrganizationDelete
                    },
                    {
                        path: "community/:community",
                        id: "orgCommunityDetails",
                        loader: loadCommunityDetails,
                        element: <OrganizationCommunity/>,
                        children: [
                            {
                                id: "suCommunityMembersList",
                                path: "",
                                element: <CommunityMembersList/>,
                                loader: loadCommunityMembers,
                            },
                            {
                                path: "invite",
                                element: <OrganizationCommunityInvitation/>,
                                action: actionCommunityInvite
                            },
                            {
                                path: "qr",
                                element: <OrganizationCommunityQR/>
                            }
                        ]
                    },
                ]
            }
        ]
    }
]