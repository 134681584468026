import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {
  PRAYER_PIN_CREATE_MUTATION,
  PRAYER_PINNED_LIST_QUERY,
  PRAYER_REQUEST_LIST_QUERY,
  PrayerRequest
} from "../schema";

export const loadPrayerRequestList = (client: ProfusionClient, groupHid: string): GraphQueryPromise<PrayerRequest[]> => {
  return executeQuery<PrayerRequest[]>(client, PRAYER_REQUEST_LIST_QUERY, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}

export const loadPinnedRequestList = (client: ProfusionClient): GraphQueryPromise<PrayerRequest[]> => {
  return executeQuery<PrayerRequest[]>(client, PRAYER_PINNED_LIST_QUERY, {
    isPrivate: true,
  })
}
