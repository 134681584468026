import {useDrawerMenu, UserAvatar} from "@profusion.media/profusion-ui"
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Box, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";


export const AppBarDrawerIcon = () => {
    const {drawerOpen: open, toggleDrawer: onToggle, setDrawerOpen} = useDrawerMenu()
    const useAvatar = false

    const user = useConnectedUser()


    if (!useAvatar) {
        return <Box
            sx={{
                marginRight: '24px',
                ...(open && {display: 'none'}),
            }}><IconButton

            onClick={onToggle}>
            <FontAwesomeIcon color="#fff" icon={faBars}/>
        </IconButton>
        </Box>
    } else {
        return <Box
            onClick={onToggle}
            sx={{
                borderRadius: '50%',
                borderStyle: "solid",
                borderColor: "#fff",
                marginRight: '36px',
                ...(open && {display: 'none'}),
            }}><UserAvatar userName={user?.profile?.displayName}
                           avatar={user?.profile?.avatar?.smallUrl}/>
        </Box>
    }
}