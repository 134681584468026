import {executeMutation} from "@profusion.media/profusion-core";
import {
    CommunityMember,
    CommunityMemberSetTagInput,
    Group,
    MutationCommunityMemberSetTagArgs,
    MutationGroupSetContentArgs
} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_MEMBER_SET_TAG_MUTATION} from "@community/schema/mutations/community_member";
import {
    GROUP_SET_CONTENT_MUTATION
} from "@profusion.media/profusion-core-group/groups/schema/mutations/group_set_content_mutation";


export const useGroupSetContent = (groupHid: string) => {
    return {
        addContent: (contentHid: string) => {
            return executeMutation<Group, MutationGroupSetContentArgs>(
                profusion,
                GROUP_SET_CONTENT_MUTATION,
                {
                    input: {
                        isRemoved: false,
                        groupHid,
                        contentHid
                    }
                },
                {
                    isPrivate: true
                })
        },

        removeContent: (contentHid: string) => {
            return executeMutation<Group, MutationGroupSetContentArgs>(
                profusion,
                GROUP_SET_CONTENT_MUTATION,
                {
                    input: {
                        isRemoved: true,
                        contentHid,
                        groupHid
                    }
                },
                {
                    isPrivate: true
                })
        }
    }
}