import {gql} from "@apollo/client";

export const COMMUNITY_FRAGMENT = gql`
    fragment COMMUNITY_FRAGMENT on Community {
        hid
        isPrivate
        isSystem
        shortDescription
        description
        language
        name
        handle
        color
        childrenCount
        memberStatus
        isMain
        organization {
            hid
            type
            name
            shortName
            prayerGroupHid
            avatar {
                largeUrl
                mediumUrl
                smallUrl
            }
        }
        tags {
            tagOrder
            name
            color
            hid
        }
    }`