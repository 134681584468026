import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const ACCOUNT_LANGUAGE_UPDATE = gql`
  ${USER_FIELDS}
  mutation($input: AccountLanguageInput!) {
    accountLanguageUpdate(input: $input) {
      user {
        ...UserFields
      }
      errors {
        field
        code
      }
    }
  }`;
