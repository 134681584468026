import {Form, PhoneInput, SubmitButton, TextInput} from "@profusion.media/profusion-ui";
import {Avatar, AvatarCreateInput, Organization} from "@graph";
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {AddressInput} from "@profusion.media/profusion-ui-address";
import {AddressInput as AddressInputType} from "@profusion.media/profusion-core-geo";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {WhiteBox} from "@components/WhiteBox";
import {AvatarEditor} from "@profusion.media/profusion-ui-image-uploader";


export interface OrganizationFormProps<T extends Partial<Organization>> {
    organization: T
    onSubmit: (organization: Partial<T>) => void
    onCancel: () => void
    errors?: ValidationErrors
    loading?: boolean
    type?: string
    variant?: "simple" | "full"
    avatarUrl?: string
    onAvatarChange?: (avatar: AvatarCreateInput) => void
    onAvatarDelete?: () => void
}

export function OrganizationForm<T extends Partial<Organization>>(props: OrganizationFormProps<T>) {
    const {
        organization: organizationOrigin,
        avatarUrl,
        onAvatarChange,
        onAvatarDelete,
        type: organizationType,
        onCancel,
        onSubmit,
        errors,
        loading,
        variant
    } = props;
    const [organization, setOrganization] = useState<Partial<T>>(organizationOrigin);

    const handleChange = (name: string, value: any) => {
        setOrganization(prevOrganization => ({
            ...prevOrganization,
            [name]: value
        }));
    };

    useEffect(() => {
        handleChange("type", organizationType);
    }, [organizationType])

    const showAddress = false; //organizationType === "church"
    const full = !variant || variant === "full"

    console.log("org", organization);

    const handleSubmit = () => {
        onSubmit(organization);
    };


    return <Form onSubmit={handleSubmit}
                 onCancel={onCancel}
                 errors={errors}
                 loading={loading}
    >
        <Stack spacing={1} marginBottom={4}>
            <WhiteBox title="Identité">
                <Stack direction="column" spacing={2}>
                    <TextInput onValueChange={handleChange}
                               value={organization.name}
                               name="name"
                               label="Nom"
                    />
                    <TextInput onValueChange={handleChange}
                               value={organization.city}
                               name="city"
                               label="Ville"
                    />

                    {full && <TextInput onValueChange={handleChange}
                                        type="url"
                                        value={organization.website}
                                        name="website"
                                        label="Site web"
                    />}
                </Stack>
            </WhiteBox>
            <WhiteBox>
                <AvatarEditor
                    description={"Ajoutez une image pour aider les participants\n" +
                        "à reconnaître votre église"}
                    image={avatarUrl}
                    onDelete={() => onAvatarDelete()}
                    onChange={onAvatarChange}/>

            </WhiteBox>
            {showAddress &&
                <WhiteBox title="Adresse">
                    <AddressInput address={organization.address as AddressInputType}
                                  name="address"
                                  autoComplete
                                  onChange={(address: AddressInputType) => handleChange("address", address)}/>

                </WhiteBox>}
            {full && <WhiteBox title="Personne ressource">
                <Stack direction="row" spacing={2} mb={2}>
                    <TextInput onValueChange={handleChange}
                               value={organization.leaderFirstName}
                               name="leaderFirstName"
                               label="Prénom"
                    />
                    <TextInput onValueChange={handleChange}
                               value={organization.leaderLastName}
                               name="leaderLastName"
                               label="Nom"
                    />
                </Stack>
                <Stack direction="column" spacing={2}>

                    <TextInput onValueChange={handleChange}
                               type="email"
                               value={organization.email}
                               name="email"
                               label="E-mail de contact"
                    />
                    <PhoneInput value={organization.phoneNumber ?? ""}
                                onValueChange={handleChange}
                                name="phoneNumber"
                                label="Numéro de téléphone"
                    />

                </Stack>
            </WhiteBox>}
            <SubmitButton/>
        </Stack>
    </Form>
}