import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {
  PRAYER_REQUEST_LIST_QUERY,
  PRAYER_REQUEST_UPDATE_MUTATION,
  PrayerRequest,
  PrayerRequestUpdateInput
} from "../schema";

export type ActionUpdatePrayerRequestResponse = {
  prayerRequest: PrayerRequest
}

export const actionUpdatePrayerRequest = (client: ProfusionClient, prayerRequest: PrayerRequestUpdateInput) => {
  return executeMutation<ActionUpdatePrayerRequestResponse, MutationInput<PrayerRequestUpdateInput>>(client, PRAYER_REQUEST_UPDATE_MUTATION,
    {
      input: {
        hid: prayerRequest.hid,
        title: prayerRequest.title,
        text: prayerRequest.text,
        dateExpiration: prayerRequest.dateExpiration ? prayerRequest.dateExpiration : undefined
      }
    }, {
      isPrivate: true,
      refetchQueries: (data) => ([{
        query: PRAYER_REQUEST_LIST_QUERY,
        context: {
          use_private: true
        },
        variables: {
          group: data.prayerRequest.groupHid
        }
      }])
    })
}
