import {gql} from "@apollo/client";

export const GET_COMMUNITY_GROUP_MEMBERS_ALLOWED = gql`
    query getGroupMembersAllowed($groupHid: String!)  {
        getGroupMembersAllowed(groupHid: $groupHid) {
            hid
            user {
                avatar {
                    smallUrl
                    mediumUrl
                    largeUrl
                }
                phoneNumber
                email
                firstName
                lastName
            }
            accessPrayer            
            dateAdded
            isAdmin
            state
            tags {
                color
                name
                tagOrder
                hid
            }
        }
    }`
