import {PrayerEvent} from "@profusion.media/profusion-core-prayer"
import {Box, ListItem, ListItemAvatar} from "@mui/material";
import {TimeAgo, UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import "@styles/commentBox.css"
import {useConnectedUser, useGetConnectedUser} from "@profusion.media/profusion-iam-user";
import Button from "@mui/material/Button";
export interface PrayerCommentListItemProps {
    comment: PrayerEvent
}

interface CommentContentProps {
    comment: PrayerEvent
}

const CommentContent = (props: CommentContentProps) => {
    const {comment} = props
    return <Box
        className="comment-box"
        p={2}
        sx={{
            borderRadius: 4,
            backgroundColor: "#f0f0f0",
        }}>
        <Typography fontWeight={700} variant="caption" component="div">
            {comment.createdBy.displayName}
        </Typography>
        <Typography variant="body2" dangerouslySetInnerHTML={{__html: comment.comment ?? ""}}/>
    </Box>
}

interface PrayerCommentMetaProps {
    comment: PrayerEvent
}

export const PrayerCommentMeta = (props: PrayerCommentMetaProps) => {
    const {comment} = props
    const user = useConnectedUser()

    console.log(comment.createdBy.hid, user.profile.hid)

    if (comment.createdBy.hid === user.profile.hid) {
        return <Box>
            <TimeAgo date={comment.dateCreated}/>
            <Button size="small" variant="text">Modifier</Button>
        </Box>
    } else {
        return <TimeAgo date={comment.dateCreated}/>
    }
}

export const PrayerCommentListItem = (props: PrayerCommentListItemProps) => {
    const {comment} = props
    return <ListItem dense alignItems="flex-start">
        <ListItemAvatar>
            <UserAvatar avatar={comment.createdBy?.avatar?.smallUrl} userName={comment.createdBy.displayName}/>
        </ListItemAvatar>
        <ListItemText primary={<CommentContent comment={comment}/>}
                      secondary={<PrayerCommentMeta comment={comment}/>} />

    </ListItem>

}
