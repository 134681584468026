import {executeMutation, MutationInput, ProfusionClient, useProfusionClient} from "@profusion.media/profusion-core";
import {NOTIFICATION_MESSAGE_READ_MUTATION, NOTIFICATION_MESSAGES_QUERY} from "../schema";
import {NotificationReadInput, NotificationReadMutation} from "../schema/types";

export const actionNotificationRead = (client: ProfusionClient, messages: string[]) => {
  return executeMutation<NotificationReadMutation, MutationInput<NotificationReadInput>>(client, NOTIFICATION_MESSAGE_READ_MUTATION,
    {
      input: {
        messageHid: messages
      }
    }, {
      isPrivate: true,
      refetchQueries: [
        {
          query: NOTIFICATION_MESSAGES_QUERY,
          fetchPolicy: "network-only",
          context: {
            use_private: true,
          }
        }
      ]
    })
}

export const useNotificationRead = (): (messages: string[]) => Promise<any> => {
  const profusion = useProfusionClient()
  return (messages: string[]) => actionNotificationRead(profusion, messages)
}

