import {executeMutation, MutationInput, ProfusionClient} from "../../index"
import { DeviceInput, DeviceUpdateMutation } from "../schema/typings";
import { DEVICE_UPDATE_MUTATION } from "../schema/mutations";


export const actionDeviceUpdate = (client: ProfusionClient, device: DeviceInput) => {
  return executeMutation<DeviceUpdateMutation, MutationInput<DeviceInput>>(client, DEVICE_UPDATE_MUTATION,
    {
      input: {
        ...device
      }
    }, {
      isPrivate: true
    })
}
