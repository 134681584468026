import {useEffect, useState} from "react";
import {ApolloError, DocumentNode} from "@apollo/client";
import {executeQuery, getQueryNames} from "../utils";
import {GraphQueryOptions, GraphQueryPromise, QueryHookResult} from "../typings";
// noinspection ES6PreferShortImport
import {useAuthentication} from "../../core/hooks/authentication";
import profusion from "@config/profusion";

export const useGraphQuery = <TData, TVariables extends Record<string, any> = Record<string, any>>(
    query: DocumentNode,
    options: GraphQueryOptions<TVariables>
): QueryHookResult<TData> => {
    const [data, setData] = useState<TData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);
    const [promise, setPromise] = useState<GraphQueryPromise<TData>>(undefined)
    const {is_refreshing, session} = useAuthentication();

    const client = profusion.apollo
    const queryName = getQueryNames(query)[0];

    useEffect(() => {
        const subscription = client.watchQuery({
            query,
            variables: options.variables,
            context: {use_private: options.isPrivate}
        }).subscribe({
            next: (result) => {
                // Effectuez les actions nécessaires en cas de mise à jour de la requête
                console.log("Query updated:", result);
                setData(result.data?.[queryName] as TData);
            }
        });

        return () => {
            // Nettoyez la souscription lorsque le composant est démonté
            subscription.unsubscribe();
        };
    }, [client]);

    const awaitQuery = async (): Promise<void> => {
        try {
            const response = await promise
            setData(response.data as TData);
            setLoading(false);
        } catch (error) {
            setError(error as any);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!options.skip) {
            setPromise(executeQuery<TData, TVariables>(profusion, query, options))
            awaitQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, JSON.stringify(options.variables)]);

    const refetch = (): void => {
        setLoading(true);
        setPromise(executeQuery<TData, TVariables>(profusion, query, options))
        awaitQuery();
    };

    return {
        promise,
        data,
        loading,
        error,
        refetch,
        isRefreshing: is_refreshing
    };
};


