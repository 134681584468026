
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {
    actionUpdatePassword as _update,
    PasswordUpdateInput,
    PasswordValidationError
} from "@profusion.media/profusion-iam-user";
import profusion from "@config/profusion";



export type PasswordUpdateActionData = {
    errors: PasswordValidationError[],
    success: boolean
}

export const actionUpdatePassword = async (props: ActionFunctionArgs) => {
    const passwordData = await props.request.json()
    const response = await _update(profusion, {
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
        confirmPassword: passwordData.confirmPassword
    })
    if (response.validationErrors) {
        return {errors: response.validationErrors, success: false}
    }
    return {errors: [], success: response.data?.success}
}

export const useUpdatePassword = (passwordData: PasswordUpdateInput) : () => Promise<any> => {
    return () => _update(profusion, passwordData)
}