import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_CREATE_MUTATION = gql`
  ${GROUP_FIELDS}
  mutation groupCreate($input: GroupCreateInput!, $avatar: AvatarCreateInput) {
    groupCreate(input: $input, avatar: $avatar) {
      group {
        ...GroupFields
      }
      errors {
        field
        code
      }
    }
  }`;
