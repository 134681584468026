import {GROUP_DETAILS, GROUP_LIST_MINE_QUERY, GROUP_SUBSCRIBER_LIST} from "../schema";
import {executeMutation, ProfusionClient} from "@profusion.media/profusion-core";
import {MutationSubscriberRemoveArgs, SubscriberRemoveInput, SubscriberRemoveMutation} from "@profusion.media/graph";
import {
    SUBSCRIBER_REMOVE_MUTATION
} from "@profusion.media/profusion-core-group/groups/schema/mutations/subscriber_remove_mutation";
import profusion from "@config/profusion";


export const actionSubscriberRemove = (client: ProfusionClient, subscriber: SubscriberRemoveInput) => {
    return executeMutation<SubscriberRemoveMutation, MutationSubscriberRemoveArgs>(client, SUBSCRIBER_REMOVE_MUTATION,
        {
            input: subscriber
        }, {
            isPrivate: true,
            refetchQueries: [{
                query: GROUP_LIST_MINE_QUERY,
                context: {
                    use_private: true
                }
            }]
        })
}


export const useGroupSubscribers = (groupHid: string) => {
    return {
        remove: (hid: string) => {
            return executeMutation<SubscriberRemoveMutation, MutationSubscriberRemoveArgs>(profusion,
                SUBSCRIBER_REMOVE_MUTATION, {
                    input: {
                        hid
                    }
                }, {
                    isPrivate: true,
                    refetchQueries: [
                        {
                            query: GROUP_DETAILS,
                            variables: {
                                group: groupHid
                            },
                            context: {
                                use_private: true
                            }
                        },
                        {
                            query: GROUP_SUBSCRIBER_LIST,
                            variables: {
                                group: groupHid
                            },
                            context: {
                                use_private: true
                            }
                        }
                    ]
                }
            )
        }
    }
}