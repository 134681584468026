import React from "react"
import {CountrySelectInputDefaultProps, CountrySelectInputProps} from "./typings";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {getAllCountries} from "@profusion.media/profusion-core-geo"
import {COUNTRY_NAMES_FR} from "../data/countries";
import {useTranslation} from "react-i18next";


export const CountrySelectInput = (props: CountrySelectInputProps) => {
    const {value, onChange, label, required} = {...CountrySelectInputDefaultProps, ...props}
    const countries = getAllCountries()
    const {t} = useTranslation()


    return <FormControl>
        <InputLabel id="_country_label">{label}</InputLabel>
        <Select value={value?.toString() ?? ""}
                labelId="_country_label"
                onChange={e => onChange(e.target.value)}
                label={label}
                required={required}
                sx={{minWidth: "120px"}}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 10.5,
                        }
                    }
                }}
                variant="outlined"
                autoWidth>
            {countries.map((_c) => <MenuItem value={_c.alpha2}
                                             key={_c.alpha2}
                                             selected={_c.alpha2 === value}>
                {t(_c.i18nCode, COUNTRY_NAMES_FR[_c.alpha2])}
            </MenuItem>)}
        </Select>
    </FormControl>
}

