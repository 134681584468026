import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate} from "react-router-dom";
import {Suspense} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Community} from "@graph";
import {WhiteBox} from "@components/WhiteBox";
import List from "@mui/material/List";
import {IconButton, ListItem, ListItemSecondaryAction} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import {QrCodeSharing} from "@components/invitation/QrCodeSharing";


export const CommunityInvitationQR = () => {
    const {community, stats} = useCommunityDetailsLoader("communityDetailsAdmin")
    const navigate = useNavigate()

    const getUrl = (c: Community) => {
        return `https://app.profusion.media/community/${c.hid}`
    }

    const onClose = () => {
        navigate("../members")
    }


    return <Suspense>
        <Await resolve={community}>
            {({data}) =>
                <FullScreenDialog title="Présentez le code QR"
                                  subtitle={data.name}
                                  onClose={onClose}>


                    <QrCodeSharing url={getUrl(data)}/>

                    <WhiteBox>
                        <List>
                            <ListItem>
                                <ListItemText primary="Utiliser le lien de partage"/>
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => navigate("../invitation")}>
                                        <FontAwesomeIcon icon={faChevronRight}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </WhiteBox>

                </FullScreenDialog>
            }
        </Await>

    </Suspense>
}