import {gql, VALIDATION_ERROR_FRAGMENT} from "@profusion.media/profusion-core";
import {GROUP_FIELDS, SUBSCRIBER_FIELDS} from "../fragments";

export const SUBSCRIBER_INVITATION_MUTATION = gql`
  ${SUBSCRIBER_FIELDS}
  ${VALIDATION_ERROR_FRAGMENT}
  mutation subscriberInvitation($input: SubscriberInvitationInput!) {
    subscriberInvite(input: $input) {
      subscriber {
        ...SubscriberFields
      }
      errors {
        ...ValidationError
      }
    }
  }`;

export const ACCEPT_INVITATION_MUTATION = gql`
  ${SUBSCRIBER_FIELDS}
  ${VALIDATION_ERROR_FRAGMENT}
  mutation acceptInvitation($group: String!, $invitation: String!) {
    acceptInvitation(group: $group, invitation: $invitation) {
      subscriber {
        ...SubscriberFields
      }
      errors {
        ...ValidationError
      }
    }
  }`;

export const RESEND_INVITATION_MUTATION = gql`
  ${SUBSCRIBER_FIELDS}
  ${VALIDATION_ERROR_FRAGMENT}
  mutation resendInvitation($input: SubscriberInvitationInput!) {
    resendInvitation(input: $input) {
      subscriber {
        ...SubscriberFields
      }
      errors {
        ...ValidationError
      }
    }
  }`
