import {GroupSettingsForm} from "@groups/Groups/components/settings";
import {Await, useActionData, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {Suspense} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {useGroupDetailsLoader} from "@groups/Groups/GroupDetails/loaders";

export const CommunityGroupUpdate = () => {
    const {community} = useCommunityDetailsLoader("communityDetailsAdmin")
    const {group} = useGroupDetailsLoader()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()

    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'


    const handleSubmit = (data: any) => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate('..')
    }

    return <Suspense>
        <Await resolve={Promise.all([community, group])}>
            {([{data: c}, {data: g}]) =>
                <FullScreenDialog
                    title="Mettre à jour"
                    onClose={handleCancel}
                >
                    <GroupSettingsForm
                        group={g}
                        community={c}
                        mode="update"
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={loading}
                        validationErrors={validationErrors}/>
                </FullScreenDialog>
            }
        </Await>
    </Suspense>
}