import React from 'react';
import {HeadsUpProps} from "./typings";
import {Paper, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Theme} from '@mui/material/styles/createTheme';

export const HeadsUp: React.FC<HeadsUpProps> = ({
                                                    backgroundColor = '#fff',
                                                    elevation = 0,
                                                    children,
                                                    sx
                                                }) => {
    const [item1, item2] = children;
    const theme = useTheme()

    const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    let maxWidth = '100%';

    if (lgScreen) {
        maxWidth = '60%';
    } else if (mdScreen) {
        maxWidth = '80%';
    }

    return (
        <Paper sx={{backgroundColor: backgroundColor, maxWidth, ...sx}} elevation={elevation}>
            <Stack direction="row" spacing={2}>
                {item1}
                {item2}
            </Stack>
        </Paper>
    );
};

