import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS, GROUP_PUBLIC_FIELDS} from "../fragments";

export const GROUP_LIST_MINE_QUERY = gql`
  ${GROUP_FIELDS}
  query GroupListMine($type: String) {
    groupListMine(type: $type) {
      ...GroupFields
    }
  }`;

export const GROUP_LIST_SHARED_QUERY = gql`
  ${GROUP_FIELDS}
  query GroupListShared($type: String) {
    groupListShared(type: $type) {
      ...GroupFields
    }
  }`;


export const GROUP_LIST_ALL_QUERY = gql`
  ${GROUP_FIELDS}
  query GroupListAll {
    groupListAll {
      ...GroupFields
    }
  }`;


export const GROUP_DETAILS = gql`
  ${GROUP_PUBLIC_FIELDS}
  ${GROUP_FIELDS}
  query GroupDetails($group: String!) {
    groupDetails(group: $group) {
      ...GroupFields
      ...GroupPublicFields
      
    }
  }`;
