import {PasswordUpdateInput} from "@profusion.media/graph";
import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {ACCOUNT_PASSWORD_UPDATE} from "../schema/mutations/account_password_update";
import {PasswordUpdateResponse} from "../schema";


export const actionUpdatePassword = (client: ProfusionClient, input: PasswordUpdateInput) => {
    return executeMutation<PasswordUpdateResponse, MutationInput<PasswordUpdateInput>>(
        client, ACCOUNT_PASSWORD_UPDATE,
        {
            input: {
                confirmPassword: input.confirmPassword,
                newPassword: input.newPassword,
                oldPassword: input.oldPassword
            }
        }, {
            isPrivate: true,
        })
}
