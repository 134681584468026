import {useNavigate, useParams} from "react-router-dom";
import {faRectangleHistoryCircleUser, faThumbTack as faBookmark, faUserGroup} from "@fortawesome/pro-solid-svg-icons"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {DashboardAppContext} from "@groups/DashboardApp";

export const GroupsBottomNavigation = () => {
    const context = useContext(DashboardAppContext)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {group_type} = useParams()


    let paths = [
        `/groups/${group_type}/mine`,
        `/groups/${group_type}/shared`,
        `/groups/${group_type}/favorites`,
    ]


    const navigateTo = (idx: number) => {
        navigate(paths[idx])
    }

    return <BottomNavigation
        showLabels
        value={context.bottomActiveTab}
        onChange={(event, newValue) => {
            context.setBottomTab(newValue);
            navigateTo(newValue)
        }}
        className="bottom-navigation"
    >
        <BottomNavigationAction
            label={t("prayer.menu.my_groups", "Mes groupes")}
            icon={<FontAwesomeIcon icon={faUserGroup} fixedWidth/>}/>
        <BottomNavigationAction
            label={t("prayer.menu.shared_groups", "Partagés avec moi")}
            icon={<FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}/>
        {group_type === 'prayer' && <BottomNavigationAction
            label={t("prayer.menu.pinned", "En cours")}
            icon={<FontAwesomeIcon icon={faBookmark} fixedWidth/>}/>}

    </BottomNavigation>
}