import {DocumentNode, gql} from "@profusion.media/profusion-core";
import {AVATAR_FIELDS} from "./avatar";

export const PROFILE_FIELDS : DocumentNode = gql`
  ${AVATAR_FIELDS}
  fragment ProfileFields on ProfileIdentity {
    hid
    kind
    displayName
    avatar {
      ...AvatarFields
    }
  }
`
