import {PartialBy} from "@profusion.media/profusion-core";

export type AddressVisibility = "public" | "private" | "restricted"

export type Address = {
  kind?: string
  category?: string

  city: string
  country: string
  province?: string

  displayName?: string
  postalCode: string

  street?: string
  visibility: AddressVisibility
  coordinates?: string
}

export const EmptyAddress : Address = {
  city: "",
  country: "",
  province: "",
  postalCode: "",
  street: "",
  displayName: "",
  visibility: "restricted"
}


export type AddressInput = PartialBy<Address, "coordinates" | "kind" | "category">

export type PlacesApiAddressComponent = {
  short_name: string
  long_name: string
  types: string[]
}

export type AddressComponentType = "street_number" | "route" | "locality" | "administrative_area_level_1" | "country" | "postal_code"
