
export interface UserAvatarProps {
    userName?: string
    avatar?: string
    size?: number
    addBorder?: boolean
}

export const UserAvatarPropsDefaults : UserAvatarProps = {

}