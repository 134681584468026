import {Container} from "@mui/material";
import {ProfusionAppBar} from "@components/layout/dashboard/appbar/ProfusionAppBar";
import {DashboardDrawer} from "@components/layout/dashboard/drawer/DashboardDrawer";
import {DashboardDrawerContent} from "@components/layout/dashboard/drawer/DashboardDrawerContent";
import {BodyContainer} from "@components/BodyContainer";
import {Outlet, useLocation} from "react-router-dom";
import React from "react";
import {EnsureInApp} from "@components/EnsureInApp";
import {ScreenContainer} from "@components/ScreenContainer";

export const CommunityLayout = () => {
    const location = useLocation()
    return <EnsureInApp next={location.pathname}>
        <ScreenContainer>
            <ProfusionAppBar title="Communautés"/>

            <DashboardDrawer>
                <DashboardDrawerContent/>
            </DashboardDrawer>
            <BodyContainer sx={{paddingBottom: 8, pt: 7}}>
                <Outlet/>
            </BodyContainer>
        </ScreenContainer>
    </EnsureInApp>
}