import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {faQrcode} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {CapacitorBarcodeScanner} from "@capacitor/barcode-scanner"
import {Html5QrcodeSupportedFormats} from "html5-qrcode";
import {useNavigate} from "react-router-dom";
import {Button, IconButton} from "@mui/material";

export interface QrScannerIconProps {
    color?: string;
    size?: FontAwesomeIconProps["size"];
    variant?: "icon" | "button"
}

export const scanQrCode = () => {
    return CapacitorBarcodeScanner.scanBarcode({
        hint: Html5QrcodeSupportedFormats.QR_CODE,
    }).then(value => {
        const internal = value.ScanResult.replace("https://app.profusion.media", "")
        console.log(internal)
        return Promise.resolve(internal)
    });
}

export const QrScannerIcon = (props: QrScannerIconProps) => {
    const {color, size, variant} = props
    const navigate = useNavigate();

    const handleOnScan = () => {
        scanQrCode().then(internal =>            navigate(internal))
    }

    if (!variant || variant === "icon") {
        return <IconButton onClick={handleOnScan}>
            <FontAwesomeIcon icon={faQrcode}
                             size={size ?? "1x"}
                             color={color ?? "white"}/>
        </IconButton>
    } else {
        return <Button variant="contained"  onClick={handleOnScan} startIcon={<FontAwesomeIcon icon={faQrcode} />}>
            Scanner un code QR
        </Button>
    }

}