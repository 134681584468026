import {PasswordUpdateResetInput} from "@profusion.media/graph";
import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {PasswordResetUpdateResponse} from "../schema";
import {ACCOUNT_PASSWORD_UPDATE_RESET} from "../schema/mutations/account_password_update_reset";


export const actionUpdateResetPassword = (client: ProfusionClient, input: PasswordUpdateResetInput) => {
  return executeMutation<PasswordResetUpdateResponse, MutationInput<PasswordUpdateResetInput>>(client, ACCOUNT_PASSWORD_UPDATE_RESET,
    {
      input: {
        confirmPassword: input.confirmPassword,
        newPassword: input.newPassword,
        code: input.code,
        email: input.email
      }
    }, {
      isPrivate: false,
    })
}
