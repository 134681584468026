import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_JOIN_MUTATION = gql`
  ${GROUP_FIELDS}
  mutation groupJoin($input: GroupJoinInput!) {
    groupJoin(input: $input) {
      ...GroupFields
    }
  }`;
