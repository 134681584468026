import {Backdrop, Box, Button, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {RefObject, useRef, useState} from "react";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {useCreatePayerPin} from "../actions/prayer_pin_create";
import {RichPopper} from "@components/RichPopper";
import Typography from "@mui/material/Typography";
import {LoadingIcon} from "@components/icons/LoadingIcon";

//import {faBookmark} from "@fortawesome/sharp-solid-svg-icons"
//import {faBookmarkSlash, faBookmark as faBookmarkNone} from "@fortawesome/pro-light-svg-icons"
import {faThumbTack as faBookmarkNone, faThumbtackSlash as faBookmarkSlash} from "@fortawesome/pro-light-svg-icons"
import {faThumbTack as faBookmark} from "@fortawesome/pro-solid-svg-icons"

export interface PrayerPinButtonProps {
    request: PrayerRequest
    onComplete?: () => void
}

export interface PinColorChooserProps {
    open: boolean
    onClose: () => void
    loading: boolean
    onSelect: (color: string) => void
    anchor: any
}

export const pinColors = ['grey', 'red', 'blue', 'green', 'purple', 'orange', 'pink']

export const PinColorChooser = (props: PinColorChooserProps) => {
    const {open, onSelect, onClose, anchor, loading} = props
    return <Backdrop open={open} invisible={true} onClick={onClose} sx={{zIndex: 1}}>
        <RichPopper open={open}
                    placement="top-end"
                    anchor={anchor}>
            <Box sx={{padding: 2, backgroundColor: "#fafafa"}}>
                {loading && <Typography><LoadingIcon/></Typography>}
                {!loading && <Stack spacing={2} direction="row">
                    <FontAwesomeIcon icon={faBookmarkSlash}
                                     size="1x"
                                     onClick={_ => onSelect("none")}/>
                    {pinColors.map((c, index) => <FontAwesomeIcon key={index}
                                                                  icon={faBookmark} color={c}
                                                                  size="1x"
                                                                  onClick={_ => onSelect(c)}/>)}
                </Stack>}
            </Box>
        </RichPopper>
    </Backdrop>
}


export const PrayerPinButton = (props: PrayerPinButtonProps) => {
    const {request, onComplete} = props
    const [loading, setLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const addPayedPin = useCreatePayerPin(request)
    const anchor = useRef<HTMLElement>() as RefObject<any>

    const openSelector = () => {
        setOpen(true)
    }

    const closeSelector = () => {
        setOpen(false)
    }

    const handleSubmit = (color: string) => {
        setOpen(false)
        setLoading(true)
        addPayedPin(color).then(() => {
            setLoading(false)
        }).then(_ => {
            if (onComplete) onComplete()
        })
    }

    return <div>
        <Button
            size="small"
            ref={anchor}
            onClick={openSelector}
            color='inherit'
            sx={{color: request.pinColor !== 'none' ? `${request.pinColor}` : 'inherit'}}
            startIcon={<FontAwesomeIcon size="4x"
                color={request.pinColor !== 'none' ? `${request.pinColor}` : undefined}
                icon={request.pinColor !== 'none' ? faBookmark : faBookmarkNone}/>}
        >En cours</Button>
        <PinColorChooser anchor={anchor?.current}
                         open={open}
                         loading={loading}
                         onClose={closeSelector}
                         onSelect={handleSubmit}/>
    </div>
}