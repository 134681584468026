import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {IconButton} from "@mui/material";
import {Clipboard, WriteOptions} from "@capacitor/clipboard";
import React from "react"

export interface ClipboardCopyButtonProps {
    value: WriteOptions
    onCopyComplete?: () => void;
}

export const ClipboardCopyButton = (props: ClipboardCopyButtonProps) => {
    const {onCopyComplete, value} = props
    const [completed, setCompleted] = React.useState<boolean>(false)
    const onCopy = () => {
        Clipboard.write(value).then(_ => {
            setCompleted(true)
            setTimeout(() => setCompleted(false), 2000)
            onCopyComplete && onCopyComplete();
        })
    }

    if (completed) {
        return <IconButton color="success">
            <FontAwesomeIcon icon={faCheck}  size="xs"/>
        </IconButton>
    } else {
        return <IconButton
            color="primary"
            onClick={onCopy}
        ><FontAwesomeIcon icon={faCopy}/></IconButton>
    }
}