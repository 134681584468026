import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_UPDATE_MUTATION = gql`
  ${GROUP_FIELDS}
  mutation groupUpdate($input: GroupUpdateInput!, $avatar: AvatarCreateInput) {
    groupUpdate(input: $input, avatar: $avatar) {
      group {
        ...GroupFields
      }
      errors {
        field
        code
      }
    }
  }`;
