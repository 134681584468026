import {Button, Container, Paper, Stack} from "@mui/material";
import {faUsersMedical} from "@fortawesome/pro-duotone-svg-icons"
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import {useNavigationCreateGroup} from "../../Groups/hooks/navigation";
import Typography from "@mui/material/Typography";

export const HomeEmptyGroupsMineDiscover = () => {
    const navigateCreate = useNavigationCreateGroup()

    const handleAdd = () => {
        navigateCreate()
    }

    return (
        <Paper sx={{paddingX: 6, paddingY: 10}}>
            <Container maxWidth="sm">
                <Stack direction="column" spacing={4} alignItems="center">
                    <DuoToneIcon
                        size="5x"
                        style={{
                            "--fa-primary-color": "#08348c",
                            "--fa-secondary-color": "#1186c5"
                        }}
                        icon={faUsersMedical}/>
                    <h2>Vous n'avez encore aucun groupe de découverte</h2>
                    <Typography variant="body1">En quelques clics, il vous est possible de
                    créer un premier groupe et d'inviter des
                    proches à découvrir Jésus avec vous.</Typography>
                    <Button variant="contained"
                            color="primary"
                            onClick={handleAdd}>Créer votre premier groupe</Button>
                </Stack>
            </Container>

        </Paper>
    )
}