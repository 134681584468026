import {GroupOrigin} from "@profusion.media/profusion-core-group";
import {useTranslation} from "react-i18next";
import {Avatar, Button, Card, CardHeader, IconButton, Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
    faRectangleHistoryCircleUser,
    faUserGroup,
    faUsers,
    faUsersRectangle
} from "@fortawesome/pro-solid-svg-icons";
import {useNavigationCreateGroup} from "../../hooks/navigation";
import {WhiteBox} from "@components/WhiteBox";

interface GroupListTitleProps {
    what: GroupOrigin
}

export const GroupListTitle = (props: GroupListTitleProps = {what: "all"}) => {
    const {t} = useTranslation()
    const navigateCreate = useNavigationCreateGroup()

    const isMine = props.what === "mine"

    const handleAdd = () => {
        navigateCreate()
    }

    const title =
        props.what === "mine" ? t("prayer.groups.mine.title", "Mes groupes") :
            props.what === "shared" ? t("prayer.groups.shared.title", "Groupes partagés avec moi") :
                t("prayer.groups.all.title", "Tous les groupes")

    const icon =
        props.what === "mine" ? faUserGroup : faRectangleHistoryCircleUser

    return <WhiteBox title={title}
                     titleVariant='h6'
                     icon={<FontAwesomeIcon size="xl" icon={icon}/>}
                     secondaryAction={isMine &&
                         <Button
                             size="small"
                             color="primary"
                             startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                             onClick={handleAdd}>
                             Créer
                         </Button>}
    >    </WhiteBox>


}