import {executeMutation} from "@profusion.media/profusion-core";
import {CommunityMember, CommunityMemberSetAdminInput, MutationCommunityMemberSetAdminArgs} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_MEMBER_SET_ADMIN_MUTATION} from "@community/schema/mutations/community_member";
import {GET_COMMUNITY_MEMBERS} from "@community/schema/queries/get_community_details";


export const useCommunityMemberSetAdmin = (memberHid: string) => {
    return {
        setAdmin: (isAdmin: boolean) => {
            return executeMutation<CommunityMember, MutationCommunityMemberSetAdminArgs>(
                profusion,
                COMMUNITY_MEMBER_SET_ADMIN_MUTATION,
                {
                    input: {
                        isAdmin,
                        memberHid
                    }
                },
                {
                    isPrivate: true
                })
        }
    }
}