import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import GridRow from "../GridRow";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useNavigate} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";


const ContactInfo: React.FC = () => {
    const user = useConnectedUser()
    const navigate = useNavigate()

    if (!user) return <></>

    return <WhiteBox title="Contact">

                <GridRow label="E-mail" value={user.email}
                         onEdit={() => {
                             navigate("contact-info")
                         }}
                />
                <GridRow label="Téléphone" value={user.phoneNumber ?? ""}
                         onEdit={() => {
                             navigate("contact-info")
                         }}
                />

        </WhiteBox>
}

export default ContactInfo;
