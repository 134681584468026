import {ReactNode, useEffect, useState} from "react";
import {Device, DeviceInfo} from "@capacitor/device";
import {useAuthentication} from "@profusion.media/profusion-core";
import {useLocation, useNavigate} from "react-router-dom";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {WaitingPage} from "@profusion.media/profusion-ui";

export interface EnsureInAppProps {
    next: string
    children: ReactNode
}
export const EnsureInApp = (props: EnsureInAppProps) => {
    const { children , next} = props
    const [info, setInfo] = useState<DeviceInfo>();
    const user = useConnectedUser()
    const navigate = useNavigate()

    console.info("EnsureInApp", user)

    useEffect(() => {
        const getInfo = async () => {
            const info = await Device.getInfo()
            setInfo(info)
        }
        getInfo()
    }, [])

    if (!info) return <WaitingPage show={true} />;

    if (info?.platform === "web" && window.location.hostname !== "localhost") {
        navigate("/downloadApp", {
            state: {
                next
            }
        })
        return
    }

    if (!user?.hid) {
        navigate("/login?next=" + next)
        return
    }

    console.info("EnsureInApp PASS", user)

    return children

}