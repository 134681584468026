import {gql} from "@profusion.media/profusion-core";

export const COMMUNITY_INVITE_MUTATION = gql`
    mutation communityInvite($input: CommunityInviteInput!) {
        communityInvite(input: $input) {
            success
            message
        }
    }`;

export const COMMUNITY_ADMIN_LINK = gql`
    mutation CommunityAdminLink($input: CommunityAdminLinkInput!) {
        communityAdminLink(input: $input) {
            message
            success
            link
        }
    }`