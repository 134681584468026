import {gql} from "@profusion.media/profusion-core";
import {SUBSCRIBER_FIELDS} from "@profusion.media/profusion-core-group";

export const COMMUNITY_GROUP_ADD_MEMBERS_MUTATION = gql`
    ${SUBSCRIBER_FIELDS}
    mutation communityGroupAddMembers($input: CommunityGroupAddMemberInput!) {
        communityGroupAddMembers(input: $input) {
            ...SubscriberFields
        }
    }`;
