import {Group, GROUP_DETAILS, GROUP_LIST_MINE_QUERY, GROUP_UPDATE_MUTATION, GroupUpdateInput} from "../schema";
import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {AvatarCreateInput, MutationGroupUpdateArgs} from "@profusion.media/graph";

export type ActionUpdateGroupResponse = {
  group: Group
}

export const actionUpdateGroup = (client: ProfusionClient, group: GroupUpdateInput, avatar?: AvatarCreateInput) => {
  return executeMutation<ActionUpdateGroupResponse, MutationGroupUpdateArgs>(client, GROUP_UPDATE_MUTATION,
    {
      input: {
        title: group.title,
        welcome: group.welcome,
        hid: group.hid,
        language: group.language,
        tags: group.tags
      },
      avatar
    }, {
      isPrivate: true,
      refetchQueries: (data, variables) => [{
        query: GROUP_LIST_MINE_QUERY,
        variables: {
          type: data.group.mainFeature,
        },
        context: {
          use_private: true
        }
      }, {
        query: GROUP_DETAILS,
        context: {
          use_private: true
        },
        variables: {
          group: group.hid
        }
      }]
    })
}
