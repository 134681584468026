import {gql} from "@profusion.media/profusion-core";

export const ACCOUNT_PASSWORD_RESET_REQUEST = gql`
  mutation($emailOrPhone: String!, $language: String) {
    passwordReset(emailOrPhone: $emailOrPhone, language: $language) {
      __typename
      success
      errors {
        field
        code
      }

    }
  }`;
