import {gql} from "@profusion.media/profusion-core";
import {DETAILED_INVITATION_FIELDS, PUBLIC_INVITATION_FIELDS, SUBSCRIBER_FIELDS} from "../fragments";

export const GROUP_INVITATION_LIST = gql`
  ${SUBSCRIBER_FIELDS}
  query GroupInvitationList ($group: String!) {
    groupInvitationList (group: $group) {
      ...SubscriberFields
    }
  }`;

export const INVITATION_DETAILS = gql`
  ${PUBLIC_INVITATION_FIELDS}
  ${DETAILED_INVITATION_FIELDS}
  query GroupInvitationDetails ($hid: String!) {
    invitationDetails(hid: $hid) {
      ...PublicInvitationFields
      ...DetailedInvitationFields
    }
  }
`

export const PENDING_INVITATION_LIST = gql`
  ${PUBLIC_INVITATION_FIELDS}
  query PendingInvitations {
    pendingInvitations {
      ...PublicInvitationFields
    }
  }
`
