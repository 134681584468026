import {useFetcher, useNavigate} from "react-router-dom";
import {Container, ListItem} from "@mui/material";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/pro-regular-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import React, {useEffect} from "react";
import {GroupAppBarSettingsProps} from "@groups/Groups/components/groupScreen/GroupAppBarSettings";
import {GroupLeaveMutation} from "@graph";

export const GroupBarSettingsShared = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const {data, submit, state} = useFetcher<GroupLeaveMutation>();
    const navigate = useNavigate()

    const handleLeave = () => {
        if (confirm("Êtes-vous sûr de vouloir quitter ce groupe ?")) {
            submit({
                groupHid: group.hid,
            }, {
                action: `/groups/${group.mainFeature}/${group.hid}/leave`,
                fetcherKey: `groupLeave${group.hid}`,
                method: "POST",
                encType: "application/json",
                navigate: true,
            })
        }
    }

    useEffect(() => {
        if (state === 'idle' && data) {
            if (data.status) {
                navigate(`/groups/${group.mainFeature}/shared`, {
                    replace: true,
                })
            }
        }
    }, [data, state])

    return <Container maxWidth="lg" disableGutters>
        <List
            sx={{pl: 2, width: '100%', bgcolor: 'background.paper'}}
            subheader={<ListSubheader>Paramètres</ListSubheader>}
        >

            <ListItem sx={{color: "#ff0000"}} onClick={handleLeave}>
                <ListItemIcon>
                    <FontAwesomeIcon color="#ff0000" icon={faSignOut} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Quitter le groupe"/>
            </ListItem>
        </List>
    </Container>
}