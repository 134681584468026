import { BaseType, MutationResponse } from "../../types";
import { gql } from "@apollo/client";


export type ApiToken = BaseType<"ApiToken"> & {
  access?: string
  refresh?: string
  scope?: Array<string>;
}

export type ClientCountry = BaseType<"ClientCountry"> & {
  country?: string
  region?: string
  city?: string
}

export type ApiConnectResponse = BaseType<"ApiConnect"> & {
  token?: ApiToken
  clientCountry?: ClientCountry
}

export type ApiConnectMutation = MutationResponse<"apiConnect", ApiConnectResponse>
export type ApiConnectInput = {
  key: string
  secrets: string
}
export const API_CONNECT_MUTATION = gql`mutation ApiConnect($key: String!, $secrets: String!) {
  apiConnect(key: $key, secrets: $secrets) {
    token {
      access
      refresh
      scope
    }
    clientCountry {
      country
      region
      city
    }
  }
}`;
