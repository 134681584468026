import {gql} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "@organizations/schema/fragments/organization";


export const ORGANIZATION_CHILDREN_QUERY = gql`
    ${ORGANIZATION_FIELDS}
    query OrganizationChildren($parent: String!) {
        getOrganizationChildren (parent: $parent) {
            ...organizationFields
        }
    }`;