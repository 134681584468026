import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_DELETE_MUTATION = gql`  
  mutation groupDelete($input: GroupDeleteInput!) {
    groupDelete(input: $input) {
      success
      feature
    }
  }`;
