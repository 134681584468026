import {Box, Container, IconButton, Paper, Toolbar, Typography, useTheme} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React from "react";
import {FullScreenDialogHeaderProps, fullScreenDialogHeaderPropsDefaults} from "../typings";
import {HelpButton} from "../../HelpButton/HelpButton";
import {GroupBackButton} from "@groups/Groups/GroupDetails/components/GroupBackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons";

export const FullScreenDialogHeader = (props: FullScreenDialogHeaderProps) => {
    const theme = useTheme();
    const {onClose, maxWidth,helpContent, title, subtitle, showHelp} = {...fullScreenDialogHeaderPropsDefaults, ...props}
    return <Paper sx={{position: 'relative'}}>
        <Container disableGutters fixed>
            <Toolbar className="profusionAppBar" sx={{
                backgroundColor: theme.palette.primary.main,
                color: "#fafafa",
            }}>
                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faArrowLeft}/>
                </IconButton>
                <Box sx={{ml: 2, flex: 1}}>
                    <Typography  variant="h6" component="div">{title}</Typography>
                    {subtitle && <Typography variant="caption" component="div">{subtitle}</Typography>}
                </Box>

                {showHelp && <HelpButton content={helpContent} />}
            </Toolbar>
        </Container>
    </Paper>

}