import {GROUP_LEAVE_MUTATION, GROUP_LIST_MINE_QUERY, GROUP_LIST_SHARED_QUERY, MutationGroupLeaveArgs} from "../schema";
import {executeMutation} from "@profusion.media/profusion-core";
import {ActionFunctionArgs} from "react-router-dom";
import {GroupLeaveMutation} from "@graph";
import profusion from "@config/profusion";


export const actionLeaveGroup = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationGroupLeaveArgs
    const response = await executeMutation<GroupLeaveMutation, MutationGroupLeaveArgs>(profusion, GROUP_LEAVE_MUTATION,
        {
            groupHid: data.groupHid
        }, {
            isPrivate: true,
            refetchQueries: [{
                query: GROUP_LIST_MINE_QUERY,
                context: {
                    use_private: true
                }
            }, {
                query: GROUP_LIST_SHARED_QUERY,
                context: {
                    use_private: true
                }
            },]
        })

    return response.data
}
