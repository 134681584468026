import {Container} from "@mui/material";
import {ProfusionAppBar} from "@components/layout/dashboard/appbar/ProfusionAppBar";
import {DashboardDrawer} from "@components/layout/dashboard/drawer/DashboardDrawer";
import {DashboardDrawerContent} from "@components/layout/dashboard/drawer/DashboardDrawerContent";
import {BodyContainer} from "@components/BodyContainer";
import {Outlet, useLocation} from "react-router-dom";
import React from "react";
import {EnsureInApp} from "@components/EnsureInApp";

export const DashboardHomeLayout = () => {
    const location = useLocation()
    console.log("DHL",location.pathname)
    return <EnsureInApp next={location.pathname}>
        <ProfusionAppBar title="Accueil"/>
        <Container disableGutters sx={{pt:7}}>
            <DashboardDrawer>
                <DashboardDrawerContent/>
            </DashboardDrawer>
            <BodyContainer disableGutters className="body-container">
                <Outlet/>
            </BodyContainer>
        </Container>
    </EnsureInApp>
}