import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Content} from "@graph";
import {defer, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {GET_PUBLIC_CONTENT, GET_USER_CONTENT} from "@community/schema/queries/get_user_content";

const _loadUserAvailableContent = (client: ProfusionClient): GraphQueryPromise<Content[]> => {
    return executeQuery<Content[]>(client, GET_USER_CONTENT, {
        isPrivate: true,
    })
}

const _loadPublicContent =  (client: ProfusionClient): GraphQueryPromise<Content[]> => {
    return executeQuery<Content[]>(client, GET_PUBLIC_CONTENT, {
        isPrivate: true,
    })
}


const loadData = () => {
    return {
        content: _loadUserAvailableContent(profusion),
        publicContent: _loadPublicContent(profusion),
    }
}


export const loadUserAvailableContent = () => {
    return defer(loadData())
}


export const useUserAvailableContentLoader = (routeId?: string) => {
    if (!routeId) {
        return useLoaderData() as ReturnType<typeof loadData>
    }
    return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
}
