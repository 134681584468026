import React from "react"
import {useFormUpdating, useOnSubmit} from "../hooks";
import {FormButton} from "./FormButton";
import {SUBMIT_BUTTON_DEFAULT_PROPS, SubmitButtonProps} from "../typings";
import {useTranslation} from "react-i18next";

export const SubmitButton = (props: SubmitButtonProps) => {
    const handler = useOnSubmit()
    const updating = useFormUpdating()
    const {label,icon, activityIcon,iconPosition} = {...SUBMIT_BUTTON_DEFAULT_PROPS, ...props}
    const {t} = useTranslation()

    const currentIcon = updating ? activityIcon : icon

    return <FormButton handler={handler}
                       label={label ?? t("common.forms.btn.submit", "Enregistrer")}
                       icon={currentIcon}
                       iconPosition={iconPosition}
                       variant="contained"
                       disabled={updating}
                       color="primary" />
}