import {Outlet} from "react-router-dom";
import {Card, CardContent, Container} from "@mui/material";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {SuperAdminSpeedDial} from "./SuperAdminSpeedDial";
import React from "react";
import {DashboardDrawer} from "@components/layout/dashboard/drawer/DashboardDrawer";
import {DashboardDrawerContent} from "@components/layout/dashboard/drawer/DashboardDrawerContent";
import {ProfusionAppBar} from "@components/layout/dashboard/appbar/ProfusionAppBar";
import {BodyContainer} from "@components/BodyContainer";
import {SuperAdminBottomNavigation} from "./SuperAdminBottomNav";

export const SuperAdminHome = () => {
    const user = useConnectedUser()

    if (!user || !user.isSuperuser) {
        return <Card>
            <CardContent>
                Accès refusé
            </CardContent>
        </Card>
    }

    return <Container disableGutters>
        <ProfusionAppBar color="warning" title="Super Admin"/>

        <DashboardDrawer>
            <DashboardDrawerContent/>
        </DashboardDrawer>
        <BodyContainer disableGutters>
            <Outlet/>
        </BodyContainer>

        <SuperAdminSpeedDial/>
        <SuperAdminBottomNavigation />
    </Container>
}