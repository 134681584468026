import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {CommunityMember} from "@graph";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {GET_COMMUNITY_MEMBERS} from "../schema/queries/get_community_details";
import {GET_COMMUNITY_GROUP_MEMBERS_ALLOWED} from "@community/schema/queries/get_community_group_members";

const _loadCommunityGroupMembersAllowed = (client: ProfusionClient, groupHid: string): GraphQueryPromise<CommunityMember[]> => {
    return executeQuery<CommunityMember[]>(client, GET_COMMUNITY_GROUP_MEMBERS_ALLOWED, {
        isPrivate: true,
        networkOnly: true,
        variables: {
            groupHid
        }
    })
}


const loadData = (hid: string) => {
    return {
        members: _loadCommunityGroupMembersAllowed(profusion, hid)
    }
}


export const loadCommunityGroupMembersAllowed = (props: LoaderFunctionArgs<["community", "hid"]>) => {
    return defer(loadData(props.params.hid ?? ""))
}

export const useCommunityGroupMembersAllowedLoader = (routeId?: string) => {
    if (!routeId) {
        return useLoaderData() as ReturnType<typeof loadData>
    } else {
        return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
    }
}


