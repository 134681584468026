import {gql} from "@profusion.media/profusion-core";
import {NOTIFICATION_MESSAGE_FIELDS} from "../fragments";

export const NOTIFICATION_MESSAGE_READ_MUTATION = gql`
  ${NOTIFICATION_MESSAGE_FIELDS}
  mutation notificationMessageRead($input: NotificationReadInput!) {
    notificationRead(input: $input) {
      message {
        ...NotificationMessageFields
      }
      errors {
        field
        code
      }
    }
  }`;
