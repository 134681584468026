import {Container, ContainerProps} from "@mui/material"

export const BodyContainer = (props: ContainerProps) => {
    const {sx, children, className,...rest} = props

    return <Container
        id="bodyContainer"
        maxWidth={false}
        disableGutters
        className={className}
        sx={{
            ...sx,
            flexGrow: 1,
            backgroundColor: "#d5d5d5",
        }}
    >

            {children}

    </Container>
}