import {Await, Outlet, useNavigate} from "react-router-dom";
import {Suspense} from "react";
import {OrganizationCommunityList} from "./OrganizationCommunityList";
import {OrganizationDetailsHeader} from "./components/OrganizationDetailsHeader";
import {Box, Button, Toolbar} from "@mui/material";
import {WhiteBox} from "@components/WhiteBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";
import {OrganizationChildren} from "./components/OrganizationChildren";

export const OrganizationDetails = () => {
    const {organization: organizationData} = useOrganizationDetailsLoader("organizationDetailsSU")
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return <Suspense>
        <Box>
            <Await resolve={organizationData}>
                {({data: organization}) => <>
                    <Toolbar>
                        <Button
                            startIcon={<FontAwesomeIcon icon={faChevronLeft}
                                                        onClick={handleBack}
                            />}>Retour à la liste</Button>
                    </Toolbar>
                    <OrganizationDetailsHeader organization={organization}/>
                </>}
            </Await>
            <WhiteBox title="Communautés">
                <OrganizationCommunityList/>
            </WhiteBox>
            <WhiteBox title="Organisations enfant">
                <OrganizationChildren/>
            </WhiteBox>
        </Box>
        <Outlet/>
    </Suspense>
}