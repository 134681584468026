import {BaseType, MutationResponse} from "../../../types";
import {gql} from "@apollo/client";


export type RefreshTokenResponse = BaseType<"TokenAuth"> & {
    token: string
    refreshToken: string
    refreshExpiresIn: number
    payload: any
}

export type RefreshTokenMutation = MutationResponse<"refreshToken", RefreshTokenResponse>
export type RefreshTokenInput = {
    refresh: string
}

export const REFRESH_TOKEN = gql`mutation RefreshToken($refresh: String!) {
    refreshToken(refreshToken: $refresh) {
        token
        refreshToken
        refreshExpiresIn
        payload
    }
}`
