import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthSession } from "./session";
import { GRAPHQL_ENDPOINT } from "../config/api";

import { API_CONNECT_MUTATION, ApiConnectInput, ApiConnectMutation, ApiToken, ClientCountry } from "./schema";
import { AppRegistry } from "./registry";
import { Language } from "../config/language";
import { memoryCacheConfiguration } from "./cache/config";
import { initMessaging } from "../messaging/setup_messaging";
import { Device } from "@capacitor/device";


const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT
});


export interface ProfusionCredentials {
  key: string;
  secrets: string;
}

export interface ClientSetupProps {
  use?: Array<(client: ProfusionClient) => void>;
}

type onLanguageChangeHandler = (lang: string) => void
type onSessionEndHandler = (session: AuthSession) => void

export class ProfusionClient {
  apollo: ApolloClient<any>;
  private credentials: ProfusionCredentials;
  private api_token?: ApiToken;
  private deviceHid?: string;
  private client_country?: ClientCountry;
  public appRegistry: AppRegistry;
  public language: Language;
  public is_loaded: boolean;
  public onLanguageChange: onLanguageChangeHandler;
  public onSessionEnd: onSessionEndHandler;

  session: AuthSession;

  constructor(api_key: string, api_secrets: string,
              onLanguageChange: onLanguageChangeHandler,
              onSessionEnd: onSessionEndHandler) {
    this.appRegistry = new AppRegistry();
    this.session = new AuthSession(this);
    this.onLanguageChange = onLanguageChange;
    this.onSessionEnd = onSessionEnd;
    this.is_loaded = false;
    this.credentials = {
      key: api_key,
      secrets: api_secrets
    };

    const cache = new InMemoryCache(memoryCacheConfiguration);
    const authLink = setContext(async (_, { headers, use_private, use_device }) => {
      let newHeaders = <any>{};
      if (this.api_token) {
        newHeaders["api-token"] = this.api_token.access;
      }
      if (use_private) {
        const token = await this.session.get_access_token();
        newHeaders["Authorization"] = "JWT " + token;
      }
      if (use_device) {
        newHeaders["X-Device"] = this.getDeviceHid();
      }
      newHeaders["X-Language"] = this.language;

      return {
        headers: {
          ...headers,
          ...newHeaders
        }
      };
    });

    const cleanTypeName = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === "__typename" ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
      }
      return forward(operation).map((data) => {
        return data;
      });
    });

    console.log("Configure Apollo")

    this.apollo = new ApolloClient({
      connectToDevTools: true,
      link: from([
        cleanTypeName,
        authLink,
        httpLink
      ]),
      cache
    });
  }


  async init() {
    console.log("INIT API CONNECT")
    const response = await this.apollo.mutate<ApiConnectMutation, ApiConnectInput>({
        mutation: API_CONNECT_MUTATION,
        variables: {
          key: this.credentials.key,
          secrets: this.credentials.secrets
        }
      }
    );
    this.api_token = response.data?.apiConnect?.token;
    console.log("API CONNECT TOKEN", this.api_token)
    this.client_country = response.data?.apiConnect?.clientCountry;
    console.log("INIT DEVICE INFO")
    await this.retrieveDeviceHid();
    console.log("INIT SESSION")
    await this.session.init();
    initMessaging(this);
    return this;
  }

  getDeviceHid(): string {
    return this.deviceHid ?? "";
  }

  checkPermission(perm: string): boolean {
    if (this.api_token?.scope) {
      return this.api_token.scope.includes(perm);
    }
    return false;
  }

  checkPermissions(perms: Array<string>): { [R: string]: boolean } {
    const result = {};
    perms.forEach(element => {
      result[element] = this.checkPermission(element);
    });

    return result;
  }

  getClientCountry(): ClientCountry {
    return this.client_country ?? {};
  }

  /**
   setDeviceHid(deviceHid: string) {
   this.deviceHid = deviceHid
   localStorage.setItem("KNOWN_TRUSTED_DEVICE", deviceHid);
   }

   clearDeviceHid() {
   this.deviceHid = undefined
   localStorage.removeItem("KNOWN_TRUSTED_DEVICE")
   }
   */

  private async retrieveDeviceHid(): Promise<string> {
    return Device.getId().then(r => {
      this.deviceHid = r.identifier;
      return r.identifier;
    });
    /**
     const from_storage = localStorage.getItem("KNOWN_TRUSTED_DEVICE");
     if (from_storage) {
     this.deviceHid = from_storage;
     } else {
     this.deviceHid = undefined;
     }
     return this.deviceHid
     */
  }

  refreshCache() {
    this.apollo.resetStore()
  }
}
