import {getPrayerGroupOverview, Group, PrayerGroupOverview} from "@profusion.media/profusion-core-group";
import ListItemText from "@mui/material/ListItemText";
import {Box, ListItem} from "@mui/material";
import {useNavigateDetails} from "../../hooks/navigation";
import {Await, useRouteLoaderData} from "react-router-dom";
import {LoadAllPrayerGroupOverviewReturn} from "../../GroupDetails/loaders";
import React from "react";
import {GroupAvatar} from "../../GroupDetails/components/GroupAvatar";
import {useTranslation} from "react-i18next";
import {WhiteBox} from "@components/WhiteBox";
import {TagChipGroup} from "@community/components/TagChip";
import {t} from "i18next";
import Typography from "@mui/material/Typography";

export interface GroupListItemProps {
    group: Group
    loaderRoute?: string
}

export interface GroupListItemOverviewProps {
    group: Group
    overview: PrayerGroupOverview
}

const GroupListItemOverview = (props: GroupListItemOverviewProps) => {
    const {group, overview} = props
    const {t} = useTranslation()

    if (!overview) return <ListItem>
        <ListItemText primary={group.subscribersCount} secondary={t("prayer.overview.subscribers_count", {
            defaultValue: "participants",
            count: group.subscribersCount
        })}/>
    </ListItem>

    return <ListItem>
        <ListItemText primary={overview.subscriberCount} secondary={t("prayer.overview.partners_count", {
            defaultValue: "partenaires",
            count: overview.subscriberCount
        })}/>
        {group.mainFeature === 'prayer' && <>
            <ListItemText primary={overview.requestCount} secondary={t("prayer.overview.requests_count", {
                defaultValue: "requêtes",
                count: overview.requestCount
            })}/>
            <ListItemText primary={overview.prayerCount} secondary={t("prayer.overview.prayers_count", {
                defaultValue: "prières",
                count: overview.prayerCount
            })}/>
        </>}
    </ListItem>
}

export const GroupListItem = (props: GroupListItemProps) => {
    const navigateDetails = useNavigateDetails()
    const {group, loaderRoute} = props
    const {overviews} = useRouteLoaderData(loaderRoute ?? "groupsRoot") as LoadAllPrayerGroupOverviewReturn

    const createdBy = group.createdBy ? group.createdBy.displayName : group.organizationName

    return <WhiteBox
        title={group.title}
        titleVariant="subtitle1"
        subtitle={createdBy}
        subtitleVariant="caption"
        secondaryAction={group.mainFeature === 'share' && <TagChipGroup tags={group.tags}/>}
        icon={<GroupAvatar group={group}/>}
        onClick={_ => navigateDetails(group)}
    >
        {group.mainFeature === 'share' && group.hosts.length > 0 && (
            <Box sx={{
                backgroundColor: "#fafafa",
                p: 1,
                borderRadius: 2,
            }}><Typography variant="body2" color="#777" mr={1}>
                {t("group.list.hosts_label", "Animé par:")}
            </Typography>
                <Typography variant="body2" color="#777" mr={1}>
                    {group.hosts.map(h => `${h.firstName} ${h.lastName}`).join(", ")}
                </Typography>
            </Box>
        )}
        <Await resolve={overviews}>
            {({data}) => <GroupListItemOverview group={group}
                                                overview={getPrayerGroupOverview(group, data)}/>}

        </Await>
    </WhiteBox>


}