import {gql} from "@apollo/client";

export const COMMUNITY_MEMBER_FRAGMENT = gql`
    fragment COMMUNITY_MEMBER_FRAGMENT on CommunityMember {
        __typename
        hid
        tags {
            color
            name
            tagOrder
            hid
        }
        state
        isAdmin
        dateAdded
        accessPrayer
        user {
            avatar {
                hid
                smallUrl
                mediumUrl
                largeUrl
            }
            email
            firstName
            lastName
            phoneNumber
        }
    }`