import { ProfusionClient } from "./client";
import {
  LOGIN_OTP,
  LOGIN_USER,
  LoginOTPInput,
  LoginOTPMutationResponse,
  LoginUserInput,
  LoginUserMutationResponse,
  LoginUserResponse,
  REFRESH_TOKEN,
  RefreshTokenInput,
  RefreshTokenMutation
} from "./schema";
import {
  CONNECT_TOKEN_AUTH,
  CONNECT_TOKEN_CREATE,
  CONNECT_TOKEN_UPDATE,
  ConnectTokenAuthInput,
  ConnectTokenAuthMutation,
  ConnectTokenCreateInput,
  ConnectTokenCreateMutation,
  ConnectTokenResponse,
  ConnectTokenUpdateInput,
  ConnectTokenUpdateMutation,
  ConnectTokenUpdateResponse
} from "./schema/exchangeToken";
import { MutationSsoTokenCreateArgs, SsoTokenSuccess } from "@graph";
import { SSO_TOKEN_CREATE_MUTATION } from "./schema/sso/mutations";
import { MutationResponse } from "../types";
import { setupMessaging } from "../messaging/setup_messaging";
import { Preferences } from "@capacitor/preferences";

const SESSION_JWT_STORAGE_KEY = "session_jwt_token";

export type AuthTokenPayload = {
  email: string
  exp: number
  origIAT: number
}

export type AuthToken = {
  token: string
  refreshToken: string
  refreshExpiresIn: number
  payload: AuthTokenPayload
}

export class AuthSession {
  client?: ProfusionClient;
  is_authenticated: boolean;
  private user_token?: AuthToken;

  constructor(client?: ProfusionClient) {
    if (!client) {
      this.is_authenticated = false;
      return;
    }
    this.client = client;
  }

  async init() {
    await this.retrieveTokens();
    await this.refresh()
  }

  async get_access_token() {
    if (this.user_token && this.isExpired()) {
      return await this.refresh(this.user_token.refreshToken);
    }
    return this.user_token?.token ?? ""
  }

  get_refresh_token(): string {
    return this.user_token?.refreshToken ?? "";
  }

  setAuthToken(token: AuthToken) {
    this.user_token = token ? token : undefined;
    if (this.user_token) {
      this.storeTokens(this.user_token);
      this.is_authenticated = true;
    }
  }

  login(credentials: LoginUserInput): Promise<LoginUserResponse> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<LoginUserMutationResponse, LoginUserInput>({
        mutation: LOGIN_USER,
        variables: {
          username: credentials.username,
          password: credentials.password,
          exchangeToken: credentials.exchangeToken
        }
      }
    ).then(response => {
      this.setAuthToken(response.data?.tokenAuth);
      setupMessaging(this.client);
      return Promise.resolve(response.data.tokenAuth);
    });
  }


  loginOTP(credentials: LoginOTPInput): Promise<any> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<LoginOTPMutationResponse, LoginOTPInput>({
        mutation: LOGIN_OTP,
        variables: {
          phoneNumber: credentials.phoneNumber,
          validationToken: credentials.validationToken
        }
      }
    ).then(response => {
      this.setAuthToken(response.data?.otpAuth);
      return Promise.resolve();
    });
  }

  connectTokenCreate(nextUrl: string): Promise<ConnectTokenResponse> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<ConnectTokenCreateMutation, ConnectTokenCreateInput>({
        mutation: CONNECT_TOKEN_CREATE,
        variables: {
          nextUrl: nextUrl
        }
      }
    ).then(_r => {
      if (_r.data?.connectTokenCreate) {
        return Promise.resolve(_r.data?.connectTokenCreate);
      } else {
        return Promise.reject();
      }
    });
  }

  ssoTokenCreate(nextUrl: string): Promise<SsoTokenSuccess> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<MutationResponse<"ssoTokenCreate", SsoTokenSuccess>, MutationSsoTokenCreateArgs>({
        mutation: SSO_TOKEN_CREATE_MUTATION,
        context: {
          use_private: true
        },
        variables: {
          nextUrl: nextUrl,
          refreshToken: this.get_refresh_token()
        }
      }
    ).then(_r => {
      if (_r.data?.ssoTokenCreate) {
        return Promise.resolve(_r.data.ssoTokenCreate);
      } else {
        return Promise.reject();
      }
    });
  }

  connectTokenUpdate(exchangeToken: string): Promise<ConnectTokenUpdateResponse> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<ConnectTokenUpdateMutation, ConnectTokenUpdateInput>({
        mutation: CONNECT_TOKEN_UPDATE,
        variables: {
          exchangeToken: exchangeToken,
          refreshToken: this.user_token.refreshToken
        },
        context: {
          use_private: true
        }
      }
    ).then(_r => {
      if (_r.data?.connectTokenUpdate) {
        return Promise.resolve(_r.data?.connectTokenUpdate);
      } else {
        return Promise.reject();
      }
    });
  }

  loginConnectToken(token: string): Promise<any> {
    if (!this.client) return Promise.reject();
    return this.client.apollo.mutate<ConnectTokenAuthMutation, ConnectTokenAuthInput>({
        mutation: CONNECT_TOKEN_AUTH,
        variables: {
          exchangeToken: token
        }
      }
    ).then(_r => {
      if (_r.data?.connectTokenAuth) {
        return this.refresh(_r.data.connectTokenAuth.refreshToken);
      } else {
        return Promise.reject();
      }
    });
  }

  isExpired(): boolean {
    return !this.user_token?.refreshToken || (Math.floor(Date.now() / 1000) >= this.user_token.payload.exp - 30);
  }

  refreshIfExpired(): Promise<any> {
    if (!this.client) return Promise.reject();
    if (this.isExpired()) {
      return this.refresh(this.user_token?.refreshToken);
    } else {
      return Promise.resolve();
    }
  }

  refresh(refreshToken?: string): Promise<any> {
    console.log("REFRESHING SESSION")
    if (!this.client) return this.logout();
    return this.client.apollo.mutate<RefreshTokenMutation, RefreshTokenInput>({
        mutation: REFRESH_TOKEN,
        variables: {
          refresh: refreshToken ?? this.user_token?.refreshToken
        }
      }
    ).then(response => {
      if (response.data?.refreshToken) {
        this.setAuthToken(response.data.refreshToken);
        return Promise.resolve(response.data.refreshToken.token);
      }
      return this.logout().then(r => '');
    }).catch(_ => this.logout());
  }

  async logout() {
    console.log("LOGOUT REQUEST");
    this.user_token = undefined;
    this.is_authenticated = false;
    await Preferences.remove({
      key: SESSION_JWT_STORAGE_KEY
    })
    //localStorage.removeItem(SESSION_JWT_STORAGE_KEY);
    localStorage.removeItem("FCM_PUSH_TOKEN");
    //this.client.clearDeviceHid();
    return Promise.resolve();
  }

  private storeTokens(token: AuthToken) {
    (async function f() {
      await Preferences.set({
        value: JSON.stringify(token),
        key: SESSION_JWT_STORAGE_KEY
      });
    })();
    //localStorage.setItem(SESSION_JWT_STORAGE_KEY, JSON.stringify(token));
  }

  private async retrieveTokens() {
    await Preferences.get({
      key: SESSION_JWT_STORAGE_KEY
    }).then(r => {
      try {
        const token_from_storage = JSON.parse(r.value);
        if (token_from_storage) {
          this.user_token = token_from_storage;
          this.is_authenticated = true;
        } else {
          this.is_authenticated = false;
        }
        console.log("INIT AUTH", this.user_token);
        console.log("AUTH STATE", this.is_authenticated);

      } catch (e) {
        console.log("INIT RESET, token migrated or none")
        this.is_authenticated = false;
      }
    }).catch((r: any) => {
      console.log("EXCEPTION WHEN RETRIEVING TOKEN", JSON.stringify(r))
    })

    /*
    const from_storage = localStorage.getItem(SESSION_JWT_STORAGE_KEY);
    if (from_storage) {
      return JSON.parse(from_storage);
    } else {
      return null;
    }*/
  }
}
