import {Container, ContainerProps} from "@mui/material";
import {useRef, ReactNode, useEffect, useState} from "react";
import "@styles/clippedContainer.css"

export interface IClippedContainerProps extends ContainerProps {
    isClipped?: boolean;
    height?: number;
    moreElement?: ReactNode;
}

export const ClippedContainer = (props: IClippedContainerProps) => {
    const {children, moreElement, height, isClipped, ...rest} = props;
    const ref = useRef<any>()
    const [showMore, setShowMore] = useState(false);
    const [newHeight, setNewHeight] = useState<any>(height);

    useEffect(() => {
        if (height && ref.current.scrollHeight > height) {
            setShowMore(true)
        } else{
            setNewHeight("auto")
        }
        console.log("height", height, ref.current.scrollHeight)
    }, [ref])

    return <>
        <Container {...rest} ref={ref} className={showMore ? "clippedContainer" : undefined}
                   sx={{...rest.sx, height: newHeight, overflowY: "hidden"}}
        >
            {children}
        </Container>
        {showMore && moreElement}
    </>

}