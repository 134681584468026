import {gql} from "@profusion.media/profusion-core";
import {PRAYER_REQUEST_FIELDS} from "../fragments";

export const PRAYER_REQUEST_DETAILS_QUERY = gql`
  ${PRAYER_REQUEST_FIELDS}
  query PrayerRequestDetails ($request: String!) {
    prayerRequestDetails(prayerRequest: $request) {
      ...PrayerRequestFields
    }
  }`;
