import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {PRAYER_COMMENT_CREATE_MUTATION, PRAYER_COMMENTS_QUERY, PrayerCommentCreateInput, PrayerEvent} from "../schema";
import {PRAYER_EVENT_GROUP_LIST_QUERY} from "../schema/queries/prayer_event_group_list";


export type ActionCreatePrayerCommentResponse = {
  prayerEvent: PrayerEvent
}

export const actionCreatePrayerComment = (client: ProfusionClient, prayerComment: PrayerCommentCreateInput) => {
  return executeMutation<ActionCreatePrayerCommentResponse, MutationInput<PrayerCommentCreateInput>>(client, PRAYER_COMMENT_CREATE_MUTATION,
    {
      input: {
        emotion: prayerComment.emotion ? prayerComment.emotion : undefined,
        comment: prayerComment.comment,
        requestHid: prayerComment.requestHid,
      }
    }, {
      isPrivate: true,
      refetchQueries: [{
        query: PRAYER_EVENT_GROUP_LIST_QUERY,
        context: {
          use_private: true
        },
        variables: {
          request: prayerComment.requestHid
        }
      }]
    })
}
