import { gql, DocumentNode } from '@profusion.media/profusion-core'

export const PRAYER_EVENT_FIELDS : DocumentNode = gql`
  fragment PrayerEventFields on PrayerEvent {
    hid
    dateCreated
    comment
    emotion
    requestHid
    groupHid
    eventType
    createdBy {
      hid
      displayName
      kind
      avatar {
        hid
        smallUrl
        mediumUrl
        largeUrl
      }
    }
  }
`
