import {useUserAvailableContentLoader} from "@community/loaders/userAvailableContent";
import {WhiteBox} from "@components/WhiteBox";
import {Suspense} from "react";
import {Await, useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import {IconButton, ListItem} from "@mui/material";
import {Content} from "@graph";
import ListItemText from "@mui/material/ListItemText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";
import {FullScreenDialog} from "@profusion.media/profusion-ui";

export const UserContentList = () => {
    const {content: contentData} = useUserAvailableContentLoader("appRoot")
    const navigate = useNavigate();
    const download = (c: Content) => {
        window.open(c.contentUrl, "_blank");
    }

    const handleClose = () => {
        navigate("..");
    }


    return <Suspense>
        <Await resolve={contentData}>
            {({data: content}) =>
                <FullScreenDialog
                    onClose={handleClose}
                    title="Contenus disponibles">
                    <WhiteBox title="Contenus à télécharger">
                        <List>
                            {content && content?.map((item: Content) =>
                                <ListItem key={item.hid}
                                          secondaryAction={<IconButton onClick={_ => download(item)}><FontAwesomeIcon
                                              icon={faDownload}/></IconButton>}
                                >
                                    <ListItemText primary={item.title}
                                                  secondary={item.description}/>
                                </ListItem>
                            )}
                        </List>
                    </WhiteBox>
                </FullScreenDialog>}
        </Await>
    </Suspense>
}