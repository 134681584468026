import {DocumentNode, gql} from '@profusion.media/profusion-core'

export const INVITATION_FIELDS: DocumentNode = gql`
  fragment InvitationFields on Invitation {
    ...on DetailedInvitation {
      recipientLastName
      recipientFirstName
      recipientPhoneNumber
      recipientEmail
      tryCount
      lastSentAt
      createdAt
      answeredAt
      isAccepted
      message
      language
      hid
    }
    ...on PublicInvitation {
      hid
      message
      isAccepted
      answeredAt
      createdAt
      lastSentAt
      tryCount
      message
      language
    }
    __typename
  }
`;
