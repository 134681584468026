import {IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/pro-light-svg-icons";
import profusion from "@config/profusion";

export const RefreshButton = () => {
    return <IconButton onClick={() => {
        profusion.apollo.resetStore()
    }}>
        <FontAwesomeIcon icon={faRefresh} color="white"/>
    </IconButton>
}