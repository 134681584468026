import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {Autocomplete, Box, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {SearchOrganizationResult} from "@profusion.media/graph"
import {SearchOptionValue} from "./SearchOptionValue";
import {SEARCH_QUERY} from "./search_query";

export interface SearchOrganizationInputProps {
    onChange: (value: SearchOrganizationResult) => void
}

const SearchOrganizationInput = (props: SearchOrganizationInputProps) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SearchOrganizationResult[]>([]);
    const [searchItems, {loading, data}] = useLazyQuery(SEARCH_QUERY, {
        fetchPolicy: 'no-cache',
        context: {
            use_private: true
        }
    });

    useEffect(() => {

            searchItems({
                variables: {
                    search: ""
                }
            });

    }, [searchItems]);

    useEffect(() => {
        if (data && data.searchOrganization) {
            console.log(data.searchOrganization)
            setOptions(data.searchOrganization);
        }
    }, [data]);


    return options && options.map(org => <SearchOptionValue showClear={false} value={org} onSelect={props.onChange}/>)

};

export default SearchOrganizationInput;
