import {useGraphMutation} from "@profusion.media/profusion-core";
import {
  AccountBirthdateInput,
  AccountContactInput,
  AccountLanguageInput,
  AccountNameInput,
  GET_CONNECTED_USER,
  User
} from "../schema";
import {ACCOUNT_NAME_UPDATE} from "../schema/mutations/account_name_update";
import {AddressInput} from "@profusion.media/profusion-core-geo";
import {ACCOUNT_ADDRESS_UPDATE} from "../schema/mutations/account_address_update";
import {ACCOUNT_CONTACT_UPDATE} from "../schema/mutations/account_contact_update";
import {AvatarCreateInput} from "@profusion.media/profusion-core-profile";
import {ACCOUNT_AVATAR_UPDATE} from "../schema/mutations/account_avatar_update";
import {ACCOUNT_BIRTHDATE_UPDATE} from "../schema/mutations/account_birthdate_update";
import {ACCOUNT_LANGUAGE_UPDATE} from "../schema/mutations/account_language_update";

export const useAccountNameUpdate = () => {
  return useGraphMutation<User, AccountNameInput>(
    ACCOUNT_NAME_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    });
};

export const useAccountAddressUpdate = () => {
  return useGraphMutation<User, AddressInput>(
    ACCOUNT_ADDRESS_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    });
};

export const useAccountContactUpdate = () => {
  return useGraphMutation<User, AccountContactInput>(
    ACCOUNT_CONTACT_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    });
};


export const useAccountAvatarUpdate = () => {
  return useGraphMutation<User, AvatarCreateInput>(
    ACCOUNT_AVATAR_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    });
};

export const useAccountBirthdateUpdate = () => {
  return useGraphMutation<User, AccountBirthdateInput>(
    ACCOUNT_BIRTHDATE_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    });
};

export const useAccountLanguageUpdate = () => {
  return useGraphMutation<User, AccountLanguageInput>(
    ACCOUNT_LANGUAGE_UPDATE, {
      isPrivate: true,
      refetchQueries: [{
        query: GET_CONNECTED_USER, context: {
          use_private: true
        }
      }]
    }
  )
}
