import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {ALL_PRAYER_REQUEST_OVERVIEW_QUERY, PrayerRequestOverview} from "../schema";
import {PRAYER_REQUEST_OVERVIEW_QUERY} from "../schema/queries/prayer_request_overview";

export const loadPrayerRequestOverview = (client: ProfusionClient, requestHid: string): GraphQueryPromise<PrayerRequestOverview> => {
  return executeQuery<PrayerRequestOverview>(client, PRAYER_REQUEST_OVERVIEW_QUERY, {
    isPrivate: true,
    variables: {
      request: requestHid
    }
  })
}

export const loadAllPrayerRequestOverview = (client: ProfusionClient, groupHid: string): GraphQueryPromise<PrayerRequestOverview[]> => {
  return executeQuery<PrayerRequestOverview[]>(client, ALL_PRAYER_REQUEST_OVERVIEW_QUERY, {
    isPrivate: true,
    variables: {
      groupHid: groupHid
    }
  })
}
