import React, {useContext, useEffect, useState} from "react";
import {ConnectedUserProps, ConnectedUserProviderProps, defaultConnectedUserProps} from "./typings";
import {
  executeQuery,
  Language,
  ProfusionContext,
  useAuthentication,
  useProfusionClient
} from "@profusion.media/profusion-core";
import {GET_CONNECTED_USER, User} from "../users/schema";


export const ConnectedUserContext: React.Context<ConnectedUserProps> = React.createContext<ConnectedUserProps>({
  ...defaultConnectedUserProps,
  roles: []
});

export const ConnectedUserProvider = (props: ConnectedUserProviderProps) => {
  const {is_authenticated, auth_ready} = useAuthentication()
  const client = useProfusionClient()
  const {setLanguage} = useContext(ProfusionContext)
  const [values, setValues] = useState({
    isConnected: is_authenticated,
    user: undefined,
    roles: [],
    loading: true,
  })

  useEffect(() => {
    if (!auth_ready) return
    if (is_authenticated) {
      (async function () {
        try {
          const {data} = await executeQuery<User>(client, GET_CONNECTED_USER, {isPrivate: true})
          console.log("UPDATE USER", values)
          setValues(prev => ({...prev, loading: false, user: data}));
          setLanguage((data.language ?? 'fr-FR') as Language)
        } catch (e) {
          console.log("GET USER EXCEPTION", JSON.stringify(e));
          if (props.onSignOut) props.onSignOut()
        }
      })()
      const watcher = client.apollo.watchQuery({
        query: GET_CONNECTED_USER,
        context: {
          use_private: true,
        }
      })

      watcher.subscribe(result => {
        setValues(prev => ({...prev, user: result.data.getConnectedUser}));
        setLanguage(result.data.getConnectedUser.language ?? 'fr-FR');
      })
    } else {
      setValues(prev => ({...prev, loading: false}));
    }
  }, [auth_ready]);


  if (values.loading && props.waitElement)
    return props.waitElement

  return <ConnectedUserContext.Provider value={values}>
    {props.children}
  </ConnectedUserContext.Provider>
}


export const useConnectedUser = () => {
  return useContext<ConnectedUserProps>(ConnectedUserContext).user
}
