import {gql} from "@profusion.media/profusion-core";

export const NOTIFICATION_SETTINGS_FIELDS = gql`
  fragment NotificationSettingsFields on NotificationSettings {
    hid
    enableNotificationEmail
    enableNotificationPush
    enableNotificationSms
    notificationFrequency
  }
`
