import {useGraphQuery} from "@profusion.media/profusion-core";
import {
  Group,
  GROUP_DETAILS,
  GROUP_LIST_ALL_QUERY,
  GROUP_LIST_MINE_QUERY,
  GROUP_LIST_SHARED_QUERY,
  GroupOrigin
} from "../schema";
import {GroupDetails} from "@graph";


export const useGroupListMine = () => {
  return useGraphQuery<Group[]>(
    GROUP_LIST_MINE_QUERY, {
      isPrivate: true,
    });
};
export const useGroupListShared = () => {
  return useGraphQuery<Group[]>(
    GROUP_LIST_SHARED_QUERY, {
      isPrivate: true,
    });
};

export const useGroupList = (what: GroupOrigin = "all") => {
  const query =
    what === "shared" ? GROUP_LIST_SHARED_QUERY :
      what === "mine" ? GROUP_LIST_MINE_QUERY :
        GROUP_LIST_ALL_QUERY
  return useGraphQuery<Group[]>(
    query, {
      isPrivate: true,
    });
}

export const useGroupDetails = (group: string) => {
  return useGraphQuery<Group, { group: string }>(
    GROUP_DETAILS, {
      variables: {
        group
      },
      isPrivate: true,
      skip: !group
    }
  )
}


export const useGetGroupDetails = () => {
    return (group: string) => useGroupDetails(group)
}