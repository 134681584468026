import React, {ReactNode} from "react";
import {faQuestionCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButtonProps} from "@mui/material";

export type HelpButtonPosition = "end" | "start" | "inline"

export interface HelpButtonProps {
    visible?: boolean
    icon?: ReactNode
    color?: IconButtonProps["color"]
    content: ReactNode
    position?: HelpButtonPosition
}

export const helpButtonPropsDefaults : Partial<HelpButtonProps> = {
    icon: <FontAwesomeIcon icon={faQuestionCircle}/>,
    color: "primary",
    visible: true,
    position: "inline"
}