import {gql, VALIDATION_ERROR_FRAGMENT} from "@profusion.media/profusion-core";
import {ORGANIZATION_FIELDS} from "../fragments/organization";

export const ORGANIZATION_CREATE_MUTATION = gql`
    ${ORGANIZATION_FIELDS}
    ${VALIDATION_ERROR_FRAGMENT}
    mutation organizationCreate($organization: OrganizationCreateInput!, $parent: String, $avatar: AvatarCreateInput) {
        createOrganization(organization: $organization, parent: $parent, avatar: $avatar) {
             organization {
                 ...organizationFields
             }
            errors {
                ...ValidationError
            }            
        }
    }`;
