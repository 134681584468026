import React, {createContext} from "react"
import {Box, useTheme} from "@mui/material";
import {FormGroupError, FormGroupProps} from "../typings"
import {useFormGroupErrors} from "../hooks";

export const FormGroup = (props: FormGroupProps) => {
    const errors = useFormGroupErrors(props.name)
    const theme = useTheme()
    return <Box>
        <FormGroupContextProvider errors={errors}>
            {props.children}
        </FormGroupContextProvider>
    </Box>
}

export interface FormGroupContextProps {
    errors?: Array<FormGroupError>
}

export const FormGroupContext = createContext<FormGroupContextProps>({})

interface FormGroupContextProviderProps extends FormGroupContextProps {
    children: React.ReactNode
}

export const FormGroupContextProvider = (props: FormGroupContextProviderProps) => {
    const {children, ...contextProps} = props
    return <FormGroupContext.Provider value={contextProps}>
        {children}
    </FormGroupContext.Provider>
}