import React from "react"
import {ProvinceSelectInputDefaultProps, ProvinceSelectInputProps} from "./typings";
import {getCountryByAlpha2} from "@profusion.media/profusion-core-geo";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";


export const ProvinceSelectInput = (props: ProvinceSelectInputProps) => {
    const {country, value, label, onChange, hideIfEmpty, required} = {...ProvinceSelectInputDefaultProps, ...props}
    const provinces = getCountryByAlpha2(country)?.provinces ?? []
    if (hideIfEmpty && !provinces.length) {
        return <></>
    }

    return <FormControl>
        <InputLabel id="_province_label">{label}</InputLabel>
        <Select value={value?.toString() ?? ""}
                labelId="province_label"
                onChange={e => onChange(e.target.value)}
                label={label}
                required={required}
                sx={{minWidth: "120px"}}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 48 * 10.5,
                        }
                    }
                }}
                variant="outlined"
                autoWidth>
            {provinces.map((_c) => <MenuItem value={_c.code}
                                             key={_c.code}
                                             selected={_c.code === value}>
                {_c.name}
            </MenuItem>)}
        </Select>
    </FormControl>
}

