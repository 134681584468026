import {ActionFunctionArgs, redirect} from "react-router-dom"
import {MutationCreateOrganizationArgs, Organization, OrganizationCreate,} from "@profusion.media/graph";
import {organizationUpdate} from "@organizations/actions/organization_edit";
import {executeMutation} from "@profusion.media/profusion-core";
import profusion from "@config/profusion";
import {ORGANIZATION_CREATE_MUTATION} from "@organizations/schema/mutations/organization_create_mutation";

export const actionOrganizationCreate = async (props: ActionFunctionArgs) => {
    console.log(props)
    const data = await props.request.json() as MutationCreateOrganizationArgs

    console.log(data, "SUBMITTED")

    const response = await executeMutation<OrganizationCreate, MutationCreateOrganizationArgs>(
        profusion,
        ORGANIZATION_CREATE_MUTATION,
        data,
        {
            isPrivate: true,
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return redirect(`/su/organizations/${response.data.organization.hid}`)
}
export const actionOrganizationUpdate =  () => {
    return organizationUpdate((organization: Organization) => {
        return redirect(`/su/organizations/${organization.hid}`)
    })

}