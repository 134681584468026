import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    CancelButton,
    DateInput,
    DateValue,
    Form,
    FormErrorBox,
    FullScreenDialog,
    HeadsUp,
    SubmitButton,
} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {AccountBirthdateInput, useAccountBirthdateUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {faShieldAlt} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";

const FormBirthdate: React.FC = () => {
    const user = useConnectedUser()

    const {t} = useTranslation()
    const navigate = useNavigate();
    const dateParsed = user?.dateOfBirth ? dayjs(user.dateOfBirth) : dayjs()

    const [fields, setFields] = useState<AccountBirthdateInput>({
        day: dateParsed.date() ?? 1,
        month: dateParsed.month() + 1,
        year: dateParsed.year() ?? 1940
    })

    const [update, {loading: updating, data, validationErrors: errors}] = useAccountBirthdateUpdate()

    useEffect(() => {
        if (user) {
            const dateParsed = user?.dateOfBirth ? dayjs(user.dateOfBirth) : dayjs()

            setFields({
                day: dateParsed.date() ?? 1,
                month: dateParsed.month() + 1,
                year: dateParsed.year() ?? 1940
            })
        }
    }, [user])

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        const r = await update({input: fields})
        if (!r?.validationErrors) {
            closeDialog()
        }
    };

    const handleCancel = () => {
        // Logique d'annulation ici
        setFields({
            day: dateParsed.date() ?? 1,
            month: dateParsed.month() + 1,
            year: dateParsed.year() ?? 1940
        });
        closeDialog()
    };


    return (
        <FullScreenDialog title="Date d'anniversaire"  onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={updating}
                  autoClose>
                <FormErrorBox message="Des erreurs sont survenues"/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <DateInput value={fields} onChange={(v: DateValue) => setFields(v)}/>
                        </Stack>

                        <HeadsUp sx={{marginTop:4}}>
                            <FontAwesomeIcon icon={faShieldAlt}/>
                            <Typography>Seuls les administrateurs de Profusion.media et de votre église peuvent voir
                                cette information.</Typography>
                        </HeadsUp>
                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton label='Annuler'/>
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"
                            label="Enregistrer"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormBirthdate;
