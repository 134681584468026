import React from "react"
import {FormErrorBoxProps} from "../typings";
import {Alert} from "@mui/material";
import {useFormErrors} from "../hooks";
import {useTranslation} from "react-i18next";

export const FormErrorBox = (props: FormErrorBoxProps) => {
    const errors = useFormErrors()
    const {t} = useTranslation()
    if (errors.hasErrors) {
        return <Alert variant="filled" severity="error">
            {props.message ?? t("common.forms.message.errors", "Le formulaire contient des erreurs")}
        </Alert>
    } else {
        return <></>
    }
}