import { gql } from "@apollo/client";

export const SSO_TOKEN_CREATE_MUTATION = gql`
  mutation SsoTokenCreate($refreshToken: String!,$nextUrl: String!) {
    ssoTokenCreate(nextUrl: $nextUrl, refreshToken: $refreshToken) {
      returnUrl
      token
    }
  }
`
