import {MutationPasswordResetArgs} from "@profusion.media/graph";
import {executeMutation, ProfusionClient} from "@profusion.media/profusion-core";
import {ACCOUNT_PASSWORD_RESET_REQUEST} from "../schema/mutations/account_password_reset_request";
import {PasswordResetResponse} from "../schema";


export const actionResetPassword = (client: ProfusionClient,emailOrPhone: string) => {
  return executeMutation<PasswordResetResponse, MutationPasswordResetArgs>(client, ACCOUNT_PASSWORD_RESET_REQUEST,
    {
      emailOrPhone: emailOrPhone
    }, {
      isPrivate: false,
    })
}
