import React, {useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useActionData, useNavigate, useNavigation, useSubmit} from 'react-router-dom';
import {
    CancelButton, FieldErrorBox,
    Form,
    FormErrorBox,
    FullScreenDialog,
    PasswordInput,
    SubmitButton
} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {PasswordUpdateInput} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import {PasswordUpdateActionData} from "../../actions/password_update";

const FormPassword: React.FC = () => {
    const {t} = useTranslation()
    const [fields, setFields] = useState<PasswordUpdateInput>({
        confirmPassword: "",
        newPassword: "",
        oldPassword: "",
    })
    const navigate = useNavigate()
    const navigation = useNavigation()

    const loading = navigation.state === 'submitting'
    const submit = useSubmit()
    const {errors, success} = useActionData() as PasswordUpdateActionData ?? {errors: [], success: undefined}

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        submit(fields, {
            method: "post",
            encType: "application/json"
        })
        if (success) closeDialog()
    };

    const handleCancel = () => {
        // Logique d'annulation ici
        setFields({
            confirmPassword: "",
            newPassword: "",
            oldPassword: "",
        });
        closeDialog()
    };


    return (
        <FullScreenDialog title="Changement de mot de passe " onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={loading}
                  autoClose>
                <FormErrorBox message="Des erreurs sont survenues"/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <PasswordInput value={fields.oldPassword}
                                           name="oldPassword"
                                           label={t("account.password.old", "Mot de passe actuel")}
                                           onValueChange={handleValueChange}/>

                            <FieldErrorBox field="oldPassword" errorCode="PasswordIncorrectError">
                                {t('account.password.errors.old_incorrect', "Mot de passe incorrect")}
                            </FieldErrorBox>

                            <PasswordInput value={fields.newPassword}
                                           name="newPassword"
                                           label={t("account.password.new", "Nouveau mot de passe")}
                                           onValueChange={handleValueChange}/>
                            <FieldErrorBox field="newPassword" errorCode="PasswordFormatError">
                                {t('account.password.errors.new_format', "Mauvais format")}
                            </FieldErrorBox>
                            <PasswordInput value={fields.confirmPassword}
                                           name="confirmPassword"
                                           label={t("account.password.confirm", "Confirmation")}
                                           onValueChange={handleValueChange}/>
                            <FieldErrorBox field="confirmPassword" errorCode="PasswordConfirmationError">
                                {t('account.password.errors.confirm_match', "La confirmation de ne correspond pas")}
                            </FieldErrorBox>
                        </Stack>

                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton label='Annuler'/>
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"
                            label="Mettre à jour"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormPassword;
