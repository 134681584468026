import React from "react"
import {Avatar, Box, Button, ButtonGroup, IconButton, Stack} from "@mui/material";
import {ImageUploaderDialog, UploadedImage} from "../ImageUploader";
import {AvatarEditorLabels, AvatarEditorProps} from "./typings";
import Typography from "@mui/material/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenCircle, faPlusCircle, faTimesCircle} from "@fortawesome/pro-solid-svg-icons";

const defaultLabels: AvatarEditorLabels = {
    addButton: "Ajouter",
    modifyButton: "Modifier",
    deleteButton: "Supprimer",
    dialogTitle: "Choisir une image"
}

const defaultProps: Partial<AvatarEditorProps> = {
    folder: "users",
    shape: "rounded",
    prefix: "usr",
    labels: defaultLabels,
    defaultImage: "https://ik.imagekit.io/profusion/Static_files/877592470d87109eeb659aa91e750039.jpg?updatedAt=1684865194773"
}

export const AvatarEditor = (props: AvatarEditorProps) => {
    const {image, shape, description,folder, prefix, onChange, onDelete, defaultImage, labels} = {...defaultProps, ...props}
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)

    const hasImage = typeof image !== "undefined" && image !== "" && image !== defaultImage

    console.log("AVATAR", defaultImage, image)
    const handleModify = () => {
        setOpenDialog(true)
    }

    const handleDelete = () => {
        onDelete()
    }

    const handleUploaded = (uploaded: UploadedImage) => {
        onChange(uploaded)
        setOpenDialog(false)
    }

    function closeDialog() {
        setOpenDialog(false)
    }

    return <Box>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap:2}}>

            <Avatar sx={{width: 64, height: 64}}
                    src={hasImage ? image : defaultImage} alt="avatar">
            </Avatar>
            {description && <Box><Typography variant="caption" component="div">
                {description}
            </Typography></Box>}
            <Stack direction="column" spacing={0}>
            <IconButton
                size="large"
                color={"primary"}
                onClick={_ => handleModify()}>
                <FontAwesomeIcon icon={faPenCircle} />
            </IconButton>
            {hasImage &&
                <IconButton
                    size="large"
                    color="error"
                    onClick={_ => handleDelete()}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>
            }
            </Stack>
        </Box>
        <ImageUploaderDialog open={openDialog}
                             onUploaded={handleUploaded}
                             onCancel={closeDialog}
                             image={image}
                             folder={folder}
                             shape={shape}
                             title={labels.dialogTitle}
                             filePrefix={prefix}
        />
    </Box>
}