import {useContext} from "react";
import { ProfusionContext } from "../contexts";
import { ClientCountry } from "../schema";
import { ApolloClient } from "@apollo/client";
import { AuthSession } from "../session";


export const useAppReady = () : boolean => {
    const appClient = useContext(ProfusionContext)
    return appClient.app_ready
}

export const useUserSession = () => {
  const appClient = useContext(ProfusionContext)
  return appClient.client.session
}

export const useClientCountry = () : ClientCountry => {
  const appClient = useContext(ProfusionContext)
  return appClient.client.getClientCountry()
}

export const useGraphClient = () : ApolloClient<any> => {
  const appClient = useContext(ProfusionContext)
  return appClient.client.apollo
}

export const useProfusionSession = () : AuthSession => {
  const appClient = useContext(ProfusionContext)
  return appClient.client.session
}
