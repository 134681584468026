import React, {createContext, useContext, useEffect, useState} from "react";
import {FormFieldProviderProps} from "./typings";
import {ValidationErrors} from "@profusion.media/profusion-core";

export type FormField = {
    isValid: boolean
    isError: boolean
    errorCode?: string
    group?: string
    type?: string
    name?: string
    value?: any
    tags?: Array<string>
}

export type FormFieldStore = {
    [FNAME in string]: FormField
}

export interface FormFieldContextProps {
    fields: FormFieldStore
    isError: boolean
    onChange: (field: FormField) => void
}

export const FormFieldContext = createContext<FormFieldContextProps>({
    fields: {},
    isError: false,
    onChange: field => {    }
})

export const useFormFieldContext = () => useContext(FormFieldContext)

export const FormFieldProvider = (props: FormFieldProviderProps) => {
    const [fields, setFields] = useState<FormFieldStore>()
    const [isError, setIsError] = useState<boolean>(false)
    const [cycle, setCycle] = useState<number>(0)

    const cloneAndPreserveError = (field: FormField) => {
        let clone = {...field}
        const localField = fields[field.name]
        if (localField) {
            clone.isError = localField.isError
            clone.errorCode = localField.errorCode
        }
        return clone
    }

    const onChange = (field: FormField) => {
        if (!field) return
        setFields(prev => {
            return {...prev, [field.name]: cloneAndPreserveError(field)}
        })
    }

    const updateErrors = (errors: ValidationErrors) => {
        let updatedFields = {}
        const fieldNames = Object.keys(fields)
        fieldNames.map(n => {
            updatedFields[n] = {...fields[n], isError: false, errorCode: undefined}
        })
        if (errors && errors.map) {
            errors.map(e => {
                if (fields[e.field]) {
                    updatedFields[e.field] = {...fields[e.field], isError: true, errorCode: e.code}
                }
            })
        }

        setFields(updatedFields)
        setIsError(!!errors)
    }

    useEffect(() => {
        setFields({})
    }, [])

    useEffect(() => {
        if (fields) {
            updateErrors(props.errors)
        }
    }, [props.errors]);

    if (!fields) return <></>

    return <FormFieldContext.Provider
        value={{
            fields,
            isError,
            onChange
        }}>
        {props.children}
    </FormFieldContext.Provider>
}