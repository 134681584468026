import profusion from "../../../config/profusion"
import {ActionFunctionArgs, redirect} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {AttachAvatarInput, MutationUpdateAvatarOrganizationArgs, OrganizationAvatarUpdateOutput} from "@graph";
import {ORGANIZATION_AVATAR_UPDATE_MUTATION} from "@organizations/schema/mutations/organization_avatar_update_mutation";

export const actionOrganizationAvatarUpdate = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as AttachAvatarInput

    const response = await executeMutation<OrganizationAvatarUpdateOutput, MutationUpdateAvatarOrganizationArgs>(
        profusion,
        ORGANIZATION_AVATAR_UPDATE_MUTATION,
        {input: data},
        {
            isPrivate: true
        })

    switch (response?.data?.__typename) {
        case "Organization":
            return redirect(`/su/organizations/${response.data?.hid}`)
        case "ValidationErrors":
            return response.data
    }
}
