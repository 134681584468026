import {styled} from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledSettingsListItem = styled('div')(({theme}) => ({
    padding: theme.spacing(1),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    '&:hover': {
        backgroundColor: theme.palette.action.hover
    },
}))
export const StyledChevronIcon = styled(ChevronRightIcon)(({theme}) => ({
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.secondary,
}));