import React from 'react';
import { TimeAgoProps } from './typings';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

export const TimeAgo: React.FC<TimeAgoProps> = ({ date, container: Container = 'div' }) => {
    const parsedDate = new Date(date);
    try {
        const relativeDate = formatDistanceToNow(parsedDate, {addSuffix: true, locale: fr});

        const ContainerComponent = Container as React.ElementType;

        return <ContainerComponent>{relativeDate}</ContainerComponent>;
    } catch (e) {
        return <></>
    }
};