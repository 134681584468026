import {gql} from "@profusion.media/profusion-core";
import {COMMUNITY_FRAGMENT} from "@community/schema/fragments";

export const COMMUNITY_JOIN_MUTATION = gql`
    ${COMMUNITY_FRAGMENT}
    mutation communityJoin($input: CommunityJoinInput!) {
        communityJoin(input: $input) {
            success
            message
            community {
                ...COMMUNITY_FRAGMENT    
            }
        }
    }`;
