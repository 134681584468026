import {gql} from "@profusion.media/profusion-core";

export const ACCOUNT_REGISTER = gql`
    mutation($input: UserRegistrationInput!) {
        accountRegister(input: $input) {
            token {
                accessToken
                refreshToken
                next
            }
            errors {
                code
                field
            }
        }
    }`;
