import React from "react"
import {useFormUpdating, useOnCancel, useOnSubmit} from "../hooks";
import {FormButton} from "./FormButton";
import {Check} from "@mui/icons-material";
import {CancelButtonProps} from "../typings";
import {useTranslation} from "react-i18next";

export const CancelButton = (props: CancelButtonProps) => {
    const handler = useOnCancel()
    const updating = useFormUpdating()
    const {t} = useTranslation()
    return <FormButton handler={handler}
                       label={props.label ?? t("common.forms.btn.cancel", "Annuler")}
                       icon={props.icon ?? <Check/>}
                       iconPosition={props.iconPosition}
                       disabled={updating}
                       color="inherit" />
}