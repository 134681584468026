import React from "react";
import {ButtonProps} from "@mui/material";
import {IconPosition} from "../typings";
import {ValidationErrors} from "@profusion.media/profusion-core"

export interface FormProps {
    children: React.ReactNode
    loading?: boolean
    updating?: boolean
    canSubmit?: boolean
    errors?: ValidationErrors
    onSubmit: () => void
    onCancel: () => void
    autoClose?: boolean
}

export interface FormButtonBaseProps {
    label?: React.ReactNode
    icon?: ButtonProps["startIcon"]
    iconPosition?: IconPosition
    disabled?: boolean
}

export interface FormButtonProps extends FormButtonBaseProps {
    handler: () => void
    color?: ButtonProps["color"]
    variant?: ButtonProps["variant"]

}

export interface SubmitButtonProps extends FormButtonBaseProps {
    activityIcon?: FormButtonBaseProps["icon"]
}
import {RefreshRounded, Check} from "@mui/icons-material";

export const SUBMIT_BUTTON_DEFAULT_PROPS : SubmitButtonProps = {
    iconPosition: "left",
    activityIcon: <RefreshRounded />,
    icon: <Check/>,
}

export interface CancelButtonProps extends FormButtonBaseProps {
}

export type FormFieldError = {
    field: string
    isError: boolean
    code?: string
}

export type FormGroupError = {
    group: string
    isError: boolean
    code?: string
}

export type FormErrors = {
    hasErrors: boolean,
    errors?: ValidationErrors
}

export interface FormErrorBoxProps {
    message?: string
}

export interface FormGroupProps {
    children: React.ReactNode
    name?: string
}

export interface FormGroupErrorBoxProps {
    text?: string
    children?: React.ReactNode
    errorCode: string
}