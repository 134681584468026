import {useLocation, useNavigate} from "react-router-dom";
import {faPrayingHands, faRectangleHistoryCircleUser, faUserGroup} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {Community} from "@graph";
import {faGears} from "@fortawesome/pro-regular-svg-icons";
import {useTabIndex} from "@groups/Groups/GroupDetails/hooks";

export interface CommunityAdminBottomNavProps {
    community: Community
}

export const CommunityAdminBottomNavigation = (props: CommunityAdminBottomNavProps) => {
    const {community} = props

    const currentTab = parseInt(useTabIndex() || "0")
    const [value, setValue] = React.useState(currentTab ?? 0);
    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation()
    const paths = [
        `/community/admin/${community.hid}/members`,
        `/community/admin/${community.hid}/groups/share`,
        `/community/admin/${community.hid}/groups/prayer`,
        `/community/admin/${community.hid}/settings`,
    ]

    const navigateTo = (idx: number) => {
        navigate(paths[idx], {
            state: {from: location.pathname}
        })
    }

    return <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
            navigateTo(newValue)
        }}
        className="bottom-navigation"
    >
        <BottomNavigationAction label={t("community.menu.members", "Communauté")}
                                icon={<FontAwesomeIcon icon={faUserGroup} fixedWidth/>}/>
        <BottomNavigationAction label={t("community.menu.groups", "Groupes")}
                                icon={<FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}/>
        <BottomNavigationAction label={t("community.menu.prayer", "Prière")}
                                icon={<FontAwesomeIcon icon={faPrayingHands} fixedWidth/>}/>
        <BottomNavigationAction label={t("community.menu.settings", "Paramètres")}
                                icon={<FontAwesomeIcon icon={faGears} fixedWidth/>}/>

    </BottomNavigation>
}