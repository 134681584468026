import {useCommunityMembersLoader} from "@community/loaders/communityMembersLoader";
import {Await, useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import {Box, Button, Divider, ListItem, ListItemAvatar, ListItemSecondaryAction, Stack} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import {CommunityMember, CommunityTag} from "@graph";
import Typography from "@mui/material/Typography";
import {faCrown} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {CommunityMemberBottomSheet} from "@community/screens/members/drawer/CommunityMemberBottomSheet";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {WhiteBox} from "@components/WhiteBox";
import {useTranslation} from "react-i18next";
import {faUsers} from "@fortawesome/pro-solid-svg-icons";
import {TagChip} from "@community/components/TagChip";
import {CommunityMemberListFilter} from "@community/screens/members/CommunityMemberListFilter";

const CommunityMemberListItemTitle = (props: CommunityMember) => {
    return (props.user &&
        <Typography>{`${props.user.firstName} ${props.user.lastName}`}
            {props.isAdmin && <FontAwesomeIcon icon={faCrown}/>}
        </Typography>)
}

const CommunityMemberListItem = (props: { member: CommunityMember, onSelect: (member: CommunityMember) => void }) => {
    const {member, onSelect} = props
    return (member.user &&
        <ListItem key={member.user.email}
                  onClick={_ => onSelect(member)}
        >
            <ListItemAvatar>
                <UserAvatar avatar={member.user.avatar?.mediumUrl} userName={member.user.firstName}/>
            </ListItemAvatar>
            <ListItemText primary={<CommunityMemberListItemTitle {...member} />}
                          secondary={member.tags && member.tags.length > 0 && member.tags.map(t => t && <TagChip tag={t}/>)}/>

        </ListItem>
    );
}


export const CommunityMembersList = () => {
    const {members} = useCommunityMembersLoader()
    const {community} = useCommunityDetailsLoader("communityDetailsAdmin")
    const [selectedMember, setSelectedMember] = useState<string>();
    const {t} = useTranslation();
    const [filters, setFilters] = useState<CommunityTag>()

    const handleSelectMember = (member: CommunityMember) => {
        setSelectedMember(member.hid)
    }

    const handleCloseMemberDrawer = () => {
        setSelectedMember(undefined)
    }

    const filter = (member: CommunityMember) => {
        if (!filters) return true
        if (!filters.hid) return !member.tags || member.tags.length == 0

        return member.tags?.find(t => t && t.hid == filters.hid)
    }


    return <Await resolve={members}>
        {({data: allMembers}) =>
            <Await resolve={community}>
                {({data: community}) =>
                    <WhiteBox
                        secondaryAction={<Box display="flex" flexDirection="row" alignItems="center" alignSelf="end">
                            <CommunityMemberListFilter community={community} filter={filters} onChange={setFilters}/>
                        </Box>}
                        icon={<FontAwesomeIcon icon={faUsers}/>}
                        title={t("community.members.list.title", "{{count}} participants", {count: allMembers.length})}>


                        <List dense subheader={filters && <>
                            <ListItem>
                                <ListItemText primary={`Filtré par :${filters.name}`}/>
                            </ListItem>
                            <Divider component="li"/>
                        </>}>
                            {allMembers.map((member: CommunityMember, k: any) => filter(member) && <CommunityMemberListItem
                                key={k}
                                member={member}
                                onSelect={handleSelectMember}
                            />)}
                        </List>
                        <CommunityMemberBottomSheet
                            member={allMembers.find((m: CommunityMember) => m.hid === selectedMember)}
                            open={!!selectedMember}
                            community={community}
                            onClose={handleCloseMemberDrawer}/>
                    </WhiteBox>}
            </Await>}
    </Await>
}