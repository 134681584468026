import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate} from "react-router-dom";
import {Suspense, useEffect} from "react";
import {Group} from "@graph";
import {PeopleSearchOption} from "./options/PeopleSearch";
import {ShareLinkOption} from "./options/ShareLink";
import {useGroupDetailsLoader} from "../loaders";
import {GroupAddMembers} from "@community/screens/groups/details/GroupAddMembers";

interface RedirectIfRequiredProps {
    group: Group;
}

export const RedirectIfRequired = (props: RedirectIfRequiredProps) => {
    const {group} = props
    const navigate = useNavigate()

    useEffect(() => {
        if (group.userRole?.isCommunityAdmin) {
            navigate("../community")
        }
    }, []);

    return <></>
}


export const GroupInvitation = () => {
    const {group, subscribers} = useGroupDetailsLoader("groupDetails")
    const navigate = useNavigate()


    const getUrl = (g: Group) => {
        return `https://app.profusion.media/groups/${g.mainFeature}/${g.hid}`
    }

    const onClose = () => {
        navigate(-1)
    }


    return <Suspense>
        <Await resolve={group}>
            {({data}) => data.userRole?.isCommunityAdmin
                ? <RedirectIfRequired group={data} />
                :<FullScreenDialog title="Inviter un ami"
                                  subtitle={data.name}
                                  onClose={onClose}>


                    <ShareLinkOption group={data} url={getUrl(data)}/>
                </FullScreenDialog>
            }
        </Await>

    </Suspense>
}