import {Await, useRouteLoaderData} from "react-router-dom";
import {Box, IconButton, ListItem} from "@mui/material";
import {LoadDetailsReturn} from "../../loaders";
import React, {Suspense} from "react";
import {useTranslation} from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {Group} from "@profusion.media/profusion-core-group";
import {CommunityTag, Content} from "@graph";
import {TagChipGroup} from "@community/components/TagChip";
import Typography from "@mui/material/Typography";
import {WhiteBox} from "@components/WhiteBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";

export const GroupContent = () => {
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {t} = useTranslation()

    const download = (c: Content) => {
        window.open(c.contentUrl, "_blank");
    }


    return <Suspense>
        <Await resolve={groupData}>
            {({data: group}: { data: Group }) => <WhiteBox>
                <List>
                    {group.attachments?.map(c => c &&

                        <ListItem key={c.hid}
                                  secondaryAction={<IconButton onClick={_ => download(c)}><FontAwesomeIcon icon={faDownload}/></IconButton>}
                        >
                            <ListItemText primary={c.title}
                                          secondary={<Box>
                                              {c.tags && <TagChipGroup tags={c.tags as CommunityTag[]}/>}
                                              <Typography variant="body2">{c.description}</Typography>
                                          </Box>}/>
                        </ListItem>)}
                </List>
            </WhiteBox>
            }

        </Await>
    </Suspense>
}