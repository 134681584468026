import {executeMutation} from "@profusion.media/profusion-core";
import {MutationCommunityGroupAddMembersArgs, Subscriber} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_GROUP_ADD_MEMBERS_MUTATION} from "@community/schema/mutations/community_group_add_member";
import {GROUP_DETAILS, GROUP_SUBSCRIBER_LIST} from "@profusion.media/profusion-core-group";


export const useCommunityGroupMembers = (groupHid: string) => {
    return {
        addMembers: (members: string[]) => {
            return executeMutation<Subscriber[], MutationCommunityGroupAddMembersArgs>(
                profusion,
                COMMUNITY_GROUP_ADD_MEMBERS_MUTATION,
                {
                    input: {
                        groupHid,
                        members
                    }
                },
                {
                    isPrivate: true,
                    refetchQueries: [
                        {
                            query: GROUP_DETAILS,
                            variables: {
                                group: groupHid,
                            },
                            context: {
                                use_private: true
                            }
                        },
                        {
                            query: GROUP_SUBSCRIBER_LIST,
                            variables: {
                                group: groupHid
                            },
                            context: {
                                use_private: true
                            }
                        }
                    ]
                })
        }
    }
}