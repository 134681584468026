import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {Group, GroupJoinInput, MutationGroupJoinArgs} from "@graph";
import profusion from "@config/profusion";
import {GROUP_JOIN_MUTATION} from "@profusion.media/profusion-core-group/groups/schema/mutations/group_join_mutation";
import {gql} from "@apollo/client";
import {ALL_GROUP_OVERVIEW_QUERY} from "@profusion.media/profusion-core-group";

export const actionGroupJoin = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as GroupJoinInput

    const response = await executeMutation<Group, MutationGroupJoinArgs>(
        profusion,
        GROUP_JOIN_MUTATION,
        {input: data},
        {
            isPrivate: true,
            refetchQueries: [{
                query: ALL_GROUP_OVERVIEW_QUERY,
                context: {
                    use_private: true,
                }
            }]
        })
    return response.data

}
