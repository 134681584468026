import { gql } from "@apollo/client";
import { AuthToken } from "../../session";
import { BaseType, MutationResponse } from "../../../types";


export type LoginUserResponse = BaseType<"TokenAuth"> & AuthToken & {
  redirectUrl?: string
}

export type LoginUserMutationResponse = MutationResponse<"tokenAuth", LoginUserResponse>
export type LoginOTPMutationResponse = MutationResponse<"otpAuth", LoginUserResponse>

export type LoginUserInput = {
  username: string
  password: string
  exchangeToken?: string
}



export type LoginOTPInput = {
  phoneNumber: string
  validationToken: string
}

export const LOGIN_USER = gql`mutation LoginUser($username: String!, $password: String!, $exchangeToken: String) {
  tokenAuth(email: $username, password: $password, exchangeToken: $exchangeToken) {
    token
    refreshToken
    refreshExpiresIn
    payload
    redirectUrl
  }
}`;


export const LOGIN_OTP = gql`mutation LoginOTP ($phoneNumber: String!, $validationToken: String!) {
  otpAuth(validationToken: $validationToken, phoneNumber: $phoneNumber) {
    token
    refreshExpiresIn
    refreshToken
    payload
  }
}`;
