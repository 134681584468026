import {WhiteBox} from "@components/WhiteBox";
import {Box, Button} from "@mui/material";
import {communityMembershipQuery} from "@community/loaders/communityMembershipLoader";
import profusion from "@config/profusion";
import React, {useEffect, useState} from "react";
import {CommunityMembership} from "@graph";
import {QrScannerIcon} from "@components/QrScanner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import {useNavigate} from "react-router-dom";
import {PrayerGroupsCTA} from "./PrayerGroupsCTA";
import {DiscoveryGroupCTA} from "./DiscoveryGroupCTA";
import Typography from "@mui/material/Typography";

export const DashboardHomePage = () => {
    const membershipQuery = communityMembershipQuery(profusion)
    const [membership, setMembership] = useState<CommunityMembership[]>([])
    const navigate = useNavigate()


    useEffect(() => {
        membershipQuery.then(result => {
            setMembership(result.data || [])
        })
    }, [membershipQuery])


    function goGroups() {
        navigate(`/groups/prayer/shared`)
    }

    return <Box>
        <WhiteBox title="Bienvenue!"
                  titleVariant="h5">
            <Typography variant="h6" color="#454545">
            L'application Profusion permet à une église et à
            ses membres de prier et de grandir ensemble.
            </Typography>
        </WhiteBox>
        {membership.length === 0 && (
            <WhiteBox title="Rejoindre votre église"
                      titleVariant="h5">
                <Box mb={2}>
                    <Typography variant="h6" color="#454545">
                    En rejoignant la communauté de votre église,
                    vous aurez accès à ses requêtes de prières et à
                    du contenu adapté à votre développement
                    spirituel. Scannez le code de votre église.
                    </Typography>
                </Box>
                <QrScannerIcon variant="button"/>

            </WhiteBox>
        )}
        {membership.length > 0 && (
            <WhiteBox title="Votre église"
                      titleVariant="h5">
                <Box mb={2}>
                    <Typography variant="h6" color="#454545">
                    Accédez aux requêtes de prières de votre église et à
                    du contenu adapté à votre développement spirituel.
                    </Typography>
                </Box>
                <Button variant="contained"
                        size={"small"}
                        endIcon={<FontAwesomeIcon icon={faChevronRight}/>}
                        onClick={() => goGroups()}>Découvrez le contenu</Button>

            </WhiteBox>
        )}

        <PrayerGroupsCTA/>


       <DiscoveryGroupCTA />
    </Box>

}