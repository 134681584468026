import {Button} from "@mui/material";
import {faComment} from "@fortawesome/pro-light-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {useNavigateRequestComment} from "../hooks/navigation";

export interface PrayerCommentButtonProps {
    request: PrayerRequest
}

const CommentIcon = () => {
    return <FontAwesomeIcon icon={faComment} fixedWidth/>
}

export const PrayerCommentButton = (props: PrayerCommentButtonProps) => {
    const {request} = props
    const {t} = useTranslation()
    const navigate = useNavigateRequestComment(request.groupHid)

    return <Button
        size="small"
        variant="text"
        color="inherit"
        onClick={_ => navigate(request.hid)}
        startIcon={<CommentIcon/>}>{t("prayer.request.button.comment", "Commenter")}
    </Button>

}