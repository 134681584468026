import React, {Suspense, useEffect, useState} from "react";
import {Await, Outlet, useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {PrayerRequestListItem} from "../../PrayerRequestList/PrayerRequestListItem";
import {Box, IconButton, Stack} from "@mui/material";
import {LoadPrayerRequestDetailsReturn} from "../../loaders";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {PrayerCommentAddButton} from "../comments/PrayerCommentAddButton";
import {useNavigateRequestEdit} from "../../hooks/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/pro-light-svg-icons";
import {PrayerEventList} from "../comments/PrayerEventList";
import Typography from "@mui/material/Typography";
import {BodyContainer} from "@components/BodyContainer";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";


export const PrayerRequestDetails = () => {
    const {hid, rhid} = useParams()
    const {
        prayerRequest: requestData,
        events: eventsData,
        comments: commentsData,
        overview: overviewData
    } = useLoaderData() as LoadPrayerRequestDetailsReturn
    const navigate = useNavigate()
    const [dialogTitle, setDialogTitle] = useState<string>("Loading...")
    const navigateEdit = useNavigateRequestEdit(hid ?? "")
    const location = useLocation()
    const [previousUrl, setPreviousUrl] = useState("..")
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const connectedUser = useConnectedUser()

    useEffect(() => {
        setPreviousUrl(location.state?.from || "..")
    }, []);


    const handleCancel = () => {
        navigate(previousUrl)
    }

    useEffect(() => {
        requestData.then(r => {
            setDialogTitle(r.data.title)
            setCanEdit(r.data.createdBy.hid === connectedUser.profile.hid)
        })
    }, [requestData]);

    return <>
        <FullScreenDialog
            title={<Stack direction="row" useFlexGap alignItems="center">
                <Typography variant="h6" sx={{flexGrow: 1}}>{dialogTitle}</Typography>
                {canEdit && <IconButton onClick={_ => navigateEdit(rhid ?? "")}>
                    <FontAwesomeIcon icon={faEdit} color="#fafafa"/>
                </IconButton>}
            </Stack>}
            bodyContainer={BodyContainer}
            onClose={handleCancel}>
            <Box paddingBottom={8}>
            <Suspense>
                <Await resolve={requestData}>
                    {({data: prayerRequest}) => <PrayerRequestListItem
                        context="details"
                        prayerRequest={prayerRequest}/>}
                </Await>

            </Suspense>
            <Stack spacing={4} sx={{marginTop: 1}}>
                <Suspense>
                    <Await resolve={eventsData}>
                        {({data: comments}) => <PrayerEventList events={comments}/>}
                    </Await>
                </Suspense>
            </Stack>
            </Box>
        </FullScreenDialog>
        <Outlet/>
    </>
}