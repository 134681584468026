import {Box, Button, Container, Paper, Stack, Typography} from "@mui/material";
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import {faFaceAwesome} from "@fortawesome/pro-duotone-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {WhiteBox} from "@components/WhiteBox";

export const GroupCreateConfirm = () => {
    const {hid, group_type} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    return <WhiteBox sx={{pb:4}}>
        <Stack direction="column"
               alignItems="center"
               justifyContent="center"
               spacing={4}>
            <Box>
                <DuoToneIcon icon={faFaceAwesome}
                             size="6x"
                             style={{
                                 "--fa-primary-color": "#9c27b0",
                                 "--fa-primary-opacity": "0.8",
                                 "--fa-secondary-color": "#1976d2",
                                 "--fa-secondary-opacity": "0.5"
                             }}/>
            </Box>

            <Typography variant="h5">Bravo !</Typography>
            <Typography textAlign="center" variant="h6">Vous venez de créer un nouveau groupe, continuez et invitez dès maintenant vos
                {group_type === 'prayer'
                    ? " partenaires de prières."
                    : " participants."}</Typography>

            <Button variant="contained"
                    onClick={_ => navigate(`/groups/${group_type}/${hid}`)}
            >{t("common.continue", "Continuer")}</Button>

        </Stack>
    </WhiteBox>
}