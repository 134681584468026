import {AppBar, AppBarProps, Stack, Toolbar} from "@mui/material";
import {AppBarDrawerIcon} from "../drawer/AppBarDrawerIcon";
import Typography from "@mui/material/Typography";
import {NotificationCenter} from "../../../NotificationCenter/NotificationCenter";
import React from "react";
import {QrScannerIcon} from "../../../QrScanner";
import {RefreshButton} from "@components/RefreshButton/RefreshButton";

export interface ProfusionAppBarProps {
    color?: AppBarProps["color"]
    title: string
}

export const ProfusionAppBar = (props: ProfusionAppBarProps) => {
    const {color, title} = props

    return <AppBar position="fixed"
                   className="profusionAppBar"
                   color={color}>
        <Toolbar>
            <AppBarDrawerIcon/>

            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                {title}
            </Typography>
            <Stack direction="row" spacing={1}>
                <RefreshButton/>
                <NotificationCenter/>
            </Stack>
        </Toolbar>
    </AppBar>
}