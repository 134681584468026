export type Language = "fr-FR" | "en-US" | "es-ES"

export interface LanguageDefinition {
        code: Language
        label: string
        flag: string
}

const defaultLanguage: LanguageDefinition = {
    code: "fr-FR",
    label: "Français",
    flag: "https://ucarecdn.com/ddf6dfce-c4f1-4c5f-92de-2825397c748e/-/quality/smart/-/scale_crop/320x320/center/",
}

export const allowedLanguages : LanguageDefinition[] = [
    defaultLanguage,
    {
        code: "en-US",
        label: "English",
        flag: "https://ucarecdn.com/20cd593a-3107-4d8a-a8c6-6711009c0a33/-/quality/smart/-/scale_crop/320x320/center/"
    },
  /*
  {
    code: "es-ES",
    label: "Español",
    flag: "https://ucarecdn.com/b72638d2-004b-46b7-be3a-356e3aaf8f00/-/quality/smart/-/scale_crop/320x320/center/"
  }
   */
]

export const getLanguageDef = (language : Language | string) => {
    return allowedLanguages.find(_l => _l.code === language) || defaultLanguage
}
