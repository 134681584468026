import {getPrayerGroupOverview, Group, PrayerGroupOverview} from "@profusion.media/profusion-core-group"
import {CardContent, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Await, useRouteLoaderData} from "react-router-dom";
import {LoadAllPrayerGroupOverviewReturn} from "../loaders";
import React from "react";
import StatPanel from "./GroupHeader/StatPanel";
import {faCommentAltDots, faPrayingHands} from "@fortawesome/pro-solid-svg-icons";


export interface GroupDetailsSubHeaderProps {
    group: Group
}

interface GroupDetailsStatsLineProps {
    group: Group
    data: PrayerGroupOverview[]
}

const GroupDetailsStatsLine = (props: GroupDetailsStatsLineProps) => {
    const {t} = useTranslation()
    const {group, data} = props
    const groupData = getPrayerGroupOverview(group, data)

    const isPrayer = group.mainFeature === 'prayer'

    if (!groupData) return <></>

    const thisWeekRequests = groupData.requestThisWeek ? t("prayer.group.header.stats.requests_this_week", {
        defaultValue: "+{{count}} cette semaine",
        count: groupData.requestThisWeek
    }) : undefined

    const thisWeekPrayers = groupData.prayerThisWeek ? t("prayer.group.header.stats.requests_this_week", {
        defaultValue: "+{{count}} cette semaine",
        count: groupData.prayerThisWeek
    }) : undefined


    return <Stack direction="row" spacing={1}>
        {isPrayer && <StatPanel icon={faCommentAltDots} iconBgColor="#1976d2"
                   value={t("prayer.group.header.stats.requests", {
                       defaultValue: "{{ requests }} requêtes",
                       count: groupData?.requestCount ?? 0,
                       requests: groupData?.requestCount ?? 0
                   })}
                   secondary={thisWeekRequests}
        />}
        {isPrayer && <StatPanel icon={faPrayingHands} iconBgColor="#228B22" value={t("prayer.group.header.stats.prayers", {
            defaultValue: "{{ prayers }} prières",
            count: groupData?.prayerCount ?? 0,
            prayers: groupData?.prayerCount ?? 0
        })}
                   secondary={thisWeekPrayers}
        />}
    </Stack>


}

export const GroupDetailsSubHeader = (props: GroupDetailsSubHeaderProps) => {
    const {group} = props
    const {overviews} = useRouteLoaderData("groupsRoot") as LoadAllPrayerGroupOverviewReturn


    return <CardContent><Await resolve={overviews}>
        {({data}) => <GroupDetailsStatsLine group={group} data={data}/>}
    </Await>
    </CardContent>
}