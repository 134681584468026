import {gql} from "@profusion.media/profusion-core";
import {NOTIFICATION_MESSAGE_FIELDS} from "../fragments/notification_message_fields";

export const NOTIFICATION_MESSAGES_QUERY = gql`
  ${NOTIFICATION_MESSAGE_FIELDS}
  query notificationMessages {
    notificationMessages {
      ...NotificationMessageFields
    }
  }
`
