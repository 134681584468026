import {useState} from "react";
import {
    AvatarCreateInput,
    MutationUpdateOrganizationArgs,
    Organization,
    OrganizationUpdateInput
} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "@organizations/screens/edit/OrganizationForm";
import {emptyOrganizationInput} from "../../typings";
import {Await, useActionData, useNavigate, useNavigation, useRouteLoaderData, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {OrganizationLoaderReturn, useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";

export const OrganizationUpdate = () => {
    const submit = useSubmit()
    const {organization} = useOrganizationDetailsLoader("organizationDetailsSU")
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const [avatar, setAvatar] = useState<AvatarCreateInput|undefined>(undefined)
    const [removeAvatar, setRemoveAvatar] = useState<boolean>(false)

    const navigate = useNavigate()
    const loading = navigation.state === "loading"


    const handleSubmit = async (organization: Partial<Organization>) => {
        const data : MutationUpdateOrganizationArgs = {
            organization: {
                hid: organization.hid,
                name: organization.name,
                shortName: organization.shortName,
                leaderLastName: organization.leaderLastName,
                leaderFirstName: organization.leaderFirstName,
                email: organization.email,
                phoneNumber: organization.phoneNumber,
                website: organization.website,
                city: organization.city,
            },
            removeAvatar,
            avatar
        }
        console.log(data)
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate("/su/organizations")
    }

    const getAvatarUrl = (org: Organization) => {
        return removeAvatar
            ? undefined
            : avatar?.url ?? org.profile?.avatar?.smallUrl
    }

    return <FullScreenDialog title={`Mettre à jour`} onClose={handleCancel}>
        <Await resolve={organization}>
            {({data}) =>
                <OrganizationForm
                    avatarUrl={getAvatarUrl(data)}
                    onAvatarDelete={() => {
                        setAvatar(undefined)
                        setRemoveAvatar(true)
                    }}
                    onAvatarChange={a => {
                        setAvatar(a)
                        setRemoveAvatar(false)
                    }}
                    organization={data}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    loading={loading}
                    errors={validationErrors}
                />}
        </Await>
    </FullScreenDialog>
}