import { gql, DocumentNode } from '@profusion.media/profusion-core'

export const PRAYER_REQUEST_FIELDS : DocumentNode = gql`
  fragment PrayerRequestFields on PrayerRequest {
    hid
    dateCreated
    dateExpiration
    title
    text
    groupHid
    groupTitle
    pinColor
    userLastPrayerDate
    userPrayerCount
    totalPrayerCount
    createdBy {
      hid
      displayName
      kind
      avatar {
        hid
        smallUrl
        mediumUrl
        largeUrl
      }
    }
  }
`
