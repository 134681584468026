import {PushNotifications, Token} from "@capacitor/push-notifications";
import {ProfusionClient} from "../core/client";
import {actionDeviceUpdate} from "../device";
import {Device as CapacitorDevice, DeviceInfo} from "@capacitor/device";


async function updateDeviceInfo(client: ProfusionClient, token: string) {
    if (client.session.is_authenticated) {
        await CapacitorDevice.getInfo().then((device: DeviceInfo) => {
            console.log("UPDATING DEVICE");
            console.log(device);
            return actionDeviceUpdate(client, {
                name: device.name ?? "",
                platform: device.platform,
                userAgent: device.manufacturer + " " + device.model + " " + device.webViewVersion,
                systemId: client.getDeviceHid(),
                notificationToken: token
            });
        });
    } else {
        console.log("SKIP DEVICE UPDATE : not authenticated")
    }
}

export function initWebMessaging(client: ProfusionClient) {
    updateDeviceInfo(client, "");
}

export function initAppMessaging(client: ProfusionClient) {
    PushNotifications.addListener("registration", async (token: Token) => {
        console.log("Push registration success, token: " + token.value);
        await updateDeviceInfo(client, token.value);
    });

    PushNotifications.addListener("registrationError",
        async (error: any) => {
            console.log("Error on registration: " + JSON.stringify(error));
            await updateDeviceInfo(client, "***" + JSON.stringify(error));
        }
    );

    PushNotifications.checkPermissions().then(async result => {
        if (result.receive === "granted") {
            await PushNotifications.register();
        }
    });
}

export function initMessaging(client: ProfusionClient) {
    CapacitorDevice.getInfo().then((device: DeviceInfo) => {
        if (device.platform === "web") {
            initWebMessaging(client);
        } else {
            initAppMessaging(client);
        }
    });
}

export function setupMessaging(client: ProfusionClient) {
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
    console.log("setupMessaging");
    try {
        PushNotifications.requestPermissions().then(async result => {
            if (result.receive === "granted") {
                // Register with Apple / Google to receive push via APNS/FCM
                await PushNotifications.register();
            } else {
                console.log("Push registration failed", result);
                await updateDeviceInfo(client, result.receive);
            }
        }).catch(onError => console.log(onError));
    } catch (error) {
        console.error(error);
    }
}
