import React, {useEffect, useState} from 'react'

import {DrawerMenuProps, DrawerMenuPropsDefaults, useDrawerMenu} from "@profusion.media/profusion-ui"
import {Box, Drawer, IconButton} from "@mui/material"
import {DashboardDrawerHeader} from "./DashboardDrawerHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons";


export const DashboardDrawer = (props: DrawerMenuProps) => {
    const {children} = {...DrawerMenuPropsDefaults, ...props}
    const {drawerOpen, toggleDrawer, setDrawerOpen} = useDrawerMenu()
    const [showClose, setShowClose] = useState<boolean>(false)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []); // Ne vous fiez qu'au montage et au démontage du composant.


    useEffect(() => {
        if (drawerOpen) {
            setTimeout(() => setShowClose(true), 195);
        } else {
            setShowClose(false)
        }
    }, [drawerOpen]);

    const handleClick = (e: any) => {
        if (windowWidth < 640) {
            setDrawerOpen(false)
        }
    }

    const closeDrawer = () => {
        console.log("CLOSE drawer")
        toggleDrawer()
    }


    return <>
    {showClose && <Box className="drawerCloseIcon">
            <IconButton onClick={_ => closeDrawer()}>
                <FontAwesomeIcon icon={faMultiply} color="#fafafa" fixedWidth/>
            </IconButton>
        </Box>}
        <Drawer open={drawerOpen}
                className="app-drawer"
                sx={{zIndex: 40000}}
                PaperProps={{sx: {width: {xs: "100%", "md": 400}}}}>

            <DashboardDrawerHeader/>
            <Box className="app-drawer-items" component="nav" onClick={handleClick}>
                {children}
            </Box>
        </Drawer></>
}