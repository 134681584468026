import {executeMutation} from "@profusion.media/profusion-core";
import {CommunityMember, CommunityMemberSetTagInput, MutationCommunityMemberSetTagArgs} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_MEMBER_SET_TAG_MUTATION} from "@community/schema/mutations/community_member";


export const useCommunityMemberSetTag = (memberHid: string) => {
    return {
        addTag: (tagHid: string) => {
            return executeMutation<CommunityMember, MutationCommunityMemberSetTagArgs>(
                profusion,
                COMMUNITY_MEMBER_SET_TAG_MUTATION,
                {
                    input: {
                        isRemoved: false,
                        memberHid,
                        tagHid
                    }
                },
                {
                    isPrivate: true
                })
        },

        removeTag: (tagHid: string) => {
            return executeMutation<CommunityMember, MutationCommunityMemberSetTagArgs>(
                profusion,
                COMMUNITY_MEMBER_SET_TAG_MUTATION,
                {
                    input: {
                        isRemoved: true,
                        memberHid,
                        tagHid
                    }
                },
                {
                    isPrivate: true
                })
        }
    }
}