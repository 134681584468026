import {useCommunityMembersLoader} from "@community/loaders/communityMembersLoader";
import {Await} from "react-router-dom";
import List from "@mui/material/List";
import {ListItem, ListItemAvatar, ListItemSecondaryAction} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import {CommunityMember, CommunityTag} from "@graph";
import Typography from "@mui/material/Typography";
import {faCrown} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {CommunityMemberBottomSheet} from "@community/screens/members/drawer/CommunityMemberBottomSheet";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {makeTagList} from "@community/utils/create_tree";

const CommunityMemberListItemTitle = (props: CommunityMember) => {
    return (props.user &&
        <Typography>{`${props.user.firstName} ${props.user.lastName}`}
            {props.isAdmin && <FontAwesomeIcon icon={faCrown}/>}
        </Typography>)
}

const CommunityMemberListItem = (props: {member: CommunityMember, onSelect: (member: CommunityMember) => void }) => {
    const {member, onSelect} = props
    return (member.user &&
        <ListItem key={member.user.email}
                  onClick={_ => onSelect(member)}
        >
            <ListItemAvatar>
                <UserAvatar avatar={member.user.avatar?.mediumUrl} userName={member.user.firstName}/>
            </ListItemAvatar>
            <ListItemText primary={<CommunityMemberListItemTitle {...member} />}
                          secondary={member.user.email}/>
            <ListItemSecondaryAction>
                {makeTagList(member.tags as CommunityTag[])}
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export interface CommunityMembersListProps {
    rootId?: string

}

export const CommunityMembersList = (props: CommunityMembersListProps) => {
    const {rootId} = props
    const {members} = useCommunityMembersLoader()
    const {community} = useCommunityDetailsLoader(rootId ? rootId : "orgCommunityDetails")
    const [selectedMember, setSelectedMember] = useState<string>();

    const handleSelectMember = (member: CommunityMember) => {
        setSelectedMember(member.hid)
    }

    const handleCloseMemberDrawer = () => {
        setSelectedMember(undefined)
    }


    return <Await resolve={members}>
        {({data: allMembers}) => <>
            <List>
                {allMembers.map((member: CommunityMember, k: any) => <CommunityMemberListItem
                    key={k}
                    member={member}
                    onSelect={handleSelectMember}
                />)}
            </List>
            <Await resolve={community}>
                {({data}) => <CommunityMemberBottomSheet member={allMembers.find((m: CommunityMember) => m.hid === selectedMember)}
                                                         open={!!selectedMember}
                                                         community={data}
                                                         onClose={handleCloseMemberDrawer}/>}
            </Await>

        </>}
    </Await>
}