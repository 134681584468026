import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import React, {Suspense} from "react";
import {Await, useNavigate} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Community} from "@graph";
import {CommunityJoin} from "@community/screens/JoinCommunity/CommunityJoin";
import {Box, Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import {DiscoveryGroupCTA} from "../../../screens/DashboardHomePage/DiscoveryGroupCTA";

export const CommunityHome = () => {
    const {community} = useCommunityDetailsLoader("communityDetails")
    const connectedUser = useConnectedUser()

    return <Suspense>
        <Await resolve={community}>
            {({data}) => ["pending", "none"].includes(data.memberStatus) ? <CommunityJoin community={data}/> :
                <CommunityStartPage community={data}/>}
        </Await>
    </Suspense>
}


interface CommunityStartPageProps {
    community: Community
}


export const CommunityStartPage = (props: CommunityStartPageProps) => {
    const {community} = props
    const navigate = useNavigate()


    function goGroups() {
        navigate(`/groups/${community.organization.prayerGroupHid}`)
    }

    return <Box>
        <WhiteBox title="Votre église"
                  subtitle={community.organization.name}
                  titleVariant="h6"
                  subtitleVariant="subtitle2">
            <Box mb={2}>
                Accédez aux requêtes de prières de votre église et à
                du contenu adapté à votre développement spirituel.
            </Box>

        </WhiteBox>
        <WhiteBox title="Groupes de prière"
                  titleVariant="h6">
            <Box mb={2}>
                Priez avec les autres membres pour les requêtes
                partagées par votre église.
            </Box>
            <Button variant="contained"
                    size="small"
                    endIcon={<FontAwesomeIcon icon={faChevronRight}/>}
                    onClick={() => goGroups()}>Groupe de prière</Button>

        </WhiteBox>


        <DiscoveryGroupCTA/>
    </Box>

}