import {gql} from "@profusion.media/profusion-core";
import {NOTIFICATION_SETTINGS_FIELDS} from "../fragments";

export const NOTIFICATION_SETTINGS_QUERY = gql`
  ${NOTIFICATION_SETTINGS_FIELDS}
  query notificationSettingsDetails($hid: String!) {
    notificationSettings(hid: $hid) {
        ...NotificationSettingsFields
    }
  }
`
