import {Await, Outlet, useLoaderData} from "react-router-dom";
import {LoadPinnedRequestListReturn} from "../loaders";
import {Suspense, useState} from "react";
import {Box, Stack} from "@mui/material";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {PrayerRequestListItem} from "../PrayerRequestList/PrayerRequestListItem";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PinnedRequestListEmpty} from "./PinnedRequestListEmpty";
import {faThumbTack as faBookmark} from "@fortawesome/pro-solid-svg-icons"
import {WhiteBox} from "@components/WhiteBox";
import {PinnedRequestListFilter} from "@groups/Prayers/PinnedRequestList/PinnedRequestListFilter";
import {faThumbTack} from "@fortawesome/pro-duotone-svg-icons";
import Typography from "@mui/material/Typography";

export const PinnedRequestList = () => {
    const {t} = useTranslation()
    const {prayerRequests: requestsData} = useLoaderData() as LoadPinnedRequestListReturn
    const [filter, setFilter] = useState<string | undefined>(undefined)

    const _filter = (request: PrayerRequest) => {
        if (!filter) return true
        return request.pinColor == filter
    }

    return <>
        <WhiteBox
            title={t("prayer.pinned.title", "Requêtes en cours")}
            icon={<FontAwesomeIcon size="xl" icon={faBookmark}/>}
            secondaryAction={<Box display="flex" flexDirection="row" alignItems="center" alignSelf="end">
                <PinnedRequestListFilter filter={filter} onChange={setFilter}/>
            </Box>}
            titleVariant="h6">
            {filter && <Typography>
                {t("prayer.favorites.filter_label", "Filtré par : ")}
                <FontAwesomeIcon icon={faBookmark} color={filter}/>
            </Typography>}
        </WhiteBox>
        <Suspense>
            <Await resolve={requestsData}>
                {({data: prayerRequests}) => <>

                    <Stack spacing={1}>
                        {prayerRequests.length ? prayerRequests.map((r: PrayerRequest) =>
                                _filter(r) && <PrayerRequestListItem
                                    key={r.hid}
                                    context="bookmark"
                                    prayerRequest={r}/>)
                            : <PinnedRequestListEmpty/>}
                    </Stack>
                </>}
            </Await>
        </Suspense>
        <Outlet/>


    </>
}
