import {useContext, useEffect, useState} from "react";
import {FormContext, FormContextProps} from "./contexts";
import {FormErrors, FormFieldError, FormGroupError} from "./typings";

import {FormFieldContext, FormFieldContextProps} from "../FormValidation/context";

export const useFormFieldError = (name: string): FormFieldError => {
    const context = useContext<FormFieldContextProps>(FormFieldContext)
    const [error, setError] = useState<FormFieldError>({
        field: name,
        isError: false
    })

    useEffect(() => {
        if (context?.isError && context?.fields[name]) {
            setError({
                field: name,
                isError: context.fields[name].isError,
                code: context.fields[name].errorCode
            })
        } else {
            setError({
                field: name,
                isError: false,
                code: undefined
            })
        }
    }, [context.isError]);

    return error
}

export const useFormGroupErrors = (name: string): Array<FormGroupError> => {
    const context = useContext<FormContextProps>(FormContext)
    const errors = context.errors?.filter(e => e.group === name) ?? []
    return errors.map(e => ({
        group: e.group,
        isError: true,
        code: e.code
    }))
}

export const useFormErrors = (): FormErrors => {
    const context = useContext<FormContextProps>(FormContext)
    return {
        hasErrors: context.errors && context.errors.length > 0,
        errors: context.errors
    }
}

export const useOnSubmit = () => {
    const context = useContext<FormContextProps>(FormContext)
    return context.onSubmit
}

export const useOnCancel = () => {
    const context = useContext<FormContextProps>(FormContext)
    return context.onCancel
}

export const useFormLoading = () => {
    const context = useContext<FormContextProps>(FormContext)
    return context.loading ?? false
}

export const useFormUpdating = () => {
    const context = useContext<FormContextProps>(FormContext)
    return context.updating ?? false
}