import {css} from "@mui/material";
import styled from "@emotion/styled";
import Cropper from "react-cropper";
import {ShapeType} from "./typings";

interface CropperShape {
    shape?: ShapeType
}

const shapeStyle = (props: CropperShape) => {
    if (props.shape === 'rounded') {
        return css`
          && .cropper-view-box,
          && .cropper-face {
            border-radius: 50%;
          }

          /* The css styles for \`outline\` do not follow \`border-radius\` on iOS/Safari (#979). */

          && .cropper-view-box {
            outline: 0;
            box-shadow: 0 0 0 1px #39f;
          }
        `
    } else {
        return css``
    }
}
export const CustomCropper = styled(Cropper)`${shapeStyle}`