import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";
import Typography from "@mui/material/Typography";
import {Box, Button} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {faArrowDown, faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Group} from "@graph";
import {GroupJoinButton} from "@groups/Groups/GroupJoin/GroupJoinButton";
import {GroupJoinInfo} from "@groups/Groups/GroupJoin/GroupJoinInfo";
import {DashboardAppContext} from "@groups/DashboardApp";

export interface GroupJoinProps {
    group: Group;
}

export const GroupJoin = (props: GroupJoinProps) => {
    const {group} = props
    const connectedUser = useConnectedUser()
    const navigate = useNavigate()
    const context = useContext(DashboardAppContext)

    useEffect(() => {
        context.setBottomTab(1)
    })

    return <WhiteBox justifyContent="center" flexDirection="column" display="flex">
        <Box display="flex"
             flexDirection="column"
             alignItems="center"
             gap={5}
             paddingY={4}
        >
            <Typography variant="h5"
                        textAlign="center"
                        color="textSecondary" component="div">
                Vous avez été invité à rejoindre un groupe !
            </Typography>


            <Box display="flex" flexDirection="row"
                 alignContent="center"
                 justifyContent="space-evenly"
                 width="100%"
                 gap={1}
            >
                <Box flex={1}
                     display="flex"
                     flexDirection="column"
                     alignItems="center"
                >
                    <UserAvatar size={64}
                                userName={connectedUser?.firstName + " " + connectedUser?.lastName}
                                avatar={connectedUser?.profile?.avatar?.smallUrl}/>
                    <Typography variant="body1">Vous</Typography>
                </Box>

                <Box flex="0 1 32px" textAlign='center' pt={2}>
                    <FontAwesomeIcon icon={faArrowRight} size="2xl"/>
                </Box>
                <Box flex={1}>
                    <GroupJoinInfo group={group}/>
                </Box>
            </Box>
            <GroupJoinButton group={group}/>
            <Button
                onClick={_ => navigate("/")}
                variant="text" color="inherit">Plus tard</Button>
        </Box>
    </WhiteBox>
}