import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {CommunityJoinInput, CommunityJoinResponse, MutationCommunityJoinArgs} from "@graph";
import profusion from "@config/profusion";
import {COMMUNITY_JOIN_MUTATION} from "@community/schema/mutations/community_join";
import {GET_CONNECTED_USER} from "@profusion.media/profusion-iam-user";

export const actionCommunityJoin = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as CommunityJoinInput

    const response = await executeMutation<CommunityJoinResponse, MutationCommunityJoinArgs>(
        profusion,
        COMMUNITY_JOIN_MUTATION,
        {input: data},
        {
            isPrivate: true,
            refetchQueries: [{
                query: GET_CONNECTED_USER, context: {
                    use_private: true
                }
            }]
        })
    return response.data

}
