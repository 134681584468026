import {Organization} from "@profusion.media/graph";
import {IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate, useSubmit} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";
import {ImageUploaderDialog, UploadedImage} from "@profusion.media/profusion-ui-image-uploader";
import React from "react";
import {useTranslation} from "react-i18next";

export interface OrganizationDetailsHeaderProps {
    organization: Organization;
}

export const OrganizationDetailsHeader = (props: OrganizationDetailsHeaderProps) => {
    const {organization} = props
    const submit = useSubmit()
    const navigate = useNavigate()
    const [openAvatarDialog, setOpenAvatarDialog] = React.useState<boolean>(false);
    const {t} = useTranslation()


    const handleUploaded = (uploaded: UploadedImage) => {
        submit({hid: organization.hid ?? "", ...uploaded}, {
            method: "post",
            encType: "application/json",
            action: `/su/organizations/${organization.hid}/avatar`,
        })
        setOpenAvatarDialog(false);
    }

    return <WhiteBox>
        <List>
            <ListItem>
                <ListItemAvatar onClick={() => setOpenAvatarDialog(true)}>
                    <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
                </ListItemAvatar>
                <ListItemText primary={organization.name}/>
                <ListItemSecondaryAction>
                    <IconButton onClick={() => navigate("edit")}>
                        <EditIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
        <ImageUploaderDialog open={openAvatarDialog}
                             onUploaded={handleUploaded}
                             onCancel={() => setOpenAvatarDialog(false)}
                             image={organization?.profile?.avatar?.largeUrl ?? ""}
                             folder="organizations/logo"
                             shape="rounded"
                             title={t("organization.logo.label", "Logo") ?? ""}
                             filePrefix="ua-"
        />
    </WhiteBox>
}