import {gql} from "@profusion.media/profusion-core";
import {PRAYER_REQUEST_FIELDS} from "../fragments";

export const PRAYER_REQUEST_CREATE_MUTATION = gql`
  ${PRAYER_REQUEST_FIELDS}
  mutation prayerRequestCreate($input: PrayerRequestCreateInput!) {
    prayerRequestCreate(input: $input) {
      prayerRequest {
        ...PrayerRequestFields
      }
      errors {
        field
        code
      }
    }
  }`;
