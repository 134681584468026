import { DefinitionNode, DocumentNode, OperationDefinitionNode } from "graphql";
import { GraphMutationOptions, GraphQueryOptions, GraphQueryPromise, MutationResult } from "./typings";
import { ProfusionClient } from "../core/client";
import { FetchResult } from "@apollo/client/link/core";

export function getMutationNames(document: DocumentNode): string[] {
  const mutationNames: string[] = [];

  document.definitions.forEach((definition: DefinitionNode) => {
    if (definition.kind === "OperationDefinition" && definition.operation === "mutation") {
      const operationDefinition = definition as OperationDefinitionNode;
      operationDefinition.selectionSet.selections.forEach(selection => {
        if (selection.kind === "Field") {
          mutationNames.push(selection.name.value);
        }
      });
    }
  });

  return mutationNames;
}

export function getQueryNames(document: DocumentNode): string[] {
  const queryNames: string[] = [];

  document.definitions.forEach((definition: DefinitionNode) => {
    if (definition.kind === "OperationDefinition" && definition.operation === "query") {
      const operationDefinition = definition as OperationDefinitionNode;
      operationDefinition.selectionSet.selections.forEach(selection => {
        if (selection.kind === "Field") {
          queryNames.push(selection.name.value);
        }
      });
    }
  });

  return queryNames;
}


export const executeQuery = <TData, TVariables extends Record<string, any> = Record<string, any>>
(client: ProfusionClient, query: DocumentNode, options: GraphQueryOptions<TVariables>): GraphQueryPromise<TData> => {
  const qlClient = client.apollo;
  const queryName = getQueryNames(query)[0];


  return qlClient.query({
    query,
    variables: options.variables,
    fetchPolicy: options.networkOnly ? "network-only" : "cache-first",
    context: {
      use_private: options.isPrivate,
      use_device: options.useDevice
    }
  }).then(response => {
    return Promise.resolve({ data: response.data?.[queryName] as TData });
  });

};

export const executeMutation = <TData, TVariables extends Record<string, any> = Record<string, any>>
(client: ProfusionClient,
 mutation: DocumentNode,
 variables: TVariables,
 options: GraphMutationOptions<TData, TVariables>): Promise<MutationResult<TData>> => {
  const session = client.session;
  const qlClient = client.apollo;
  const mutationName = getMutationNames(mutation)[0];

  function mutate(variables?: TVariables | { [R: string]: TVariables }) {
    return qlClient.mutate<TData, TVariables | { [R: string]: TVariables }>({
      mutation,
      variables,
      context: { use_private: options.isPrivate, use_device: options.useDevice },
      refetchQueries: (_r) => {
        if (options.refetchQueries && _r.data?.[mutationName]) {
          if (typeof options.refetchQueries === "function") {
            return options.refetchQueries(_r.data?.[mutationName], variables);
          } else {
            return options.refetchQueries;
          }
        } else {
          return [];
        }
      },
      //awaitRefetchQueries: true,
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      }
    }).catch(e => {
      console.log(`EXECPTION in ${mutationName} : ` + JSON.stringify(e));
      throw e;
    });
  }

  const responseResolver = (response: FetchResult<TData>) => {
    const _r = response.data?.[mutationName];
    return Promise.resolve({
      data: _r,
      validationErrors: _r?.errors
    });
  };

  try {
    return mutate(variables).then(response => {
      return responseResolver(response);
    });
  } catch (error) {
    console.error("Error executing mutation:", JSON.stringify(error));
  }
};
