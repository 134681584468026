import {SearchOrganizationResult} from "@profusion.media/graph";
import {ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons";

export interface SearchOptionValueProps {
    value: SearchOrganizationResult
    showClear?: boolean
    onSelect?: (v: SearchOrganizationResult) => void
    onClear?: () => void
}

export const SearchOptionValue = (props: SearchOptionValueProps) => {
    const {value, showClear, onClear, onSelect} = props

    return <ListItem dense onClick={_ => onSelect && onSelect(value)}>
        <ListItemAvatar>
            <UserAvatar avatar={value.avatar?.smallUrl} userName={value.name || ""}/>
        </ListItemAvatar>
        <ListItemText primary={value.name} secondary={value.city}/>
        {showClear && <ListItemSecondaryAction>
            <FontAwesomeIcon icon={faMultiply} onClick={onClear} />
        </ListItemSecondaryAction>}
    </ListItem>
}