import {
  Group,
  GROUP_DETAILS,
  GROUP_LIST_MINE_QUERY,
  GROUP_UPDATE_MUTATION,
  GroupUpdateInput,
  MutationGroupLeaveArgs
} from "../schema";
import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {
  AvatarCreateInput,
  GroupDeleteMutation,
  MutationGroupDeleteArgs,
  MutationGroupUpdateArgs
} from "@profusion.media/graph";
import {
  GROUP_DELETE_MUTATION
} from "@profusion.media/profusion-core-group/groups/schema/mutations/group_delete_mutation";
import {ActionFunctionArgs, redirect} from "react-router-dom";
import profusion from "@config/profusion";


export const actionDeleteGroup = async (props: ActionFunctionArgs) => {
  const data = await props.request.json() as MutationGroupDeleteArgs
  const response = await executeMutation<GroupDeleteMutation, MutationGroupDeleteArgs>(profusion, GROUP_DELETE_MUTATION,
    {
      input: {
        hid: data.input.hid
      }
    }, {
      isPrivate: true,
      refetchQueries: (data, variables) => [{
        query: GROUP_LIST_MINE_QUERY,
        variables: {
          type: data.feature,
        },
        context: {
          use_private: true
        }
      }]
    })

  return response.data
}
