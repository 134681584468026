import React, {useEffect} from "react"

import { App as CapacitorApp } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    useEffect(() => {
        CapacitorApp.addListener('appUrlOpen', (event: any) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('profusion.media').pop();
            if (slug) {
             window.location.href = slug;
            }
        });
    }, []);

    return null;
};

export default AppUrlListener;