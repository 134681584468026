import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {
  PRAYER_REQUEST_CREATE_MUTATION,
  PRAYER_REQUEST_LIST_QUERY,
  PrayerRequest,
  PrayerRequestCreateInput
} from "../schema";

export type ActionCreatePrayerRequestResponse = {
  prayerRequest: PrayerRequest
}

export const actionCreatePrayerRequest = (client: ProfusionClient, prayerRequest: PrayerRequestCreateInput) => {
  return executeMutation<ActionCreatePrayerRequestResponse, MutationInput<PrayerRequestCreateInput>>(client, PRAYER_REQUEST_CREATE_MUTATION,
    {
      input: {
        title: prayerRequest.title,
        text: prayerRequest.text,
        groupHid: prayerRequest.groupHid,
        dateExpiration: prayerRequest.dateExpiration ? prayerRequest.dateExpiration : undefined
      }
    }, {
      isPrivate: true,
      refetchQueries: [{
        query: PRAYER_REQUEST_LIST_QUERY,
        context: {
          use_private: true
        },
        variables: {
          group: prayerRequest.groupHid
        }
      }]
    })
}
