import {useState} from "react";
import {MutationCreateOrganizationArgs, Organization, SearchOrganizationResult} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "@organizations/screens/edit/OrganizationForm";
import {emptyOrganizationInput} from "../../typings";
import {useActionData, useNavigate, useNavigation, useParams, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {OrganizationTypeChooser} from "./OrganizationTypeChooser";
import SearchOrganizationInput from "./components/OrganizationSeachInput/OrganizationSearchInput";
import {SearchOptionValue} from "./components/OrganizationSeachInput/SearchOptionValue";
import List from "@mui/material/List";
import {WhiteBox} from "@components/WhiteBox";

export const OrganizationCreate = () => {
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const {org_type} =useParams()
    const [data, setData] = useState<MutationCreateOrganizationArgs>({
        organization: {...emptyOrganizationInput, type: org_type},
        parent: "",
        avatar: undefined
    });
    const [selectParent, setSelectParent] = useState<boolean>(['church', 'partner'].includes(org_type))
    const [parentData, setParentData] = useState<SearchOrganizationResult>()
    const [avatar, setAvatar] = useState<any|undefined>(undefined)

    const navigate = useNavigate()
    const loading = navigation.state === "loading"

    const handleSubmit = async (organization: MutationCreateOrganizationArgs["organization"]) => {
        const newData = {
            organization,
            parent: parentData?.hid ?? "",
            avatar
        }
        setData(newData)
        submit(newData, {
            method: "post",
            encType: "application/json"
        })
    }

    const getAvatarUrl = () => {
        return avatar?.url
    }


    const setType = (v: string) => {
        setData(prev => ({
            ...prev, organization: {
                ...prev.organization, type: v
            }
        }))
        setSelectParent(['church', 'partner'].includes(v))
    }

    const setParent = (v: SearchOrganizationResult | undefined) => {
        console.log("set parent " + v)
        setParentData(v)
        setData(prev => ({...prev, parent: v?.hid || ""}))
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const title = org_type === 'church' ? "Ajouter une église" : "Ajouter une dénomination"

    return <FullScreenDialog title={title} onClose={handleCancel}>
        {selectParent && <WhiteBox title={parentData ? "Dénomination" : "Choisir une dénomination"}>
            {data.parent === "" && <SearchOrganizationInput onChange={setParent}/>}
            {parentData && <List>
                <SearchOptionValue value={parentData} onClear={() => setParent(undefined)} showClear={true}/>
            </List>}
        </WhiteBox>}
        <OrganizationForm<MutationCreateOrganizationArgs["organization"]>
            organization={data.organization}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            avatarUrl={getAvatarUrl()}
            onAvatarChange={a => {
                setAvatar(a)
            }}
            onAvatarDelete={() => setAvatar(undefined)}
            loading={loading}
            errors={validationErrors || []}
            type={data.organization.type}
            variant="simple"
        />
    </FullScreenDialog>
}