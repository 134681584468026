import {Content, Group} from "@graph";
import {Checkbox} from "@mui/material";
import React from "react";
import {useRevalidator} from "react-router-dom";
import {useGroupSetContent} from "@profusion.media/profusion-core-group/groups/actions/group_set_content";

export interface GroupContentCheckboxProps {
    group: Group
    content: Content
}

const isCheck = (group: Group, content: Content) => {
    return !!group.attachments?.find(a => a && a.hid === content.hid)
}

export const GroupContentCheckbox = (props: GroupContentCheckboxProps) => {
    const {group, content} = props
    const [checked, setChecked] = React.useState(isCheck(group, content))
    const {revalidate} = useRevalidator()
    const manager = useGroupSetContent(group.hid)

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.currentTarget.checked)
        if (e.currentTarget.checked) {
            manager.addContent(content.hid).then(_ => revalidate())
        } else {
            manager.removeContent(content.hid).then(_ => revalidate())
        }
    }

    return <Checkbox checked={checked} onChange={handleCheck}/>
}