import {Subscriber} from "@graph";
import {ListItem, ListItemSecondaryAction, Switch} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {useRevalidator} from "react-router-dom";
import React, {useState} from "react";
import {useGroupSubscriberAction} from "@groups/Groups/GroupDetails/invitation/actions";

export interface GroupSubscriberInfoHostProps {
    subscriber: Subscriber;
}

export const GroupSubscriberInfoHost = (props: GroupSubscriberInfoHostProps) => {
    const {subscriber} = props
    const {setHost} = useGroupSubscriberAction(subscriber.hid)
    const {revalidate, state} = useRevalidator();
    const [checked, setChecked] = useState(subscriber.isHost)

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        setHost(event.target.checked).then(_ => revalidate())
    }

    return <ListItem>
        <ListItemText primary="Animateur du groupe"/>
        <ListItemSecondaryAction>
            <Switch checked={checked} onChange={handleSwitch}/>
        </ListItemSecondaryAction>
    </ListItem>
}