import {ListItem, ListItemAvatar} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";
import {Await, useNavigate} from "react-router-dom";
import {Organization} from "@profusion.media/graph";


export const OrganizationChildren = () => {
    const {children} = useOrganizationDetailsLoader("organizationDetailsSU")
    const navigate = useNavigate()
    const handleDetails = (organization: Organization) => {
        navigate(`/su/organizations/${organization.hid}`)
    }
    return <Await resolve={children}>
        {({data: organizations}) =>
            organizations.map((organization: Organization) =>
                <ListItem key={organization.hid}
                          onClick={() => handleDetails(organization)}>
                    <ListItemAvatar>
                        <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
                    </ListItemAvatar>
                    <ListItemText primary={organization.name}/>
                </ListItem>)
        }
    </Await>

}