import {Box, Button, Container, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {WaitingPage} from "@profusion.media/profusion-ui";
import {AuthSession, useAuthentication, useProfusionSession} from "@profusion.media/profusion-core";
import {useNavigate} from "react-router-dom";

async function _sessionConnect(xr: string, session: AuthSession) {
    console.log("receiver : " + xr)
    await session.loginConnectToken(xr)
    console.log("receiver connected")
}

export const HomePage = () => {
    const {refresh} = useAuthentication()
    const searchParams = new URLSearchParams(window.location.search)
    const xr = searchParams.get("xr") ?? false
    const session = useProfusionSession()
    const [sessionReady, setSessionReady] = useState<boolean>(false)

    const navigate = useNavigate()

    const startSession = () => {
        setSessionReady(true)
    }

    useEffect(() => {
        if (xr) {
            _sessionConnect(xr, session).then(_ => {
                refresh().then((_: any) => {
                    startSession()
                })
            })
        } else {
            startSession()
        }
    }, [xr]);

    useEffect(() => {
        if (session.is_authenticated) {
            navigate("/dashboard")
        }
    }, [session.is_authenticated])


    if (!sessionReady) {
        return <WaitingPage show={true}/>
    }


    const goConnect = () => {
        navigate("/login")
    }
    const goRegister = () => {
        navigate("/register")
    }

    const packageVersion = import.meta.env.VITE_APP_VERSION; // getting package version from package.json

    return <Container
        maxWidth="xl"
        sx={{
            backgroundImage: `url(/img/_0b4a91be-bfc4-4f4a-b749-de2bc9ab77dc.jpeg)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            overflowY: 'scroll',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',  // added this line for background opacity
        }}>
        <Container maxWidth="sm" sx={{paddingY: 4}}>
            <Box display="flex" flexDirection="column" gap={1}>
                <img src="/icon-256x256.png"
                     width={128} height={128}
                     alt="logo profusion"/>

                <Box display="flex" flexDirection="column" gap={0} mt={3}>
                    <Typography
                        sx={{
                            textAlign: "center",
                            p: 1, color: 'white', backgroundColor: "#000000dd",
                            borderTopLeftRadius: 10, borderTopRightRadius: 10,
                        }}
                        variant="h4">
                        Profusion.media
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "center",
                            p: 1, color: 'white', backgroundColor: "#0e72a4dd"
                        }}
                        variant="h6">
                        La moisson est grande, et il y a une profusion d'ouvriers
                    </Typography>
                </Box>
            </Box>


            <Stack spacing={4} sx={{
                padding: 5,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                background: "#ffffff99"
            }}>
                <Button
                    color="info"
                    variant="contained"
                    onClick={goConnect}
                >Me connecter</Button>
                <Button
                    color="info"
                    variant="contained"
                    onClick={goRegister}
                >Créer un compte</Button>

            </Stack>
            <Box mt={4} display="flex" flexDirection="column" alignItems="center" gap={3}>
                <a href="https://www.profusion.media/"
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{color: "white"}}>Qui sommes-nous?</a>

                <a href="https://www.profusion.media/politique-de-confidentialite/"
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{color: "white"}}>Politique de confidentialité</a>
            </Box>
        </Container>
    </Container>
}