import React from "react"
import {LanguageInputProps} from "./typings";
import {SelectInput} from "../SelectInput";
import {LanguageDefinition, useAllowedLanguages} from "@profusion.media/profusion-core"
import {Avatar, Stack, Typography} from "@mui/material";

export const LanguageInput = (props: LanguageInputProps) => {
    const {value, onChange, dense, name} = props
    const allowed = useAllowedLanguages()

    return <SelectInput<LanguageDefinition>
        values={allowed}
        value={value}
        dense={dense}
        name={name ?? "language"}
        onChange={onChange}
        renderItem={(item) => {
            return <Stack direction="row" spacing={1}
                          sx={{alignItems: "center"}}>
                <Avatar src={item.flag}
                        sx={dense ? {width: 24, height: 24} : {}}
                        alt={item.label}/>
                <Typography>{item.label}</Typography>
            </Stack>
        }}
        keyExtractor={(item) => item.code}
    />
}