import {WhiteBox} from "@components/WhiteBox";
import {Box, Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

export const DiscoveryGroupCTA = () => {
    const navigate = useNavigate();

    function goContent() {
        navigate("/content")
    }


    return  <WhiteBox title="Les groupes de découverte"
                      titleVariant="h5">
        <Box mb={2}>
            <Typography variant="h6" color="#454545">
            10 leçons à la découverte de Jésus. Notre
            application vous permettra de créer un groupe et
            d'y inviter amis et collègues.
            </Typography>
        </Box>
        <Button variant="contained"
                size={"small"}
                endIcon={<FontAwesomeIcon icon={faChevronRight}/>}
                onClick={goContent}>Découvrez le contenu</Button>

    </WhiteBox>
}