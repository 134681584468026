import {gql} from "@profusion.media/profusion-core";

export const PRAYER_PIN_CREATE_MUTATION = gql`
    mutation prayerPinCreate($input: PrayerPinCreateInput!) {
        prayerPinCreate(input: $input) {
            prayerPin {
                hid
                dateCreated
                color
                prayerRequest {
                    hid
                }
            }
            errors {
                field
                code
            }
        }
    }`;
