import {HelpButtonProps, helpButtonPropsDefaults} from "./typings";
import {IconButton, Popover} from "@mui/material";
import React, {useRef, useState} from "react";

export const HelpButton = (props: HelpButtonProps) => {
    const {content, visible, icon, color} = {...helpButtonPropsDefaults, ...props}
    const [open, setOpen] = useState<boolean>(false)
    const ref = useRef()
    if (!visible) return

    const toggleOpen = () => {
        setOpen(prev => !prev)
    }

    return <React.Fragment>
        <IconButton ref={ref}
                    edge="end"
                    color={color}
                    onClick={toggleOpen}
                    aria-label="help">
            {icon}
        </IconButton>
        <Popover
            anchorEl={ref.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={_ => setOpen(false)}
            open={open}>
            {content}
        </Popover>
    </React.Fragment>
}