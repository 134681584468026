import {gql} from "@apollo/client";

export const GET_COMMUNITY_MEMBERSHIP = gql`
    query getCommunityMembership  {
        getCommunityMembership {
            communityHid
            communityLogo {
                largeUrl
                mediumUrl
                smallUrl
            }
            prayerGroupHid
            communityName
            dateAdded
            isAdmin
            state
            tags {
                color
                name
                tagOrder
                hid
            }
        }
    }`
