import { MutationResponse } from "../../../types";
import { gql } from "@apollo/client";

// STEP 1 : création du token de connexion (tiers -> account)


export type ConnectTokenCreateInput = {
  nextUrl: string
}

export type ConnectTokenResponse = {
  token: string
  loginUrl: string
  success: boolean
}

export type ConnectTokenCreateMutation = MutationResponse<"connectTokenCreate", ConnectTokenResponse>
export const CONNECT_TOKEN_CREATE = gql`mutation ConnectTokenCreate($nextUrl: String!) {
  connectTokenCreate(nextUrl: $nextUrl) {
    token
    loginUrl
    success
  }
}`;

// STEP 2: mise à jour du token avec les informations de session


export type ConnectTokenUpdateInput = {
  exchangeToken: string
  refreshToken: string
}

export type ConnectTokenUpdateResponse = {
  nextUrl: string
  success: boolean
}

export type ConnectTokenUpdateMutation = MutationResponse<"connectTokenUpdate", ConnectTokenUpdateResponse>
export const CONNECT_TOKEN_UPDATE = gql`mutation ConnectTokenUpdate($exchangeToken: String!, $refreshToken: String!) {
  connectTokenUpdate(exchangeToken: $exchangeToken, refreshToken: $refreshToken) {
    nextUrl
    success
  }
}`;


// STEP 3 : sur app tierce, connexion avec le return Token

export type ConnectTokenAuthResponse = {
  accessToken: string
  refreshToken: string
}

export type ConnectTokenAuthInput = {
  exchangeToken: string
}
export type ConnectTokenAuthMutation = MutationResponse<"connectTokenAuth", ConnectTokenAuthResponse>

export const CONNECT_TOKEN_AUTH = gql`mutation ConnectTokenAuth($exchangeToken: String!) {
  connectTokenAuth(exchangeToken: $exchangeToken) {
    refreshToken
    accessToken
  }
}
`;
