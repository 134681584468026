import {CommunityMember, Subscriber} from "@graph";
import {Box} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";

export interface HeadingProps {
    subscriber: Subscriber
}

export const Heading = (props: HeadingProps) => {
    const {subscriber} = props

    return <Box display="flex" flexDirection="row" gap={2} padding={2} alignItems="center">
        <UserAvatar userName={subscriber.user?.displayName}
                    avatar={subscriber.user?.avatar?.mediumUrl}
                    size={64}
        />
        <Box>
            <Typography fontWeight={700}>{subscriber.user?.displayName}</Typography>
        </Box>
    </Box>
}