import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import {Group} from "@graph";
import {CommunityGroupListItem} from "@community/screens/groups/list/CommunityGroupListItem";
import {GroupListItem} from "@groups/Groups/GroupList/components/GroupListItem";

export interface CommunityGroupListProps {
    groups: Group[]
}

export const CommunityGroupList = (props: CommunityGroupListProps) => {
    const {groups} = props
    const navigate = useNavigate();

    return <>
        {groups.map((g: Group) => <GroupListItem loaderRoute="communitiesRoot" group={g} key={g.hid}/>)}
    </>

}