import {FieldErrorBoxProps} from "./typings";
import React from "react";
import {Alert} from "@mui/material";
import {useFormFieldContext} from "../FormValidation";

export const FieldErrorBox = (props: FieldErrorBoxProps) => {
    const {fields, isError} = useFormFieldContext()
    const visible = fields[props.field]?.isError && fields[props.field]?.errorCode === props.errorCode

    return <React.Fragment>{visible ? <Alert severity="error">
        {props.children}
    </Alert> : <></>} </React.Fragment>
}