import {Group, Subscriber} from "@graph";
import {Divider, Drawer, ListItem, Stack} from "@mui/material";
import List from "@mui/material/List";
import {Heading} from "@groups/Groups/GroupDetails/components/SubscriberList/drawer/Heading";
import {
    GroupSubscriberInfoHost
} from "@groups/Groups/GroupDetails/components/SubscriberList/drawer/GroupSubscriberInfoHost";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import {useGroupSubscribers} from "@profusion.media/profusion-core-group/groups/actions/subscripber_remove";
import {useRevalidator} from "react-router-dom";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";

export interface GroupMemberBottomSheetProps {
    subscriber: Subscriber | undefined;
    group: Group
    open: boolean
    onClose: () => void
}

export const GroupMemberBottomSheet = (props: GroupMemberBottomSheetProps) => {
    const {subscriber, open, onClose, group} = props
    const {remove} = useGroupSubscribers(group.hid)
    const {revalidate, state} = useRevalidator();

    const handleDelete = () => {
        if (!subscriber || !group) {
            return
        }
        if (confirm("Êtes-vous sûr ?")) {
            remove(subscriber?.hid).then(_ => {
                revalidate()
                onClose()
            })
        }
    }

    return subscriber && <Drawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        sx={{zIndex: 40001}}
        className="bottom-drawer"
    >
        <Stack spacing={2} direction="column" alignItems="left">
            <Heading subscriber={subscriber}/>
            {!subscriber.isCreator && <List>
                <GroupSubscriberInfoHost subscriber={subscriber}/>
            </List>}
            <List subheader={<ListSubheader><Divider/></ListSubheader>}>

                <ListItem onClick={handleDelete}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faTrash} color="#990000" />
                    </ListItemIcon>
                    <ListItemText primary={<Typography color="#990000" fontWeight={700}>
                        Retirer du groupe
                    </Typography>} />
                </ListItem>
            </List>
        </Stack>

    </Drawer>
}