import React, {ReactElement} from 'react';
import {Grid, IconButton, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {TypographyProps} from "@mui/material/Typography";

const StyledGridRow = styled('div')(({theme}) => ({
    padding: theme.spacing(1),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    '&:hover': {
        backgroundColor: theme.palette.action.hover
    },
}))

const StyledChevronIcon = styled(ChevronRightIcon)(({theme}) => ({
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.secondary,
}));

interface GridRowProps {
    label: string;
    value: string;
    right?: ReactElement,
    onEdit: () => void;
    color?: TypographyProps['color']
}

const GridRow: React.FC<GridRowProps> = ({label, value, onEdit, right, color = 'default'}) => {

    return (
        <StyledGridRow onClick={onEdit}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <Typography
                        color={color}
                        variant="subtitle1">{label}:</Typography>
                </Grid>
                <Grid item xs={6} textOverflow="ellipsis" overflow="clip">
                    <Typography>{value}</Typography>
                </Grid>
                <Grid item xs={2}>
                    {right ? right : <IconButton color="primary" size="small">
                        <StyledChevronIcon/>
                    </IconButton>}
                </Grid>
            </Grid>
        </StyledGridRow>
    );
}

export default GridRow;