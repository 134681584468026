import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Organization, Community} from "@profusion.media/graph";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {ORGANIZATION_DETAILS_QUERY} from "../schema/queries/organization_details";
import {ORGANIZATION_COMMUNITIES_QUERY} from "../schema/queries/organization_communitites";
import {ORGANIZATION_CHILDREN_QUERY} from "@organizations/schema/queries/organization_children";

const _loadOrganizationDetails = (client: ProfusionClient, hid: string): GraphQueryPromise<Organization> => {
    return executeQuery<Organization>(client, ORGANIZATION_DETAILS_QUERY, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}

const _loadOrganizationCommunities = (client: ProfusionClient, hid: string) : GraphQueryPromise<Community[]> => {
    return executeQuery<Community[]>(client, ORGANIZATION_COMMUNITIES_QUERY, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}
const _loadOrganizationChildren = (client: ProfusionClient, hid: string) : GraphQueryPromise<Organization[]> => {
    return executeQuery<Organization[]>(client, ORGANIZATION_CHILDREN_QUERY, {
        isPrivate: true,
        variables: {
            parent: hid
        }
    })
}

const loadData = (hid: string) => {
    return {
        organization: _loadOrganizationDetails(profusion, hid),
        communities: _loadOrganizationCommunities(profusion, hid),
        children: _loadOrganizationChildren(profusion, hid)
    }
}


export const loadOrganizationDetails = (props: LoaderFunctionArgs<["hid"]>) => {
    return defer(loadData(props.params.hid ?? ""))
}

export const useOrganizationDetailsLoader = (routeId?: string) => {
    if (routeId) {
        return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
    } else {
        return useLoaderData() as ReturnType<typeof loadData>
    }
}

export const useOrganizationDetails = () => {
    return (hid: string) => _loadOrganizationDetails(profusion, hid) as ReturnType<typeof _loadOrganizationDetails>
}

export type OrganizationLoaderReturn = ReturnType<typeof loadData>