import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const GET_USER_IDENTITY = gql`
    ${USER_FIELDS}
    query getUserIdentity($hids: [String]!) {
        getUserIdentity(hids: $hids) {
            ...UserFields
        }
    }`;
