import {gql} from "@apollo/client";

export const CONTENT_FRAGMENT = gql`
    fragment CONTENT_FRAGMENT on Content {
        hid
        description
        dateAdded
        title
        dateUpdated
        contentUrl
        isPublic
        author {
            lastName
            firstName
            email
            avatar {
                smallUrl
                mediumUrl
                largeUrl
            }
        }
        tags {
            hid
            name
            tagOrder
            color
        }
        organization {
            hid
            name            
            avatar {
                largeUrl
                mediumUrl
                smallUrl
            }
        }
    }`