import {gql} from "@profusion.media/profusion-core";

export const ACCOUNT_PASSWORD_UPDATE_RESET = gql`
  mutation($input: PasswordUpdateResetInput!) {
    passwordUpdateReset(input: $input) {
      __typename
      success
      errors {
        code
        field
        group
      }
    }
  }`;
