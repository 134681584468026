import React, {createContext} from "react";
import {ValidationErrors} from "@profusion.media/profusion-core"

export interface FormContextProps {
    loading: boolean
    updating: boolean
    canSubmit?: boolean
    onSubmit?: () => void
    onCancel?: () => void
    errors?: ValidationErrors
}

export const FormContext = createContext<FormContextProps>({
    loading: false,
    updating: false,
    canSubmit: true
})

interface FormContextProviderProps extends FormContextProps {
    children: React.ReactNode
}

export const FormContextProvider = (props: FormContextProviderProps) => {
    const {children, ...contextProps} = props
    return <FormContext.Provider value={contextProps}>
        {children}
    </FormContext.Provider>
}