import React, {useContext, useEffect} from "react"
import {PhoneInputProps} from "./typings";
import {MuiTelInput, MuiTelInputCountry} from 'mui-tel-input'
import {useFormFieldError} from "../Form";
import {FormStepContext} from "../MultiPartForm";
import {useFormFieldContext} from "../FormValidation";


export const PhoneInput = (props: PhoneInputProps) => {
    const {value, country, label, name, onValueChange} = props
    const stepInfo = useContext(FormStepContext)
    const error = useFormFieldError(props.name)
    const context = useFormFieldContext()

    const defaultCountry: MuiTelInputCountry = country as MuiTelInputCountry ?? "CA"

    useEffect(() => {
        updateField()
    }, []);

    useEffect(() => {
        updateField()
    }, [props.value]);

    const updateField = () => {
        context.onChange({
            type: "phone",
            isError: false,
            tags: [stepInfo.stepId],
            name: props.name,
            value: props.value,
            isValid: !props.required || !!props.value
        })
    }


    return <MuiTelInput
        required={props.required}
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        error={error.isError}
        defaultCountry={defaultCountry}
        onChange={(value, info) => onValueChange(name, info.numberValue)}
        preferredCountries={['CA', 'FR', 'US']}
    />
}