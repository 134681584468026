import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";
import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/pro-light-svg-icons";
import {captureStackTrace} from "@apollo/client/testing/internal/profile/traces";

const ErrorPage = () => {
    const error = useRouteError() as TypeError;
    const navigate = useNavigate();

    console.error(error, typeof error);

    const ErrorMessage = () => {
        if (isRouteErrorResponse(error)) {
            return <Box>
                <Typography variant={"h6"} component="div" >{error.status}</Typography>
                <Typography variant={"body2"} component="div" >{error.statusText}</Typography>
            </Box>
        } else {
            return <Box overflow="auto" textOverflow="ellipsis">
                <Typography variant="h6" component="div" >{error.message && error.message}</Typography>
                <Typography variant="body2" component="div" textOverflow="ellipsis" >

                {error.stack && error.stack.split("\n")[1]}

            </Typography>
            </Box>
        }
    }


        return <Box p={4}>
            <Typography variant={"h5"}>Une erreur est survenue</Typography>
            <Box sx={{
                mt: 2,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: 1,
                borderColor: "#99000022",
                p: 2,
                backgroundColor: "#99000011",
            }}>
                <ErrorMessage  />
            </Box>
        <Box mt={2} mb={4} color={"#777"}>
            <Typography variant={"body2"}>
                Communiquez avec les développeurs par email : info@profusion.media
            </Typography>
        </Box>

            <Button startIcon={<FontAwesomeIcon icon={faRefresh}/>}
                    variant="contained"
                    onClick={_ => navigate("/")}>
                Relancer l'application
            </Button>
        </Box>;

}

export default ErrorPage