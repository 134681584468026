import React, {startTransition, useEffect, useState} from "react";
import {PrayerCommentCreateInput} from "@profusion.media/profusion-core-prayer";
import {CancelButton, Form, FormErrorBox, FullScreenDialog, SubmitButton} from "@profusion.media/profusion-ui";
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {useActionData, useLocation, useNavigate, useNavigation, useParams, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import '@styles/editor.css'
import {useTranslation} from "react-i18next";
import {BodyContainer} from "@components/BodyContainer";

export const PrayerCommentForm = () => {
    const {rhid: requestHid} = useParams()
    const {t} = useTranslation()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'
    const location = useLocation()
    const [previousUrl, setPreviousUrl] = useState("..")

    useEffect(() => {
        setPreviousUrl(location.state?.from || "..")
    }, []);

    const emptyRequest: PrayerCommentCreateInput = {
        comment: "",
        requestHid: requestHid ?? "",
    }
    const [data, setData] = useState<PrayerCommentCreateInput>(emptyRequest)

    const onChange = (name: string, value: string) => {
        setData(prev => ({...prev, [name]: value}))
    }

    const handleSubmit = () => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        startTransition(() => {
            navigate(previousUrl)
        })
    }

    return <FullScreenDialog title="Nouveau commentaire"
                             bodyContainer={BodyContainer}
                             onClose={handleCancel}>
        <Form onSubmit={handleSubmit}
              onCancel={handleCancel}
              errors={validationErrors}
              updating={loading}
        >
            <FormErrorBox message="Des erreurs sont survenues"/>
            <Card>
                <CardContent>
                    <Stack spacing={4}>
                        <ReactQuill
                            placeholder={t("prayer.request.form.comment.placeholder", "Écrivez votre commentaire ici") as string}
                            value={data.comment}
                            onChange={value => onChange("comment", value)}
                            theme="snow"
                        />
                    </Stack>
                </CardContent>
                <CardActions sx={{flexDirection: "row-reverse", paddingY:4}}>

                    <SubmitButton/>
                    <CancelButton/>
                </CardActions>
            </Card>
        </Form>
    </FullScreenDialog>
}