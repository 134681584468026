import {Box, Button, Dialog, DialogContent, DialogTitle, useTheme} from "@mui/material";
import {faCheck} from "@fortawesome/pro-solid-svg-icons"
import {faCheckCircle, faPrayingHands} from "@fortawesome/pro-light-svg-icons"
import {faCaretLeft} from "@fortawesome/sharp-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {PrayerRequest} from "@profusion.media/profusion-core-prayer";
import {useAddPrayerEvent} from "../actions/prayer_add_prayed_event";
import {LoadingIcon} from "@components/icons/LoadingIcon";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

export interface PrayerPrayedButtonProps {
    request: PrayerRequest
    onComplete?: () => void
    count?: number
    showStatsButton?: boolean
}

const PrayIcon = () => {
    return <FontAwesomeIcon icon={faPrayingHands} fixedWidth/>
}
const CheckIcon = () => {
    return <FontAwesomeIcon icon={faCheck} fixedWidth/>
}

interface ThankYouButtonProps {
    width: number
}

interface SquareWithNumberProps {
    color: string,
    number?: number
}

const SquareWithNumber = ({color, number}: SquareWithNumberProps) => {
    if (number === undefined) return <></>
    return <Box sx={{backgroundColor: color, marginLeft: 1, width: 24, height: 24, position: 'relative'}}>
        <FontAwesomeIcon icon={faCaretLeft}
                         color={color}
                         fixedWidth
                         style={{position: 'absolute', left: -10, top: '50%', transform: 'translateY(-50%)'}}/>
        <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <Typography variant="caption">{number}</Typography>

        </Box>
    </Box>
}


const ThankYouButton = (props: ThankYouButtonProps) => {
    const {t} = useTranslation()
    return <Button
        size="small"
        color="success"
        variant="text"
        startIcon={<CheckIcon/>}>
        {t("prayer.request.button.thanks", "Merci !")}
    </Button>
}

export const PrayerPrayedButton = (props: PrayerPrayedButtonProps) => {
    const [success, setSuccess] = useState<boolean>(false)
    const {request, onComplete} = props
    const {t} = useTranslation()
    const addPayedEvent = useAddPrayerEvent(request)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const theme = useTheme()

    const handleSubmit = () => {
        setLoading(true)
        addPayedEvent().then(() => {
            setLoading(false)
            setSuccess(true)
            //setTimeout(() => setSuccess(false), 2000)
        }).then(_ => {
            if (onComplete) onComplete()
        })
    }

    const openStats = () => {
        navigate("stats")
    }

    const handleClose = () => {
        setSuccess(false)
    }

    return <Box sx={{display: "flex", alignItems: "center"}}>{success ? <>
            <ThankYouButton width={140}/>
            <Dialog open={success} onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    sx={{
                        alignContent: "center",
                        paddingY: 8,
                        zIndex: 40005
                    }}>
                <DialogTitle
                    align="center"
                    fontSize={32}
                    color={theme.palette.success.main}>Merci !</DialogTitle>
                <DialogContent sx={{textAlign: "center"}}>
                    <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} size="4x"/>
                </DialogContent>
            </Dialog>
        </>
        : <Button
            size="small"
            variant="text"
            color="inherit"
            onClick={handleSubmit}
            startIcon={loading ? <LoadingIcon/> : <PrayIcon/>}>{t("prayer.request.button.prayed", "J'ai prié")}</Button>
    }
    </Box>
}