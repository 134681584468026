import {Group, GroupUpdateInput, MutationGroupUpdateArgs} from "@profusion.media/profusion-core-group";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useActionData, useLoaderData, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {GroupSettingsForm, GroupSettingsHelp} from "../../../components/settings";
import {LoadGroupReturn} from "../../loaders";
import {Suspense, useEffect} from "react";


interface GroupSettingsRedirectProps {
    group: Group
}

const GroupSettingsRedirect = (props: GroupSettingsRedirectProps) => {
    const {group} = props
    const navigate = useNavigate()
    useEffect(() => {
        if (group.communityHid) {
            navigate(`/community/admin/${group.communityHid}/groups/${group.mainFeature}/${group.hid}/update`)
        }
    }, []);

    return <></>
}

export const GroupSettingsUpdate = () => {
    const {group: groupData} = useLoaderData() as LoadGroupReturn
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()

    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'



    const handleSubmit = (data: any) => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(-1)
    }

    return <FullScreenDialog title="Mettre à jour"
                             showHelp
                             helpContent={<GroupSettingsHelp/>}
                             onClose={handleCancel}>
        <Suspense>
        <Await resolve={groupData}>
            {({data: group}) => <>
                <GroupSettingsRedirect group={group} />
                <GroupSettingsForm
                group={group}
                mode="update"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                loading={loading}
                validationErrors={validationErrors}/></>
            }
        </Await>
        </Suspense>
    </FullScreenDialog>
}