import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {CommunityMembership} from "@graph";
import {defer, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {GET_COMMUNITY_MEMBERSHIP} from "@community/schema/queries/get_community_membership";

export const communityMembershipQuery = (client: ProfusionClient): GraphQueryPromise<CommunityMembership[]> => {
    return executeQuery<CommunityMembership[]>(client, GET_COMMUNITY_MEMBERSHIP, {
        isPrivate: true
    })
}


const _loadData = () => {
    return {
        membership: communityMembershipQuery(profusion)
    }
}


export const loadCommunityMembership = () => {
    return defer(_loadData())
}


export const useCommunityMembershipLoader = (routeId: string) => {
    return useRouteLoaderData(routeId) as ReturnType<typeof _loadData>
}
