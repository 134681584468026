import {useFetcher, useNavigate, useSubmit} from "react-router-dom";
import {Container, ListItem} from "@mui/material";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/pro-regular-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import React, {useEffect} from "react";
import {GroupAppBarSettingsProps} from "@groups/Groups/components/groupScreen/GroupAppBarSettings";
import {GroupDeleteMutation, GroupLeaveMutation} from "@graph";

export const GroupBarSettingsMine = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const {data, submit, state} = useFetcher<GroupDeleteMutation>();
    const navigate = useNavigate()

    const handleDelete = () => {
        if (confirm("Êtes-vous sûr de vouloir supprimer ce groupe ?")) {
            submit({
                input: {
                    hid: group.hid,
                }
            }, {
                action: `/groups/${group.mainFeature}/${group.hid}/delete`,
                method: "POST",
                encType: "application/json",
            })
        }
    }
    const handleEdit = () => {
        navigate("edit")
    }


    useEffect(() => {
        if (state === 'idle' && data) {
            if (data.success) {
                navigate(`/groups/${group.mainFeature}/mine`, {
                    replace: true,
                })
            }
        }
    }, [data, state])

    return <Container maxWidth="lg" disableGutters>
        <List
            sx={{pl: 2, width: '100%', bgcolor: 'background.paper'}}
            subheader={<ListSubheader>Paramètres</ListSubheader>}
        >
            <ListItem onClick={handleEdit}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faEdit} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Mettre à jour"/>
            </ListItem>

            <ListItem sx={{color: "#ff0000"}} onClick={handleDelete}>
                <ListItemIcon>
                    <FontAwesomeIcon color="#ff0000" icon={faTrash} fixedWidth/>
                </ListItemIcon>
                <ListItemText primary="Supprimer le groupe"/>
            </ListItem>
        </List>
    </Container>
}