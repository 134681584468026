import {gql} from "@profusion.media/profusion-core";

export const NOTIFICATION_MESSAGE_FIELDS = gql`
  fragment NotificationMessageFields on NotificationMessage {
    __typename
    hid
    action
    avatar {
      smallUrl
    }
    title
    body
    createdAt
    readAt
  }
`
