import {useUserAvailableContentLoader} from "@community/loaders/userAvailableContent";
import {Await, useNavigate} from "react-router-dom";
import {Badge, Box, Chip, ListItem, ListItemIcon} from "@mui/material";
import {faBooks} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";

export const UserContentMenu = () => {
    const {content} = useUserAvailableContentLoader("appRoot")
    const navigate = useNavigate();

    return <Await resolve={content}>
        {({data: content}) => content && <ListItem onClick={_ => navigate("/content")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faBooks}/>
            </ListItemIcon>
            <ListItemText
                primary={<Box display="flex" alignItems="center">
                    <Typography sx={{pr:2}}>Mes contenus</Typography>
                    <Box bgcolor={"#9c27b0"}
                         padding="1px 8px"
                         borderRadius={8}
                         color="#fff"
                         fontSize={12}
                         alignItems="center"
                         display="inline-flex">{content.length}</Box>
            </Box>}
            />
        </ListItem>}
    </Await>
}