import {Container, Paper, Stack} from "@mui/material";
import {faUsersSlash} from "@fortawesome/pro-duotone-svg-icons"
import {DuoToneIcon} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";

export const HomeEmptyGroupsShared = () => {

    return <Paper sx={{padding: 3, pb:4}}>
        <Container>
            <Stack direction="column" spacing={4} alignItems="center">
                <DuoToneIcon
                    size="5x"
                    style={{
                        "--fa-primary-color": "#08348c",
                        "--fa-secondary-color": "#1186c5"
                    }}
                    icon={faUsersSlash}/>

                <Stack spacing={2}>
                    <Typography variant="h5">Vous n'avez pas encore été invité dans un groupe</Typography>
                    <Typography variant="h6" color="#777">
                        Ici apparaîtront les groupes dans lesquels vous avez été invité à participer.
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    </Paper>

}