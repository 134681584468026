import { DocumentNode, useApolloClient } from "@apollo/client";
import { getMutationNames } from "../utils";
import { GraphMutationOptions, MutationHookResult, MutationResult, MutationStates, ValidationErrors } from "../typings";
// noinspection ES6PreferShortImport
import { useAuthentication } from "../../core/hooks/authentication";
import { useEffect, useState } from "react";

export const useGraphMutation = <TData, TVariables>(
  mutation: DocumentNode,
  options: GraphMutationOptions<TData, TVariables>
): MutationHookResult<TData, TVariables | { [R: string]: TVariables }> => {
  console.log("DEPRECATION NOTICE using old useGraphMutation")
  const client = useApolloClient();
  const { is_refreshing, session } = useAuthentication();
  const mutationName = getMutationNames(mutation)[0];
  const [states, setStates] = useState<MutationStates<TData, TVariables>>({
    data: undefined,
    isRefreshing: is_refreshing,
    error: undefined,
    loading: false,
    validationErrors: undefined
  });

  function mutate(variables?: TVariables | { [R: string]: TVariables }) {
    return client.mutate<TData, TVariables | { [R: string]: TVariables }>({
      mutation,
      variables,
      context: { use_private: options.isPrivate },
      refetchQueries: (_r) =>  {
        if (options.refetchQueries && _r.data?.[mutationName]) {
          if (typeof options.refetchQueries === "function") {
            return options.refetchQueries(_r.data?.[mutationName], variables)
          } else {
            return options.refetchQueries
          }
        } else {
          return []
        }
      },
      //awaitRefetchQueries: true,
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      }
    });
  }

  function executeMutation(variables?: TVariables | { [R: string]: TVariables }): Promise<MutationResult<TData>> {
    updateResponse({ loading: true });
    console.log("DEPRECATION NOTICE using old executeMutation")
    try {

          return mutate(variables).then(response => {
            const _r : any = response.data?.[mutationName];
            updateResponse({ loading: false, data: _r as TData, validationErrors: _r?.errors as ValidationErrors });
            return {
              data: _r,
              validationErrors: _r?.errors
            };
          });


    } catch (error) {
      console.error("[DEPRECATED] Error executing mutation:", JSON.stringify(error));
    }
  }

  const updateResponse = (newResponse: Partial<MutationStates<TData, TVariables>>) => {
    setStates(prevResponse => ({ ...prevResponse, ...newResponse }));
  };


  return [executeMutation, { ...states }];
};


