import React from "react";
import {WhiteBox} from "@components/WhiteBox";
import {CommunityOrganizationInfo} from "@community/screens/JoinCommunity/CommunityOrganizationInfo";
import Typography from "@mui/material/Typography";
import {Box, Button} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CommunityJoinButton} from "@community/screens/JoinCommunity/CommunityJoinButton";
import {Community} from "@graph";
import {useNavigate} from "react-router-dom";

export interface CommunityJoinProps {
    community: Community;
}

export const CommunityJoin = (props: CommunityJoinProps) => {
    const {community} = props
    const navigate = useNavigate();
    const connectedUser = useConnectedUser()

    return <WhiteBox justifyContent="center" flexDirection="column" display="flex">
        <Box display="flex"
             flexDirection="column"
             alignItems="center"
             gap={5}
             paddingY={4}
        >
            <Typography variant="h5"
                        textAlign="center"
                        color="textSecondary" component="div">
                Vous avez été invité !
            </Typography>

            <Box display="flex" flexDirection="row"
                 alignContent="center"
                 justifyContent="space-evenly"
                 width="100%"
                 gap={1}
            >
                <Box flex={1}
                     display="flex"
                     flexDirection="column"
                     alignItems="center"
                >
                    <UserAvatar size={64}
                                userName={connectedUser?.firstName + " " + connectedUser?.lastName}
                                avatar={connectedUser?.profile?.avatar?.smallUrl}/>
                    <Typography variant="body1">Vous</Typography>
                </Box>

                <Box flex="0 1 32px" textAlign='center' pt={2}>
                    <FontAwesomeIcon icon={faArrowRight} size="2xl"/>
                </Box>
                <Box flex={1}>
                    <CommunityOrganizationInfo community={community}/>
                </Box>
            </Box>
            <CommunityJoinButton community={community}/>
            <Button
                onClick={_ => navigate("/")}
                variant="text" color="inherit">Plus tard</Button>
        </Box>
    </WhiteBox>
}