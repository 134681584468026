import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilterList} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {Community, CommunityTag} from "@graph";
import {TagChip, tagNone} from "@community/components/TagChip";

export interface CommunityMemberListFilterProps {
    filter: CommunityTag | undefined
    onChange: (filter: CommunityTag | undefined) => void
    community: Community
}

export const CommunityMemberListFilter = (props: CommunityMemberListFilterProps) => {
    const {filter, onChange, community} = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSelect = (option: CommunityTag | undefined) => {
        setAnchorEl(null);
        onChange(option)
    };

    const handleClose = () => {
        setAnchorEl(null);
    }



    return <div>
        <IconButton  onClick={handleClick} color={filter ? "primary" : "inherit"}>
            <FontAwesomeIcon icon={faFilterList} />
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={_ => handleClose()}
        >
            <MenuItem key="0"
                      dense
                      onClick={() => {handleSelect(undefined)}}>
                Tous les membres
            </MenuItem>
            <MenuItem key="0"
                      dense
                      onClick={() => {handleSelect(tagNone)}}>
                <TagChip tag={tagNone} />
            </MenuItem>
            {community.tags.map((option) => (
                option && <MenuItem key={option.hid}
                                    dense
                                    onClick={_ => handleSelect(option)}>
                    {<TagChip tag={option} />}
                </MenuItem>
            ))}
        </Menu>
    </div>
}