import {InvitationOptionProps} from "./typings";
import {WhiteBox} from "@components/WhiteBox";
import {Box, Button, Stack, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faShareAlt} from "@fortawesome/pro-regular-svg-icons";
import {faQrcode} from "@fortawesome/pro-light-svg-icons";
import {Share} from "@capacitor/share"
import {useNavigate} from "react-router-dom";
import {ClipboardCopyButton} from "@components/ClipboardCopyButton";
import Typography from "@mui/material/Typography";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";

export const ShareLinkOption = (props: InvitationOptionProps) => {
    const {url, group} = props
    const navigate = useNavigate()
    const user = useConnectedUser()

    const onShare = () => {
        Share.share({
            url: url,
            text: group.mainFeature === 'prayer'
                ? user.profile.displayName+" vous invite à joindre son groupe de prière sur Profusion. En cliquant sur le lien, vous téléchargerez l’application et serez en mesure de vous créer un compte gratuitement. Ensuite, cliquez à nouveau sur ce lien et voilà! Vous serez dirigé dans le groupe de prière."
                : user.profile.displayName+' vous invite à joindre un groupe de découverte de Jésus sur Profusion. En cliquant sur le lien, vous téléchargerez l’application et serez en mesure de vous créer un compte gratuitement. Ensuite, cliquez à nouveau sur ce lien, et voilà! Vous aurez maintenant accès au contenu du groupe de découverte.'
        })
    }
    const onQR = () => {
        navigate("../qr")
    }

    return <WhiteBox title="Lien de partage" icon={<FontAwesomeIcon icon={faLink}/>}>
        <Box>
            <Typography variant="body2" color="textSecondary">
                Vous pouvez inviter vos partenaires de prière en leur transmettant le lien suivant :
            </Typography>
        </Box>
        <TextField value={url}
                   variant="outlined"
                   fullWidth
                   margin="dense"
                   InputProps={{
                       endAdornment: <ClipboardCopyButton value={{url}}/>
                   }}
        />
        <Stack direction="row" mt={2} spacing={2}>
            <Button
                variant="outlined"
                onClick={onQR}
                startIcon={<FontAwesomeIcon icon={faQrcode}/>}
            >Code QR</Button>
            <Button
                variant="outlined"
                onClick={onShare}
                startIcon={<FontAwesomeIcon icon={faShareAlt}/>}
            >Partager</Button>
        </Stack>
    </WhiteBox>
}