import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChurch} from "@fortawesome/pro-light-svg-icons";
import Typography from "@mui/material/Typography";
import {faBuildings} from "@fortawesome/pro-regular-svg-icons";


export const SuperAdminSpeedDial = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const {org_type} = useParams();

    console.log(org_type);

    const actions = org_type == "church"
        ? [
            {
                icon: <FontAwesomeIcon icon={faChurch}/>,
                name: 'Ajouter une église',
                onClick: () => navigate("/su/church/add")
            },

        ] : [
            {
                icon: <FontAwesomeIcon icon={faBuildings}/>,
                name: 'Ajouter une dénomination',
                onClick: () => navigate("/su/denomination/add")
            },
        ]

    return <SpeedDial
        ariaLabel="SpeedDial basic example"
        key={location.pathname}
        className="speed-dial-groups"
        icon={<SpeedDialIcon/>}>
        {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                onClick={action.onClick}
                tooltipOpen
                tooltipTitle={<Typography variant="caption" noWrap>{action.name}</Typography>}
            />
        ))}
    </SpeedDial>

}