import {Box, BoxProps, Stack, TypographyVariant} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {ReactNode} from "react";

export interface WhiteBoxProps extends Omit<BoxProps, "bgcolor" | "marginBottom" | "padding"> {
    title?: string
    subtitle?: string
    icon?: any
    secondaryAction?: ReactNode
    titleVariant?: TypographyVariant
    subtitleVariant?: TypographyVariant
    titleWeight?: number | string
    padding?: number
}

export const WhiteBox = (props: WhiteBoxProps) => {
    const {
        children, icon,
        title, titleVariant,
        subtitle, subtitleVariant,
        secondaryAction, padding,
        ...rest
    } = props;
    return <Box position="relative" {...rest} marginBottom={1} padding={padding ?? 2} bgcolor="#fff">
        {secondaryAction && <Box position="absolute" top={20} right={20}>{secondaryAction}</Box>}
        {title && <Stack alignItems="center" direction="row" spacing={1} mb={2}>
            {icon}
            <Stack direction="column" spacing={0}>
                <Typography
                    variant={titleVariant ?? "overline"}>{title}</Typography>
                {subtitle && <Typography color="#777" variant={subtitleVariant ?? "overline"}>{subtitle}</Typography>}
            </Stack>

        </Stack>}
        {children}
    </Box>
}