import {Group} from "@profusion.media/profusion-core-group"
import {Box, Drawer, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import React, {useState} from "react";
import {GroupBarSettingsMine} from "@groups/Groups/components/groupScreen/GroupAppBarSettingsMine";
import {GroupBarSettingsShared} from "@groups/Groups/components/groupScreen/GroupAppBarSettingsShared";

export interface GroupAppBarSettingsProps {
    group: Group
}

export const GroupAppBarSettings = (props: GroupAppBarSettingsProps) => {
    const {group} = props
    const [open, setOpen] = useState(false)

    const handleSettings = async () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    }

    return <>
        <IconButton onClick={handleSettings}>
            <FontAwesomeIcon color="#f0f0f0" icon={faGear}/>
        </IconButton>

        <Drawer anchor="bottom"
                open={open}
                onClose={handleClose}
                sx={{zIndex: 40001}}
        >
            <Box sx={{pb:4}}>
            {group.userRole?.isHost || group.userRole?.isCreator || group.userRole?.isCommunityAdmin
                ? <GroupBarSettingsMine group={group} />
                : <GroupBarSettingsShared group={group} />}
            </Box>
        </Drawer>
    </>
}


