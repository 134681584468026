import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Stack,} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    CancelButton,
    Form,
    FormErrorBox,
    FullScreenDialog,
    HeadsUp,
    LanguageInput,
    SubmitButton,
} from "@profusion.media/profusion-ui";
import {RefreshRounded} from "@mui/icons-material";
import {AccountLanguageInput, useAccountLanguageUpdate, useConnectedUser} from "@profusion.media/profusion-iam-user";
import {useTranslation} from "react-i18next";
import {faLanguage} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import {Language, useProfusionClient} from "@profusion.media/profusion-core"

const FormLanguage: React.FC = () => {
    const user = useConnectedUser()
    const language = (user?.language || 'fr-FR') as Language

    const {t} = useTranslation()
    const navigate = useNavigate();

    const [fields, setFields] = useState<AccountLanguageInput>({
        language
    })

    const [update, {loading: updating, data, validationErrors: errors}] = useAccountLanguageUpdate()

    useEffect(() => {
        if (user) {
            setFields({
                language
            })
        }
    }, [user])

    const handleValueChange = (name: string, value: string) => {
        setFields({...fields, [name]: value})
    }

    const closeDialog = () => {
        navigate('..', {replace: true});
    }

    const handleSave = async () => {
        const r = await update({input: fields})
        if (!r?.validationErrors) {
            closeDialog()
        }
    };

    const handleCancel = () => {
        setFields({
            language
        });
        closeDialog()
    };


    return (
        <FullScreenDialog title={t("account.form.language.title", "Langue")} onClose={closeDialog}>
            <Form onSubmit={handleSave}
                  onCancel={handleCancel}
                  errors={errors}
                  updating={updating}
                  autoClose>
                <FormErrorBox/>
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <LanguageInput value={fields.language}
                                           onChange={(lang: Language) => handleValueChange("language", lang)}/>
                        </Stack>

                        <HeadsUp sx={{marginTop: 4}}>
                            <FontAwesomeIcon icon={faLanguage}/>
                            <Typography>Votre choix de langue sera effectif après validation du formulaire et
                            s'appliquera à l'interface utilisateur web et mobile, ainsi que toutes les communications
                            que nous vous enverrons.</Typography>
                        </HeadsUp>
                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <CancelButton />
                        <SubmitButton
                            activityIcon={<RefreshRounded/>}
                            iconPosition="left"/>
                    </CardActions>
                </Card>
            </Form>
        </FullScreenDialog>
    );
};

export default FormLanguage;
