import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {CommunityMember} from "@graph";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "@config/profusion";
import {GET_COMMUNITY_MEMBERS} from "../schema/queries/get_community_details";

const _loadCommunityMembers = (client: ProfusionClient, hid: string): GraphQueryPromise<CommunityMember> => {
    return executeQuery<CommunityMember>(client, GET_COMMUNITY_MEMBERS, {
        isPrivate: true,
        variables: {
            hid
        }
    })
}


const loadData = (hid: string) => {
    return {
        members: _loadCommunityMembers(profusion, hid)
    }
}


export const loadCommunityMembers = (props: LoaderFunctionArgs<["community"]>) => {
    return defer(loadData(props.params.community ?? ""))
}

export const useCommunityMembersLoader = (routeId?: string) => {
    if (!routeId) {
        return useLoaderData() as ReturnType<typeof loadData>
    } else {
        return useRouteLoaderData(routeId) as ReturnType<typeof loadData>
    }
}
