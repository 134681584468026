import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation} from "@profusion.media/profusion-core";
import {CommunityInviteInput, CommunityInviteResponse, MutationCommunityInviteArgs} from "@graph";
import {COMMUNITY_INVITE_MUTATION} from "@community/schema/mutations/community_invite";
import profusion from "@config/profusion";

export const actionCommunityInvite = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as CommunityInviteInput

    const response = await executeMutation<CommunityInviteResponse, MutationCommunityInviteArgs>(
        profusion,
        COMMUNITY_INVITE_MUTATION,
        {input: data},
        {
            isPrivate: true
        })
    return response.data
}
