import {useNavigate, useParams} from "react-router-dom";
import {Group} from "@graph";

export const useNavigateDetails = () => {
    const navigate = useNavigate()
    return (group: Group) => navigate(`/groups/${group.mainFeature}/${group.hid}`, {
        state: {
            from: window.location.pathname
        }
    })
}

export const useNavigateEdit = () => {
    const navigate = useNavigate()
    return (group: Group) => navigate(`/groups/${group.mainFeature}/${group.hid}/edit`)
}

export const useNavigateManageContent = () => {
    const navigate = useNavigate()
    return (group: Group) => navigate(`/groups/${group.mainFeature}/${group.hid}/content/manage`)
}

export const useNavigateInvite = () => {
    const navigate = useNavigate()
    return (group: Group) => navigate(`/groups/${group.mainFeature}/${group.hid}/invite`)
}

export type GroupTab = "prayer" | "settings" | "subscribers" | "about" | "content" | "community"

export const useNavigateDetailsTab = (group: Group) => {
    const navigate = useNavigate()
    return (tab: GroupTab) => navigate(`/groups/${group.mainFeature}/${group.hid}/${tab}`, {
        replace: true
    })
}
export const useNavigationCreateGroup = () => {
    const navigate = useNavigate()
    const {group_type} = useParams()
    return () => navigate(`/groups/${group_type}/add`)
}

export const useNavigationInvitationList = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/invitations`)
}

export const useNavigationInvitation = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/invitations/add`)
}

export const useNavigationBack = () => {
    const navigate = useNavigate()
    return (folder: string) => navigate(`/groups/${folder === 'own' ? 'mine' : 'shared'}`)
}