import {Await, Outlet, useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";
import {AppBar, Container, IconButton, Toolbar} from "@mui/material";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Suspense, useContext, useEffect, useState} from "react";
import {LoadDetailsReturn} from "../../GroupDetails/loaders";
import {GroupAppBarSettings} from "./GroupAppBarSettings";
import Typography from "@mui/material/Typography";
import {BodyContainer} from "@components/BodyContainer";
import {ScreenContainer} from "@components/ScreenContainer";
import {Group} from "@graph";
import {DashboardAppContext} from "@groups/DashboardApp";


interface GroupBottomNavSetterProps {
    group: Group
}

const GroupBottomNavSetter = (props: GroupBottomNavSetterProps) => {
    const context = useContext(DashboardAppContext)
    const {group} = props

    useEffect(() => {
        console.log(group)
        if (group.folder === 'own') {
            context.setBottomTab(0)
        } else {
            context.setBottomTab(1)
        }
    }, [group]);

    return <></>
}

export const GroupScreen = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [previousUrl, setPreviousUrl] = useState("")
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn

    useEffect(() => {
        if (location.state?.from) {
            setPreviousUrl(location.state?.from)
        } else {
            setPreviousUrl("")
        }
    }, []);

    const handleBack = (group: Group) => {
        if (previousUrl) {
            navigate(previousUrl)
        } else {
            navigate(`/groups/${group.mainFeature}/mine`)
        }
    }


    return <Suspense>
        <Await resolve={groupData}>
            {({data: group}) =>
                <ScreenContainer>
                    <GroupBottomNavSetter group={group} />
                    <AppBar className="profusionAppBar"
                            position="fixed">
                        <Container maxWidth="lg">
                            <Toolbar disableGutters>
                                <IconButton onClick={_ => handleBack(group)}>
                                    <FontAwesomeIcon color="#f0f0f0" icon={faArrowLeft}/>
                                </IconButton>
                                <Typography flexGrow={1}></Typography>
                                <GroupAppBarSettings group={group}/>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <BodyContainer maxWidth="lg" disableGutters>
                        <Outlet/>
                    </BodyContainer>
                </ScreenContainer>
            }
        </Await>
    </Suspense>
}