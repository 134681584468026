import React, { useCallback, useContext, useEffect, useState } from "react";
import { ProfusionClient } from "../client";
import { AuthenticationProvider } from "./authentication";
import { ApolloProvider } from "@apollo/client";
import { Language } from "../../config/language";


interface ProfusionProviderProps {
  client: ProfusionClient;
  children: React.ReactNode | React.ReactNode[] | null;
}

interface ProfusionContextProps {
  client?: ProfusionClient;
  app_ready: boolean;
  language: Language;
  setLanguage: (lang: Language) => void;
}

export const ProfusionContext = React.createContext<ProfusionContextProps>({
  app_ready: false,
  language: "fr-FR",
  setLanguage: (lang: Language) => {
  }
});

export const useProfusionClient = () => {
  return useContext(ProfusionContext).client;
};

export const ProfusionProvider = (props: ProfusionProviderProps) => {
  const [state, setState] = useState<ProfusionContextProps>({
    client: props.client,
    app_ready: false,
    language: "fr-FR",
    setLanguage
  });
  const { client } = props;


  function setLanguage(lang: Language) {
    setState((prevState) => ({ ...prevState, language: lang }));
    client.language = lang;
    client.onLanguageChange(lang);
  }

  useEffect( () => {
    if (client && !state.app_ready) {
      (async function() {
        await client.init()
        setState({ ...state, app_ready: true });
        client.language = state.language;
      })()
    }
  }, [client]);


  return <ProfusionContext.Provider value={state}>
    {state.app_ready && <ApolloProvider client={props.client.apollo}>
      <AuthenticationProvider client={props.client}>
        {props.children}
      </AuthenticationProvider>
    </ApolloProvider>}
  </ProfusionContext.Provider>;
};
