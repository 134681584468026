import {CommunityMember, CommunityTag} from "@graph";
import {Box, Checkbox, Drawer, ListItem, ListItemSecondaryAction, Stack} from "@mui/material";
import React, {useEffect} from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import {useCommunityMemberSetTag} from "@community/actions/member";
import {useRevalidator} from "react-router-dom";
import Typography from "@mui/material/Typography";

export interface MemberTagSelectorProps {
    member: CommunityMember
    allowedTags: CommunityTag[]
    open: boolean
    onClose: () => void
}

export interface MemberTagCheckboxProps {
    member: CommunityMember
    tag: CommunityTag
    onCheck: (checked: boolean) => void
}

export const MemberTagCheckbox = (props: MemberTagCheckboxProps) => {
    const {member, tag, onCheck} = props
    const [checked, setChecked] = React.useState(false);
    const {addTag, removeTag} = useCommunityMemberSetTag(member.hid)
    const {revalidate} = useRevalidator();

    useEffect(() => {
        setChecked(!!member.tags?.find((t) => t && t.hid === tag.hid))
    }, [member])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        const response = event.target.checked ? addTag(tag.hid) : removeTag(tag.hid);
        response.then(_ => {
            revalidate()
            onCheck(event.target.checked)
        })
    }

    return <Checkbox checked={checked} onChange={handleChange}/>
}

export const MemberTagSelector = (props: MemberTagSelectorProps) => {
    const {allowedTags, member, open, onClose} = props


    return <Drawer sx={{zIndex: 40002}}
                   open={open}
                   className="bottom-navigation"
                   anchor="bottom">

        <Box display="flex" flexDirection="column" padding={2}>
            <Stack direction="row" alignItems="center" gap={2} marginLeft={2}>
                <FontAwesomeIcon icon={faArrowLeft} onClick={onClose}/>
                <Typography fontSize={16} fontWeight={700} onClick={onClose}>
                    Choisir une catégorie
                </Typography>
            </Stack>
            <List>
                {allowedTags.map((tag: CommunityTag) => <ListItem key={tag.name}>
                    <ListItemText primary={tag.name}/>
                    <ListItemSecondaryAction>
                        <MemberTagCheckbox
                            member={member}
                            tag={tag} onCheck={_ => onClose()}/>
                    </ListItemSecondaryAction>
                </ListItem>)}
            </List>
        </Box>
    </Drawer>
}