import {gql} from "@profusion.media/profusion-core";
import {GROUP_FIELDS} from "../fragments";

export const GROUP_LEAVE_MUTATION = gql`
  mutation groupLeave($groupHid: String!) {
    groupLeave(groupHid: $groupHid) {
      status
      feature
    }
  }`;
