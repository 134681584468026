import React, {cloneElement, createContext, useContext, useMemo, useState} from "react"
import {MultiPartFormProps} from "../typings";
import {MultiPartFormStepper} from "./MultiPartFormStepper";
import {useFormErrors} from "../../Form";

export interface MultiPartFormContextProps {
    activeStep: number
    navigate: {
        next: () => void
        previous: () => void
        complete: () => void
    }
}

export const MultiPartFormContext = createContext<MultiPartFormContextProps>({
    activeStep: 0,
    navigate: {
        next: () => ({}),
        previous: () => ({}),
        complete: () => ({}),
    },
})


export const MultiPartForm = (props: MultiPartFormProps) => {
    const {children} = props

    const [activeStep, setActiveStep] = useState<number>(0)
    const formErrors = useFormErrors()

    const myId = 'mpf'

    const steps = useMemo(() => {
        return React.Children.map(children, (child, index) => {
            const id = `${myId}-${index}`;
            return cloneElement(child, {id, step: index});
        });
    }, [props.children])

    const stepErrors: number[] = useMemo(() => {
        if (formErrors.hasErrors) {

        } else {
            return []
        }
    }, [formErrors])

    const handlePrevious = () => {
        if (activeStep > 0) setActiveStep(activeStep - 1)
    }
    const handleNext = () => {
        if (activeStep < steps.length - 1) setActiveStep(activeStep + 1)
        else props.onComplete()
    }

    return <MultiPartFormContext.Provider value={{
        activeStep,
        navigate: {
            next: handleNext,
            previous: handlePrevious,
            complete: props.onComplete
        }
    }}>
        <MultiPartFormStepper
            activeStep={activeStep}
            onChange={k => k < activeStep ? setActiveStep(k) : false}
            steps={steps.map(s => ({
                    title: s.props.title,
                    stepId: s.props.id
                }))}/>

        {steps}
    </MultiPartFormContext.Provider>
}

export const useIsActiveStep = (step: number) : boolean => {
    const context = useContext<MultiPartFormContextProps>(MultiPartFormContext)
    return context.activeStep === step
}

export const useNavigateStep = (): MultiPartFormContextProps["navigate"] => {
    const context = useContext<MultiPartFormContextProps>(MultiPartFormContext)
    return context.navigate
}

export const useMultiPartFormContext = (): MultiPartFormContextProps => {
    return useContext<MultiPartFormContextProps>(MultiPartFormContext)
}