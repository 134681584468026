import {gql} from "@profusion.media/profusion-core";
import {PRAYER_EVENT_FIELDS} from "../fragments";

export const PRAYER_EVENT_LIST_QUERY = gql`
    ${PRAYER_EVENT_FIELDS}
    query PrayerEventList ($request: String!) {
        prayerRequestEvents(prayerRequest: $request) {
            ...PrayerEventFields
        }
    }`;

export const PRAYER_EVENT_REPLIES_QUERY = gql`
    ${PRAYER_EVENT_FIELDS}
    query PrayerEventReplies ($parentHid: String!) {
        prayerEventReplies(parentHid: $parentHid) {
            ...PrayerEventFields
        }
    }`;

export const PRAYER_COMMENTS_QUERY = gql`
    ${PRAYER_EVENT_FIELDS}
    query PrayerComments ($request: String!) {
        prayerRequestComments(prayerRequest: $request) {
            ...PrayerEventFields
        }
    }`;

export const PRAYER_PRAYED_EVENTS_QUERY = gql`
    ${PRAYER_EVENT_FIELDS}
    query PrayerPrayedEvents ($requestHid: String!) {
        prayerRequestPrayed(prayerRequest: $requestHid) {
            ...PrayerEventFields
        }
    }`;
