import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {
    Autocomplete,
    Box,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {SearchResult} from "@profusion.media/graph"
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons"
import {WhiteBox} from "@components/WhiteBox";
import List from "@mui/material/List";

const SEARCH_QUERY = gql`
    query SearchUsers($search: String!) {
        searchUsers(search: $search) {
            hid
            displayName
            city
            avatar {
                smallUrl
                mediumUrl
                largeUrl
            }
        }
    }
`;

interface SearchOptionValueProps {
    value: SearchResult
    showClear?: boolean
    onClear?: () => void
    onSelect?: (v: SearchResult) => void
}

export const SearchResultItem = (props: SearchOptionValueProps) => {
    const {value, onSelect, showClear, onClear} = props

    return <ListItem onClick={() => onSelect && onSelect(value)}>
        <ListItemAvatar>
            <UserAvatar avatar={value.avatar?.smallUrl} userName={value.displayName || ""}/>
        </ListItemAvatar>
        <ListItemText primary={value.displayName} secondary={value.city}/>
        {showClear && <ListItemSecondaryAction onClick={_ => onClear && onClear()}>
            <FontAwesomeIcon icon={faMultiply} fixedWidth/>
        </ListItemSecondaryAction>}
    </ListItem>
}

export interface SearchAutocompleteProps {
    onChange?: (v: SearchResult | null) => void
    title?: string
    children?: React.ReactNode
}


const SearchAutocomplete = (props: SearchAutocompleteProps) => {
    const {onChange, title, children} = props
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [searchItems, {loading, data}] = useLazyQuery(SEARCH_QUERY, {
        fetchPolicy: 'no-cache',
        context: {
            use_private: true
        }
    });
    const [selected, setSelected] = useState<SearchResult | null>()

    useEffect(() => {
        if (inputValue.length >= 3) {
            searchItems({
                variables: {
                    search: inputValue
                }
            });
        }
    }, [inputValue, searchItems]);

    useEffect(() => {
        if (data && data.searchUsers) {
            console.log(data.searchUsers)
            setOptions(data.searchUsers);
        }
    }, [data]);

    const handleInputChange = (event: any, value: React.SetStateAction<string>) => {
        setInputValue(value);
    };
    const handleClear = () => {
        setSelected(null);
        onChange && onChange(null);
    }

    const onSelect = (value: SearchResult) => {
        setSelected(value);
        onChange && onChange(value);
    }

    if (selected) {
        return <WhiteBox title={title ?? "Chercher une personne"}>
            <List>
                <SearchResultItem value={selected} showClear onClear={handleClear}/>
            </List>
        </WhiteBox>
    }

    return <WhiteBox title={title ?? "Chercher une personne"}>
        {children}
        <Autocomplete<SearchResult>
            options={options}
            inputValue={inputValue}
            getOptionLabel={option => option.displayName || ""}
            autoHighlight
            onInputChange={handleInputChange}
            onChange={(e, v) => onChange && onChange(v)}
            loading={loading}
            renderOption={(params, option) => <Box>
                <SearchResultItem value={option} onSelect={onSelect}/>
            </Box>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    </WhiteBox>

};

export default SearchAutocomplete;
