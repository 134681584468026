import { gql, DocumentNode } from '@profusion.media/profusion-core'

export const PUBLIC_INVITATION_FIELDS : DocumentNode = gql`
  fragment PublicInvitationFields on PublicInvitation {
    answeredAt
    createdAt
    redirectUrl
    hid
    isAccepted
    language
    subject
    message
    invitedBy {
      hid
      displayName
      avatar {
        smallUrl
        mediumUrl
      }
    }
  }
`

export const DETAILED_INVITATION_FIELDS : DocumentNode = gql`
  fragment DetailedInvitationFields on DetailedInvitation {
    message
    language
    isAccepted
    hid
    redirectUrl
    createdAt
    answeredAt
    lastSentAt
    recipientEmail
    recipientFirstName
    recipientLastName
    recipientPhoneNumber
    tryCount
    invitedBy {
      hid
      displayName
    }
  }
`
