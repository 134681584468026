import {DocumentNode, gql} from '@profusion.media/profusion-core'
import {PROFILE_FIELDS} from "@profusion.media/profusion-core-profile"
import {ADDRESS_ALL_FIELDS} from "@profusion.media/profusion-core-geo"

export const USER_FIELDS: DocumentNode = gql`
  ${PROFILE_FIELDS}
  ${ADDRESS_ALL_FIELDS}
  fragment UserFields on User {
    __typename
    hid
    firstName
    lastName
    email
    phoneNumber
    dateOfBirth
    emailChecked
    isActive
    isStaff
    isSuperuser
    communityMembership {
      prayerGroupHid
      tags {
        hid
        color
        tagOrder
        name
      }
      dateAdded
      communityHid
      isAdmin
      state
      communityName
      communityLogo {
        smallUrl
        mediumUrl
        largeUrl
      }      
    }
    profile {
      ...ProfileFields
    }
    address {
      ...AddressAllFields
    }
    language
  }
`;
