import {gql, VALIDATION_ERROR_FRAGMENT} from "@profusion.media/profusion-core";
import {GROUP_FIELDS, SUBSCRIBER_FIELDS} from "../fragments";

export const SUBSCRIBER_UPDATE_MUTATION = gql`
    ${SUBSCRIBER_FIELDS}
    ${VALIDATION_ERROR_FRAGMENT}
    mutation subscriberUpdate($input: SubscriberUpdateInput!) {
        subscriberUpdate(input: $input) {
            subscriber {
                ...SubscriberFields
            }
            errors {
                ...ValidationError
            }
        }
    }`;