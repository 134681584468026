import React, {useId} from "react"
import {SelectInputProps} from "./typings"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useFormFieldError} from "../Form";

export function SelectInput<T>(props: SelectInputProps<T>) {
    const {values, value, error, onChange,dense, label, keyExtractor, renderItem} = props
    const globalError = useFormFieldError(props.name)
    const labelId = useId()
    const variant = dense ? "standard" : "outlined"

    return <FormControl fullWidth={props.fullWidth} sx={props.sx}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select value={value?.toString() ?? ""}
                error={error || globalError.isError}
                labelId={labelId}
                label={label}
                onChange={e => onChange(e.target.value)}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: (dense ? 36 : 48) * 10.5,
                        }
                    }
                }}
                variant={variant}
                autoWidth={props.autoWidth}>
            {values.map(item => {
                const k = keyExtractor(item)
                return <MenuItem value={k}
                                 key={k}
                                 selected={k === value}>{renderItem(item)}</MenuItem>
            })}
        </Select>
    </FormControl>
}
