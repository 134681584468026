import {useAuthentication} from "@profusion.media/profusion-core";
import {Await, Outlet, useNavigate, useRouteLoaderData} from "react-router-dom";
import {LoadDetailsReturn} from "@groups/Groups/GroupDetails/loaders";
import React, {Suspense} from "react";
import {GroupJoin} from "@groups/Groups/GroupJoin/GroupJoin";
import {Group} from "@graph";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";

export const GroupAccessChecker = () => {
    const {is_authenticated} = useAuthentication()
    const user = useConnectedUser()
    const navigate = useNavigate()
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn

    const isAllowed = (group: Group) => {

        return group.userRole.isHost ||
            group.userRole.isCommunityAdmin ||
            group.userRole.isCreator ||
            group.userRole.state == 'A_10' ||
            (user?.isSuperuser && group.communityHid)
    }

    if (is_authenticated && user) {
        return <Suspense>
            <Await resolve={groupData}>
                {({data}) =>
                    isAllowed(data)
                        ? <Outlet/>
                        : <GroupJoin group={data}/>}

            </Await>
        </Suspense>
    } else {
        navigate("public")
    }
}