import { BaseObject } from "../types";

export type ClassDef = { new(data: any): any }

export class ObjectRegistry {
  private classMap: { [key: string]: ClassDef} = {};

  register(objectClass: ClassDef) {
    this.classMap[objectClass.name] = objectClass
  }

  getInstance(data: BaseObject): any | null {
    const className = this.classMap[data.__typename];
    if (className) {
      return new className(data);
    }
    return null;
  }
}

export class AppRegistry {
  objectRegistry: ObjectRegistry

  constructor() {
    this.objectRegistry = new ObjectRegistry()
  }
}
