import profusion from "../../config/profusion";
import {LoaderFunctionArgs, redirect} from "react-router-dom";
import {loadInvitationDetails} from "@profusion.media/profusion-core-group";
import {Device} from "@capacitor/device"

type InvitationLoaderArgs = LoaderFunctionArgs<["hid"]>

export const invitationLoader = async (props: InvitationLoaderArgs) => {
    const {params} = props
    if (!params.hid) return redirect("/register")
    const device = await Device.getInfo();

    const invitation = await loadInvitationDetails(profusion, params.hid)
    const nextUrl = invitation.data?.redirectUrl + "/invitation/" + params.hid

    localStorage.setItem("_afterDL", JSON.stringify({
        type: "invitation",
        nextUrl: nextUrl,
        invitation: invitation.data
    }))

    if (device.platform === "web") {
        return redirect("/downloadApp")
    }

    if (profusion.session.is_authenticated) {
        return redirect(nextUrl ?? "/register")
    } else {
        return redirect("/register?next=" + nextUrl )
    }
}
