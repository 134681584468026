import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {
  PRAYER_PIN_CREATE_MUTATION, PRAYER_PINNED_LIST_QUERY,
  PRAYER_REQUEST_DETAILS_QUERY,
  PrayerPinCreateInput,
  PrayerPinCreateMutation
} from "../schema";


export const actionCreatePrayerPin = (client: ProfusionClient, prayerPin: PrayerPinCreateInput) => {
  return executeMutation<PrayerPinCreateMutation, MutationInput<PrayerPinCreateInput>>(client, PRAYER_PIN_CREATE_MUTATION,
    {
      input: {
        requestHid: prayerPin.requestHid,
        color: prayerPin.color
      }
    }, {
      isPrivate: true,
      refetchQueries: (data) => ([{
        query: PRAYER_REQUEST_DETAILS_QUERY,
        context: {
          use_private: true
        },
        variables: {
          request: data.prayerPin.prayerRequest.hid
        }
      }, {
        query: PRAYER_PINNED_LIST_QUERY,
        context: {
          use_private: true
        }
      }])
    })
}
