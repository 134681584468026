import {Box, Tab} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigateDetailsTab} from "../../hooks/navigation";
import {Group} from "@profusion.media/profusion-core-group";
import {TabContext, TabList} from "@mui/lab";
import {useTabIndex} from "../hooks";

export interface GroupTabsProps {
    group: Group
}

export const GroupTabs = (props: GroupTabsProps) => {
    const {group} = props
    const {t} = useTranslation()
    const navigate = useNavigateDetailsTab(props.group)
    const tabIndex = useTabIndex() || (group.mainFeature === 'prayer' ? "1" : "5");

    const isPrayer = group.mainFeature === 'prayer'
    const isShare = group.mainFeature === 'share'

    const labelMembers = isPrayer
        ? t("prayer.group.tabs.subscribers", "Partenaires")
        : t("group.tabs.subscribers", "Membres")

    return <Box sx={{width: '100%', typography: 'body1', backgroundColor: '#f3f3f3'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabContext value={tabIndex}>
                <TabList variant="scrollable"
                         scrollButtons
                         aria-label={t("group.details.tabs.aria", "Fonctionnalités du groupe") as string}>
                    {isPrayer && <Tab label={t("prayer.group.tabs.prayer", "Prière")}
                                      value="1"
                                      onClick={_ => navigate("prayer")}
                    />}
                    {isShare && <Tab label={t("group.tabs.content", "Contenu")}
                                     value="5"
                                     onClick={_ => navigate("content")}
                    />}
                    <Tab label={labelMembers}
                         value="2"
                         sx={{display: "hidden"}}
                         onClick={_ => navigate("subscribers")}
                    />
                </TabList>
            </TabContext>
        </Box>
    </Box>
}
