import { InMemoryCacheConfig } from "@apollo/client";
import { StrictTypedTypePolicies } from "@profusion.media/graph";

const typePolicies: StrictTypedTypePolicies = {
  Group: {
    keyFields: ['hid']
  },
  Subscriber: {
    keyFields: ['hid']
  },
  PrayerRequest: {
    keyFields: ['hid'],
  },
  Organization: {
    keyFields: ['hid']
  },
  PrayerGroupOverview: {
    keyFields: ['groupHid']
  },
  PrayerOverview: {
    keyFields: ['requestHid']
  },
  Refresh: {
    keyFields: []
  },
  NotificationMessage: {
    keyFields: ['hid']
  },
  Community: {
    keyFields: ['hid']
  },
  CommunityMember: {
    keyFields: ['hid']
  },
  CommunityMembership: {
    keyFields: ['communityHid']
  },
  CommunityTag: {
    keyFields: ['hid']
  }
}

export const memoryCacheConfiguration : InMemoryCacheConfig = {
  typePolicies
}
