import React from "react"
import {FullScreenDialogProps, fullScreenDialogPropsDefaults} from "../typings";
import {Container, Dialog,} from '@mui/material';
import {FullScreenDialogHeader} from "./FullScreenDialogHeader";

export const FullScreenDialog = (props: FullScreenDialogProps) => {
    const {
        title, maxWidth,
        subtitle,
        slotProps,
        bodyContainer,
        children, onClose, helpContent, showHelp
    } = {...fullScreenDialogPropsDefaults, ...props}

    const handleClose = () => {
        if (onClose) onClose()
    };

    const dialogProps = (slotProps && slotProps.dialog)
        ?? {
            disableEscapeKeyDown: true,
            sx: {
                zIndex: 40000,
            },
            PaperProps: {
                sx: {
                    backgroundColor: "#f0f0f0"
                }
            }
        }

    const containerSxProps = (slotProps && slotProps.container?.sx) ?? {}
    return <Dialog
        {...dialogProps}
        fullScreen
        open={true}
        transitionDuration={0}
        onClose={handleClose}
    >
        <FullScreenDialogHeader
            title={title}
            subtitle={subtitle}
            maxWidth={maxWidth}
            onClose={handleClose}
            showHelp={showHelp} helpContent={helpContent}/>
        {bodyContainer
            ? bodyContainer({children})
            : <Container sx={{...containerSxProps, marginTop: 1}}
                         maxWidth={maxWidth}>
                {children}
            </Container>}
    </Dialog>
}