
export type CountryNameDefinition = {
    [T in string]: string
}

export const COUNTRY_NAMES_FR : CountryNameDefinition  = {
    AF: "Afghanistan",
    AL: "Albanie",
    AQ: "Antarctique",
    DZ: "Algérie",
    AS: "Samoa Américaines",
    AD: "Andorre",
    AO: "Angola",
    AG: "Antigua-et-Barbuda",
    AZ: "Azerbaïdjan",
    AR: "Argentine",
    AU: "Australie",
    AT: "Autriche",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    AM: "Arménie",
    BB: "Barbade",
    BE: "Belgique",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BV: "Île Bouvet",
    BR: "Brésil",
    BZ: "Belize",
    IO: "Territoire Britannique de l'Océan Indien",
    SB: "Îles Salomon",
    VG: "Îles Vierges Britanniques",
    BN: "Brunéi Darussalam",
    BG: "Bulgarie",
    MM: "Myanmar",
    BI: "Burundi",
    BY: "Bélarus",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-vert",
    KY: "Îles Caïmanes",
    CF: "République Centrafricaine",
    LK: "Sri Lanka",
    TD: "Tchad",
    CL: "Chili",
    CN: "Chine",
    TW: "Taïwan",
    CX: "Île Christmas",
    CC: "Îles Cocos (Keeling)",
    CO: "Colombie",
    KM: "Comores",
    YT: "Mayotte",
    CG: "République du Congo",
    CD: "République Démocratique du Congo",
    CK: "Îles Cook",
    CR: "Costa Rica",
    HR: "Croatie",
    CU: "Cuba",
    CY: "Chypre",
    CZ: "République Tchèque",
    BJ: "Bénin",
    DK: "Danemark",
    DM: "Dominique",
    DO: "République Dominicaine",
    EC: "Équateur",
    SV: "El Salvador",
    GQ: "Guinée Équatoriale",
    ET: "Éthiopie",
    ER: "Érythrée",
    EE: "Estonie",
    FO: "Îles Féroé",
    FK: "Îles (malvinas) Falkland",
    GS: "Géorgie du Sud et les Îles Sandwich du Sud",
    FJ: "Fidji",
    FI: "Finlande",
    AX: "Îles Åland",
    FR: "France",
    GF: "Guyane Française",
    PF: "Polynésie Française",
    TF: "Terres Australes Françaises",
    DJ: "Djibouti",
    GA: "Gabon",
    GE: "Géorgie",
    GM: "Gambie",
    PS: "Territoire Palestinien Occupé",
    DE: "Allemagne",
    GH: "Ghana",
    GI: "Gibraltar",
    KI: "Kiribati",
    GR: "Grèce",
    GL: "Groenland",
    GD: "Grenade",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinée",
    GY: "Guyana",
    HT: "Haïti",
    HM: "Îles Heard et Mcdonald",
    VA: "Saint-Siège (état de la Cité du Vatican)",
    HN: "Honduras",
    HK: "Hong-Kong",
    HU: "Hongrie",
    IS: "Islande",
    IN: "Inde",
    ID: "Indonésie",
    IR: "République Islamique d'Iran",
    IQ: "Iraq",
    IE: "Irlande",
    IL: "Israël",
    IT: "Italie",
    CI: "Côte d'Ivoire",
    JM: "Jamaïque",
    JP: "Japon",
    KZ: "Kazakhstan",
    JO: "Jordanie",
    KE: "Kenya",
    KP: "République Populaire Démocratique de Corée",
    KR: "République de Corée",
    KW: "Koweït",
    KG: "Kirghizistan",
    LA: "République Démocratique Populaire Lao",
    LB: "Liban",
    LS: "Lesotho",
    LV: "Lettonie",
    LR: "Libéria",
    LY: "Jamahiriya Arabe Libyenne",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaisie",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MQ: "Martinique",
    MR: "Mauritanie",
    MU: "Maurice",
    MX: "Mexique",
    MC: "Monaco",
    MN: "Mongolie",
    MD: "République de Moldova",
    MS: "Montserrat",
    MA: "Maroc",
    MZ: "Mozambique",
    OM: "Oman",
    NA: "Namibie",
    NR: "Nauru",
    NP: "Népal",
    NL: "Pays-Bas",
    AN: "Antilles Néerlandaises",
    AW: "Aruba",
    NC: "Nouvelle-Calédonie",
    VU: "Vanuatu",
    NZ: "Nouvelle-Zélande",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    NU: "Niué",
    NF: "Île Norfolk",
    NO: "Norvège",
    MP: "Îles Mariannes du Nord",
    UM: "Îles Mineures Éloignées des États-Unis",
    FM: "États Fédérés de Micronésie",
    MH: "Îles Marshall",
    PW: "Palaos",
    PK: "Pakistan",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    PE: "Pérou",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Pologne",
    PT: "Portugal",
    GW: "Guinée-Bissau",
    TL: "Timor-Leste",
    PR: "Porto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Roumanie",
    RU: "Fédération de Russie",
    RW: "Rwanda",
    SH: "Sainte-Hélène",
    KN: "Saint-Kitts-et-Nevis",
    AI: "Anguilla",
    LC: "Sainte-Lucie",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les Grenadines",
    SM: "Saint-Marin",
    ST: "Sao Tomé-et-Principe",
    SA: "Arabie Saoudite",
    SN: "Sénégal",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    VN: "Viet Nam",
    SI: "Slovénie",
    SO: "Somalie",
    ZA: "Afrique du Sud",
    ZW: "Zimbabwe",
    ES: "Espagne",
    EH: "Sahara Occidental",
    SD: "Soudan",
    SR: "Suriname",
    SJ: "Svalbard etÎle Jan Mayen",
    SZ: "Swaziland",
    SE: "Suède",
    CH: "Suisse",
    SY: "République Arabe Syrienne",
    TJ: "Tadjikistan",
    TH: "Thaïlande",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinité-et-Tobago",
    AE: "Émirats Arabes Unis",
    TN: "Tunisie",
    TR: "Turquie",
    TM: "Turkménistan",
    TC: "Îles Turks et Caïques",
    TV: "Tuvalu",
    UG: "Ouganda",
    UA: "Ukraine",
    MK: "L'ex-République Yougoslave de Macédoine",
    EG: "Égypte",
    GB: "Royaume-Uni",
    IM: "Île de Man",
    TZ: "République-Unie de Tanzanie",
    US: "États-Unis",
    VI: "Îles Vierges des États-Unis",
    BF: "Burkina Faso",
    UY: "Uruguay",
    UZ: "Ouzbékistan",
    VE: "Venezuela",
    WF: "Wallis et Futuna",
    WS: "Samoa",
    YE: "Yémen",
    CS: "Serbie-et-Monténégro",
    ZM: "Zambie",
}