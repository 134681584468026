import {gql} from "@profusion.media/profusion-core";
import {PRAYER_EVENT_FIELDS} from "../fragments";

export const PRAYER_EVENT_GROUP_LIST_QUERY = gql`
  ${PRAYER_EVENT_FIELDS}
  query PrayerEventList ($request: String!) {
    prayerRequestEventGroups(prayerRequest: $request) {
      hid
      count
      dateLastEvent
      eventGroupType
      events {
        ...PrayerEventFields
      }
    }
  }`;
