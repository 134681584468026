import {gql} from "@profusion.media/profusion-core";
import {USER_FIELDS} from "../fragments";

export const ACCOUNT_ADDRESS_UPDATE = gql`
  ${USER_FIELDS}
  mutation($input: AddressInput!) {
    accountAddressUpdate(input: $input) {
      user {
        ...UserFields
      }
      errors {
        code
        field
      }
    }
  }`;
