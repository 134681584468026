import {executeMutation, MutationInput, ProfusionClient} from "@profusion.media/profusion-core";
import {
  PRAYER_ADD_PRAYED_EVENT_MUTATION,
  PRAYER_EVENT_LIST_QUERY,
  PRAYER_REQUEST_OVERVIEW_QUERY,
  PrayerAddPrayedEventInput,
  PrayerEvent
} from "../schema";
import {GROUP_OVERVIEW_QUERY} from "@profusion.media/profusion-core-group";
import {PRAYER_EVENT_GROUP_LIST_QUERY} from "../schema/queries/prayer_event_group_list";


export type ActionAddPrayerEventResponse = {
  prayerEvent: PrayerEvent
}

export const actionAddPrayedEvent = (client: ProfusionClient, prayerEvent: PrayerAddPrayedEventInput) => {
  return executeMutation<ActionAddPrayerEventResponse, MutationInput<PrayerAddPrayedEventInput>>(client, PRAYER_ADD_PRAYED_EVENT_MUTATION,
    {
      input: {
        requestHid: prayerEvent.requestHid,
      }
    }, {
      isPrivate: true,
      refetchQueries: (data) => ([
        {
          query: PRAYER_EVENT_GROUP_LIST_QUERY,
          context: {
            use_private: true
          },
          variables: {
            request: data.prayerEvent.requestHid
          }
        }, {
          query: PRAYER_REQUEST_OVERVIEW_QUERY,
          context: {
            use_private: true
          },
          variables: {
            request: data.prayerEvent.requestHid
          }
        }, {
          query: GROUP_OVERVIEW_QUERY,
          context: {
            use_private: true
          },
          variables: {
            groupHid: data.prayerEvent.groupHid
          }
        }
      ])
    })
}
