import React, {ChangeEvent} from "react"
import {AddressInputDefaultProps, AddressInputProps} from "./typings";
import {Stack} from "@mui/material";
import {TextInput, useFormFieldError} from "@profusion.media/profusion-ui";
import {CountrySelectInput} from "./CountrySelectInput";
import {ProvinceSelectInput} from "./ProvinceSelectInput";
import {getCountryByAlpha2} from "@profusion.media/profusion-core-geo";
import "./styles.css"


export const AddressInput = (props: AddressInputProps) => {
    const {
        address,
        onChange,
        inputProps,
        labels,
        hideStreet,
        required,
        name
    } = {...AddressInputDefaultProps, ...props}

    const error = useFormFieldError(props.name)

    function isValid() {
        if (!required) return true
        const isValidProvince = getCountryByAlpha2(address.country)?.provinces ? !!address.province : true
        const isValidStreet = hideStreet ? true : !!address.street

        return !!address.city && !!address.postalCode && !!address.country && isValidProvince && isValidStreet
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange({...address, [e.target.name]: e.target.value})
    }

    const handleChangeCountry = (country: string) => {
        onChange({...address, country})
    }

    const handleChangeProvince = (province: string) => {
        onChange({...address, province})
    }

    return <Stack spacing={4}>
        {!hideStreet && <TextInput value={address.street}
                                   name="street"
                                   label={labels.street}
                                   onChange={(e) => handleChange(e)}
                                   {...inputProps} />}

        <Stack spacing={4} direction="row">

            <TextInput value={address.city}
                       name="city"
                       label={labels.city}
                       onChange={(e) => handleChange(e)}
                       required={required}
                       {...inputProps} />

            <TextInput value={address.postalCode}
                       name="postalCode"
                       label={labels.postalCode}
                       onChange={(e) => handleChange(e)}
                       required={required}
                       {...inputProps} />
        </Stack>
        <Stack spacing={4} direction="row">
            <CountrySelectInput value={address.country}
                                label={labels.country}
                                required={required}
                                onChange={handleChangeCountry}/>
            <ProvinceSelectInput country={address.country}
                                 value={address.province}
                                 label={labels.province}
                                 required={required}
                                 onChange={handleChangeProvince}/>
        </Stack>
    </Stack>
}

