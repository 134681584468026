import {ALL_GROUP_OVERVIEW_QUERY, Group, GROUP_OVERVIEW_QUERY, PrayerGroupOverview} from "../schema";
import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";

export const loadPrayerGroupOverview = (client: ProfusionClient, groupHid: string): GraphQueryPromise<PrayerGroupOverview> => {
  return executeQuery<PrayerGroupOverview>(client, GROUP_OVERVIEW_QUERY, {
    isPrivate: true,
    variables: {
      group: groupHid
    }
  })
}

export const loadAllPrayerGroupOverview = (client: ProfusionClient): GraphQueryPromise<PrayerGroupOverview[]> => {
  return executeQuery<PrayerGroupOverview[]>(client, ALL_GROUP_OVERVIEW_QUERY, {
    isPrivate: true,
  })
}

export const getPrayerGroupOverview = (group: Group, data: PrayerGroupOverview[]) => {
  return data.find((_t: { groupHid: string; }) => _t.groupHid === group.hid)
}
