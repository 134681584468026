import {Await, useRouteLoaderData} from "react-router-dom";
import {Avatar, Card, ListItem} from "@mui/material";
import {LoadDetailsReturn} from "../../loaders";
import {Suspense} from "react";
import {useTranslation} from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {Group} from "@profusion.media/profusion-core-group";
import {getLanguageDef} from "@profusion.media/profusion-core";
import ListItemIcon from "@mui/material/ListItemIcon";
import {DuoToneIcon, TimeAgo, UserAvatar} from "@profusion.media/profusion-ui";
import {GroupAvatar} from "../GroupAvatar";
import {faCalendar} from "@fortawesome/pro-duotone-svg-icons"

export const GroupAbout = () => {
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {t} = useTranslation()

    return <Suspense>

        <Await resolve={groupData}>
            {({data: group}: { data: Group }) => group && <Card sx={{mt: 4}}>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <GroupAvatar group={group}/>
                        </ListItemIcon>
                        <ListItemText primary={group.title} secondary="Nom du groupe"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <DuoToneIcon size="2x"
                                         icon={faCalendar}
                                         style={{
                                             "--fa-primary-color": "#39adeb",
                                             "--fa-secondary-color": "#785cee"
                                         }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={group.dateCreated && <TimeAgo date={group.dateCreated} />} secondary="Date de création"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <UserAvatar avatar={group.createdBy?.avatar?.smallUrl}
                                        userName={group.createdBy?.displayName}/>
                        </ListItemIcon>
                        <ListItemText primary={group.createdBy?.displayName} secondary="Créer par"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Avatar src={getLanguageDef(group.language).flag}/>
                        </ListItemIcon>
                        <ListItemText primary={getLanguageDef(group.language).label} secondary="Langue principale"/>
                    </ListItem>
                </List>
            </Card>
            }

        </Await>
    </Suspense>
}