import {GroupList} from "./GroupList/GroupList";
import {GroupCreate} from "./GroupCreate/GroupCreate";
import {loadList} from "./GroupList/loaders";
import {LoaderFunctionArgs, Outlet, RouteObject} from "react-router-dom"
import {actionCreate} from "./components/settings";


export const groupListRouter: RouteObject[] = [
    {
        path: ":group_type",
        element: <Outlet/>,
        children: [
            {
                path: "mine",
                element: <GroupList/>,
                id: "groupMine",
                loader: (args: LoaderFunctionArgs<["group_type"]>) => loadList("mine", args.params.group_type),
                shouldRevalidate: ({currentUrl, nextUrl}) => {
                    return currentUrl.pathname === "/groups/add" ||
                        currentUrl !== nextUrl;
                },
                handle: {
                    "list": "mine"
                }
            },
            {
                path: "shared",
                element: <GroupList/>,
                id: "groupShared",
                loader: (args: LoaderFunctionArgs<["group_type"]>) => loadList("shared", args.params.group_type),
                handle: {
                    "list": "shared"
                }
            },
            {
                path: "add",
                element: <GroupCreate/>,
                id: "groupCreate",
                action: (props) => actionCreate(props)
            },
        ]
    },

]

