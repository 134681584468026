import React, {useEffect} from 'react';
import './App.css';
import MainRouter from "./router/MainRouter";
import {ConnectedUserProvider} from "@profusion.media/profusion-iam-user";
import {useAppReady} from "@profusion.media/profusion-core";
import {DrawerMenuProvider, WaitingPage} from "@profusion.media/profusion-ui";
import {StatusBar, Style} from '@capacitor/status-bar';
import {PushNotificationProvider} from "@components/NotificationCenter/PushNotificationProvider";
import {Box} from "@mui/material";
import {SafeArea} from "@capacitor-community/safe-area";

export const App = () => {
    const appReady = useAppReady()
    const navigate = (action: string) => {
        window.location.href = action;
    }

    useEffect(() => {
        (async function () {

            await StatusBar.setStyle({
                style:Style.Dark
            });
            await StatusBar.setBackgroundColor({
                color: "#1976d2"
            })
            SafeArea.enable({
                config: {
                    customColorsForSystemBars: true,
                    statusBarColor: '#1976d2', // transparent
                    statusBarContent: 'light',
                    navigationBarColor: '#1976d2', // transparent
                    navigationBarContent: 'light',
                },
            });

        })()
    }, []);

    if (!appReady) {
        return <WaitingPage show={true}/>
    }

    const onPushAction = (action: string) => {
        navigate(action)
    }

    const onSignOut = () => {
        navigate("/");
    }

    return <DrawerMenuProvider>
        <ConnectedUserProvider onSignOut={onSignOut}
                               waitElement={<WaitingPage show={true}/>}>
            <PushNotificationProvider onAction={onPushAction}>
                <Box bgcolor="#d5d5d5" sx={{minHeight: "100vh", overflowY: "auto"}}>
                    <MainRouter/>
                </Box>

            </PushNotificationProvider>
        </ConnectedUserProvider>
    </DrawerMenuProvider>


}

export default App;
