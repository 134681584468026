import {gql} from "@apollo/client";
import {COMMUNITY_FRAGMENT} from "../fragments";
import {COMMUNITY_MEMBER_FRAGMENT} from "@community/schema/fragments/community_member";
import {GROUP_FIELDS} from "@profusion.media/profusion-core-group";
import {ORGANIZATION_FIELDS} from "@organizations/schema/fragments/organization";
import {CONTENT_FRAGMENT} from "@community/schema/fragments/content";

export const GET_USER_CONTENT = gql`
    ${CONTENT_FRAGMENT}
    query getUserContent {
        getUserContent {
            ...CONTENT_FRAGMENT
        }
    }`

export const GET_PUBLIC_CONTENT = gql`
    ${CONTENT_FRAGMENT}
    query getPublicContent {
        getPublicContent {
            ...CONTENT_FRAGMENT
        }
    }`
