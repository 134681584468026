import { gql, DocumentNode } from '@profusion.media/profusion-core'

export const GROUP_OVERVIEW_FIELDS : DocumentNode = gql`
  fragment GroupOverviewFields on PrayerGroupOverview {
    subscriberCount
    pendingCount
    requestCount
    prayerCount
    prayerThisWeek
    requestThisWeek
    groupHid
  }`
