import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation, gql} from "@profusion.media/profusion-core";
import {
    GroupInviteInput,
    GroupInviteResponse,
    MutationGroupUserInviteArgs, MutationSubscriberRemoveArgs,
    MutationSubscriberUpdateArgs, SubscriberRemoveMutation,
    SubscriberUpdateMutation
} from "@graph";
import profusion from "@config/profusion";
import {
    SUBSCRIBER_UPDATE_MUTATION
} from "@profusion.media/profusion-core-group/groups/schema/mutations/subscriber_update_mutation";
import {
    SUBSCRIBER_REMOVE_MUTATION
} from "@profusion.media/profusion-core-group/groups/schema/mutations/subscriber_remove_mutation";


export const GROUP_USER_INVITE_MUTATION = gql`
    mutation groupUserInvite($input: GroupInviteInput!) {
        groupUserInvite(input: $input) {
            success
            message
        }
    }`;


export const actionGroupInvite = async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as GroupInviteInput

    const response = await executeMutation<GroupInviteResponse, MutationGroupUserInviteArgs>(
        profusion,
        GROUP_USER_INVITE_MUTATION,
        {input: data},
        {
            isPrivate: true
        })
    return response.data

}


export const useGroupSubscriberAction = (subscriberHid: string) => {
    return {
        setHost: (isHost: boolean) => {
            return executeMutation<SubscriberUpdateMutation, MutationSubscriberUpdateArgs>(
                profusion,
                SUBSCRIBER_UPDATE_MUTATION,
                {
                    input: {
                        hid: subscriberHid,
                        isHost: isHost
                    }
                },
                {
                    isPrivate: true
                })
        },
        remove: () =>  {
            return executeMutation<SubscriberRemoveMutation, MutationSubscriberRemoveArgs>(
                profusion,
                SUBSCRIBER_REMOVE_MUTATION,
                {
                    input: {
                        hid: subscriberHid
                    }
                },
                {
                    isPrivate: true
                })
        },
    }
}