import {SubscriberInvitation} from "./GroupDetails/components/SubscriberInvitation/SubscriberInvitation";
import {GroupCreateConfirm} from "./GroupCreate/GroupCreateConfirm";
import {
    DetailsLoaderProps,
    loadGroup,
    loadSubscriberSettings,
    SubscriberSettingsLoaderProps
} from "./GroupDetails/loaders";
import {redirect, RouteObject} from "react-router-dom"
import {GroupSubscriberList} from "./GroupDetails/components/SubscriberList/GroupSubscriberList";
import {GroupSubscriberSettings} from "./GroupDetails/components/SubscriberSettings/GroupSubscriberSettings";
import {actionUpdate} from "./components/settings";
import {GroupSettingsUpdate} from "./GroupDetails/components/GroupSettings/GroupSettingsUpdate";
import {prayerGroupRouter} from "../Prayers/prayerRouter";
import {GroupAbout} from "./GroupDetails/components/GroupAbout/GroupAbout";
import {GroupDetails} from "./GroupDetails/GroupDetails";
import {GroupPrivacyForm} from "./GroupDetails/components/GroupPrivacy/GroupPrivacyForm";
import {GroupInvitation} from "./GroupDetails/invitation/GroupInvitation";
import {actionGroupInvite} from "./GroupDetails/invitation/actions";
import {GroupQR} from "./GroupDetails/invitation/GroupQR";
import {GroupAddMembers} from "@community/screens/groups/details/GroupAddMembers";
import {loadCommunityGroupMembersAllowed} from "@community/loaders/communityGroupMembersAllowedLoader";
import {GroupContent} from "@groups/Groups/GroupDetails/components/GroupContent/GroupContent";
import {loadCommunityAvailableContent} from "@community/loaders/communityAvailableContent";
import {GroupContentManage} from "@groups/Groups/GroupDetails/components/GroupContent/GroupContentManage";
import {actionLeaveGroup} from "@profusion.media/profusion-core-group";
import {actionDeleteGroup} from "@profusion.media/profusion-core-group/groups/actions/delete";


export const groupSingleRouter: RouteObject[] = [
    {
        path: "",
        element: <GroupDetails/>,
        children: [

            {
                path: "prayer-request",
                loader: ({params}) => redirect(`/groups/prayer/${params.hid}`)
            },
            {
                path: "subscribers",
                element: <GroupSubscriberList/>,
                handle: {
                    tab: "2"
                }
            },

            {
                path: "invite",
                element: <GroupInvitation />,
                action: actionGroupInvite,
                handle: {
                    tab: "2"
                }
            },
            {
                path: "leave",
                action: actionLeaveGroup,
            },
            {
                path: "delete",
                action: actionDeleteGroup,
            },
            {
                path: "community",
                element: <GroupAddMembers />,
                loader: loadCommunityGroupMembersAllowed,
                action: actionGroupInvite,
                handle: {
                    tab: "2"
                }
            },
            {
                path: "qr",
                element: <GroupQR />,
                handle: {
                    tab: "2"
                }
            },
            {
                path: "settings",
                element: <GroupSubscriberSettings/>,
                loader: (props: SubscriberSettingsLoaderProps) => loadSubscriberSettings(props),
                handle: {
                    tab: "3"
                }
            },
            {
                path: "about",
                element: <GroupAbout/>,
                handle: {
                    tab: "4"
                }
            },
            {
                path: "content",
                element: <GroupContent/>,
                handle: {
                    tab: "5"
                }
            },
            {
                path: "content/manage",
                element: <GroupContentManage/>,
                loader: loadCommunityAvailableContent,
                handle: {
                    tab: "5"
                }
            },

            ...prayerGroupRouter,
        ]
    },


    {
        path: "confirm",
        element: <GroupCreateConfirm/>,
        id: "groupCreateConfirm"
    },
    {
        path: "edit",
        element: <GroupSettingsUpdate/>,
        id: "groupSettingsUpdate",
        loader: (props: DetailsLoaderProps) => loadGroup(props),
        action: (props) => actionUpdate(props)
    },
    {
        path: "privacy",
        element: <GroupPrivacyForm/>,
        id: "groupSettingsPrivacy",

    }
]

