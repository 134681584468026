import {AddressInput as AddressInputType} from "@profusion.media/profusion-core-geo";
import {TextInputProps} from "@profusion.media/profusion-ui";

export interface AddressInputLabels {
    city?: string
    postalCode?: string
    street?: string
    country?: string
    province?: string
    search?: string
}

export interface AddressInputProps {
    name?: string
    address: AddressInputType
    onChange: (address: AddressInputType) => void
    inputProps?: TextInputProps
    labels?: AddressInputLabels
    hideStreet?: boolean
    autoComplete?: boolean
    required?: boolean
}


export interface CountrySelectInputProps {
    value: string
    onChange: (country: string) => void
    label?: string
    required?: boolean
}

export interface ProvinceSelectInputProps {
    country: string
    value: string
    onChange: (province: string) => void
    label?: string
    hideIfEmpty?: boolean
    required?: boolean
}
export const AddressInputDefaultProps: Partial<AddressInputProps> = {
    hideStreet: false,
    autoComplete: false,
    required: false,
    name: "address",
    labels: {
        city: "Ville",
        postalCode: "Code postal",
        country: "Pays",
        province: "Province",
        street: "Rue",
        search: "Chercher"
    }
}

export const CountrySelectInputDefaultProps: Partial<CountrySelectInputProps> = {
    label: AddressInputDefaultProps.labels.country,
    required: false
}
export const ProvinceSelectInputDefaultProps: Partial<ProvinceSelectInputProps> = {
    hideIfEmpty: true,
    label: AddressInputDefaultProps.labels.province,
    required: false
}